import ColorContrastChecker from 'color-contrast-checker';

export function initState() {
	return {
		darkTheme: false,
		showFullFormHeader: false,
		uiVersion: {
			current: null,
			new: null
		}
	};
}

export default {
	state: initState(),
	setDarkTheme(value) {
		//Toggle value if not passed
		if (value === undefined) {
			value = !_REALM.STORE.layoutStore.state.darkTheme;
		}

		_REALM.VM.$vuetify.theme.isDark = value;

		_REALM.STORE.layoutStore.state.darkTheme = value;
	},
	accentContrastIsValid(accentColor, isDarkMode) {
		const contrastChecker = new ColorContrastChecker();
		if (!contrastChecker.isValidColorCode(accentColor)) {
			return true;
		}

		const backgroundColor = isDarkMode ? '#1e1e1e' : '#ffffff';
		return contrastChecker.isLevelCustom(accentColor, backgroundColor, 1.2);
	},
	isGivingForm() {
		// Payment forms are a type of giving form
		return _REALM.VM.$route.name === 'donor-form' || _REALM.VM.$route.name === 'donor-payment-form';
	},
	isPledgeForm() {
		return _REALM.VM.$route.name === 'donor-pledge-form';
	},
	isPaymentForm() {
		return _REALM.VM.$route.name === 'donor-payment-form';
	}
};
