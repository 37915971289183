<style lang="scss">
@import '@/scss/variables.scss';
.eg-swatches-wrapper {
	.vue-swatches__fallback__input--wrapper {
		padding-right: 13px;
	}
	.vue-swatches__fallback__input {
		padding: 6px;
	}

	.ok-button {
		margin-left: 11px;
		background: $realm-blue;
	}
}
</style>

<template>
	<v-swatches v-model="internalValue" :trigger-style="swatchTriggerStyles" :swatches="swatchColors" :swatch-style="swatchStyles" class="eg-swatches-wrapper d-flex flex-column align-stretch" show-fallback fallbackOkClass="ok-button" @input="$emit('input', $event)" />
</template>

<script>
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import cssVariables from '@/scss/variables.scss';

export default {
	components: {
		VSwatches
	},
	props: {
		value: String
	},
	data() {
		return {
			internalValue: null,
			swatchTriggerStyles: {
				width: '100%',
				height: '50px',
				border: `1px solid ${cssVariables['color-black']}`
			},
			swatchStyles: {
				padding: '0'
			},
			swatchColors: ['#A84848', '#C87F39', '#CFC64E', cssVariables['color-primary'], '#56AB5E', '#46B792', '#5EAFB3', '#4587B8', '#5E67B7', '#8B5BB1', '#B058B3', '#B54C79', '#836C48', '#D8D8D8', '#808080', '#222222']
		};
	},
	created() {
		this.internalValue = this.value;
	}
};
</script>
