<template>
	<r-link target="_blank" :route="computedRoute"><slot></slot></r-link>
</template>

<script>
export default {
	props: {
		resourceId: {
			type: String,
			required: true
		}
	},
	computed: {
		computedRoute() {
			return `Help/Portal?resourceid=${this.resourceId}`;
		}
	}
};
</script>
