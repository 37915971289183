import siteService from '../services/siteService';

export function initState() {
	return {
		site: {},
		sites: [],
		settings: {
			textGivingEnabled: false
		}
	};
}

//var siteUrlCacheKey = function() {
//	return _REALM.STORE.siteStore.state.site.siteId ? `site.url.${_REALM.STORE.siteStore.state.site.siteId}` : null;
//};

const enums = {
	SiteClassification: {
		None: 0,
		Inform: 1,
		Connect: 2,
		Multiply: 3,
		Office: 4
	},
	GivingClassification: {
		None: 0,
		Giving1: 1,
		Giving2: 2
	},
	ChmsGivingIntegration: {
		None: 0,
		MinistryPlatform: 1
	}
};

export default {
	state: initState(),
	getSite() {
		const cacheToken = `site.cache`;
		return _REALM.STORE.CACHE.verify(cacheToken).catch(function() {
			return siteService.getSite().then(function(response) {
				_REALM.STORE.siteStore.state.site = _REALM.STORE.CACHE.set(cacheToken, response);
				return _REALM.STORE.siteStore.state.site;
			});
		});
	},
	getSettings() {
		//return _REALM.STORE.CACHE.verify(siteSettingsCacheKey()).catch(function() {
		//	return siteService
		//		.getSiteSettings({ id })
		//		.then(function(response) {
		//			_REALM.STORE.givingStore.state.settings = _REALM.STORE.CACHE.set(siteSettingsCacheKey(), response.settings);
		//			return _REALM.STORE.givingStore.state.settings;
		//		})
		//		.catch(function(error) {
		//			//If settings not found, add settings
		//			if (error.code === 13) {
		//				return _REALM.STORE.siteStore.addSiteSettings({
		//					siteId: id
		//				});
		//			} else {
		//				return Promise.reject(error);
		//			}
		//		});
		//});
	},
	isKeywordAvailable() {
		//return siteService.isKeywordAvailable({ siteId: this.state.site.siteId, keyword: this.state.settings.primaryKeyword });
	},
	hasExperiment(key) {
		return _REALM.CONFIG.Experiments.includes(key);
	},
	hasClassification(classification) {
		const siteClassification = parseInt(_REALM.CONFIG.SiteClassification);
		if (classification === enums.SiteClassification.None && siteClassification !== enums.SiteClassification.None) {
			return false;
		}

		return siteClassification >= classification;
	},
	hasGivingClassification(givingClassification) {
		const siteGivingClassification = parseInt(_REALM.CONFIG.GivingClassification);
		if (givingClassification === enums.GivingClassification.None && siteGivingClassification !== enums.GivingClassification.None) {
			return false;
		}

		return siteGivingClassification >= givingClassification;
	},
	isChmsGivingIntegration() {
		const siteChmsGivingIntegration = parseInt(_REALM.CONFIG.ChmsGivingIntegration);
		return siteChmsGivingIntegration > enums.ChmsGivingIntegration.None;
	},
	enums: enums
};
