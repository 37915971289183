import givingFormService from '../services/givingFormService';
import _findIndex from 'lodash/findIndex';
import _truncate from 'lodash/truncate';
import cssVariables from '@/scss/variables.scss';

var resetGivingForm = function() {
	return {
		useDarkOverlayScheme: true,
		headerBackgroundColor: cssVariables['color-primary'],
		buttonColor: cssVariables['color-primary'],
		font: 'modern',
		title: _truncate(_REALM.STORE.siteStore.state.site.siteName, { length: 50, omission: '' }),
		subtitle: _truncate(_REALM.STORE.siteStore.state.site.city + ', ' + _REALM.STORE.siteStore.state.site.region, { length: 50, omission: '' }),
		logoBorderRadius: 50,
		memorials: null
	};
};

var getNonAdminDefaultGivingForm = function() {
	return new Promise(function(resolve) {
		_REALM.STORE.givingFormStore.state.givingForm = {
			siteId: _REALM.STORE.siteStore.state.site.siteId,
			id: '533eff5e098b439e8586d72317fe4eb6',
			name: 'Default',
			funds: [], //TODO update with all online giving funds
			urlPath: 'now',
			textKeyword: '',
			title: _REALM.STORE.siteStore.state.site.siteName,
			subtitle: _REALM.STORE.siteStore.state.site.city + ', ' + _REALM.STORE.siteStore.state.site.region,
			introMessage: '',
			headerBackgroundColor: '#319acf',
			buttonColor: '#319acf',
			useDarkOverlayScheme: false,
			useDarkBodyScheme: false,
			font: 'modern',
			receiptMessage: _REALM.STORE.layoutStore.isPaymentForm() ? '' : "With your generosity, we're closer to reaching our goal!",
			isDefault: true,
			isText: false,
			isMobile: false,
			showAddPledge: false,
			logoBorderRadius: 50,
			memorials: _REALM.STORE.layoutStore.isPaymentForm() ? [] : _REALM.STORE.memorialStore.state.activeMemorials.map(m => m.memorialId)
		};

		return resolve(_REALM.STORE.givingFormStore.state.givingForm);
	});
};

var resetIsDefault = function(defaultFormId) {
	for (var i = 0; i < _REALM.STORE.givingFormStore.state.givingForms.length; i++) {
		_REALM.STORE.givingFormStore.state.givingForms[i].isDefault = _REALM.STORE.givingFormStore.state.givingForms[i].formId === defaultFormId;
	}
};

var resetIsText = function(textGivingFormId) {
	for (var i = 0; i < _REALM.STORE.givingFormStore.state.givingForms.length; i++) {
		_REALM.STORE.givingFormStore.state.givingForms[i].isText = _REALM.STORE.givingFormStore.state.givingForms[i].formId === textGivingFormId;
	}
};

var resetIsMobile = function(mobileGivingFormId) {
	for (var i = 0; i < _REALM.STORE.givingFormStore.state.givingForms.length; i++) {
		_REALM.STORE.givingFormStore.state.givingForms[i].isMobile = _REALM.STORE.givingFormStore.state.givingForms[i].formId === mobileGivingFormId;
	}
};

var overideRealmEmbed = function() {
	_REALM.STORE.givingFormStore.state.givingForm.headerBackgroundColor = cssVariables['color-primary'];
	_REALM.STORE.givingFormStore.state.givingForm.buttonColor = cssVariables['color-primary'];
	_REALM.STORE.givingFormStore.state.givingForm.useDarkOverlayScheme = false;
	_REALM.STORE.givingFormStore.state.givingForm.useDarkBodyScheme = false;
	_REALM.STORE.givingFormStore.state.givingForm.font = 'modern';
	_REALM.STORE.givingFormStore.state.givingForm.funds = _REALM.STORE.givingFormStore.isAdminManaging() ? [] : _REALM.STORE.givingFormStore.state.givingForm.funds;
	if (_REALM.STORE.givingFormStore.isAdminManaging()) {
		_REALM.STORE.givingFormStore.state.givingForm.defaultAmount = null;
	}
};

export function initState() {
	return {
		currentDonorPage: 'GiveIntro',
		givingForm: {},
		givingForms: [],
		transactionAccount: { primaryEmail: '' },
		sortableFunds: [],
		editingRecurrenceIsComplete: false,
		textTokenExpired: false,
		givingFormQueryData: {},
		givingFormError: ''
	};
}

export default {
	state: initState(),
	resetTransactionAccount() {
		_REALM.STORE.givingFormStore.state.transactionAccount = { primaryEmail: '' };
	},
	async setTransactionAccount(individualId) {
		if (!_REALM.STORE.accountStore.isLoggedIn()) {
			return;
		}

		if (individualId === _REALM.STORE.accountStore.state.authAccount.individualId) {
			_REALM.STORE.givingFormStore.state.transactionAccount = _REALM.STORE.accountStore.state.authAccount;
		} else {
			await _REALM.STORE.givingFormStore.getGivingFormIndividual(individualId).then(function(individual) {
				_REALM.STORE.givingFormStore.state.transactionAccount = individual;
			});
		}

		_REALM.STORE.givingFormStore.setTransactionHasEmail();
	},
	setTransactionHasEmail() {
		_REALM.STORE.givingFormStore.state.transactionAccount.hasEmail =
			_REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail !== null && _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail !== undefined && _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail !== '';
	},
	isAdminManaging() {
		return _REALM.STORE.accountStore.isLoggedIn() && _REALM.STORE.givingFormStore.state.transactionAccount.individualId !== _REALM.STORE.accountStore.state.authAccount.individualId;
	},
	isPreviewGivingFormMode() {
		return _REALM.VM.$route.name === 'giving-form';
	},
	setCurrentGivingForm(givingForm) {
		_REALM.STORE.givingFormStore.state.givingForm = givingForm;
	},
	getGivingFormIndividual(individualId) {
		return givingFormService.getGivingFormIndividual(individualId);
	},
	listGivingForms(siteId) {
		return givingFormService.listGivingForms().then(function(givingForms) {
			_REALM.STORE.givingFormStore.state.givingForms = givingForms;
			return _REALM.STORE.givingFormStore.state.givingForms;
		});
	},
	addGivingForm(givingForm) {
		givingForm.siteId = _REALM.STORE.siteStore.state.site.siteId;
		return givingFormService.addGivingForm(givingForm).then(function(newGivingForm) {
			_REALM.STORE.givingFormStore.state.givingForm = newGivingForm;
			_REALM.STORE.givingFormStore.state.givingForms.push(newGivingForm);

			if (newGivingForm.isDefault) {
				resetIsDefault(newGivingForm.formId);
			}

			return newGivingForm;
		});
	},
	updateGivingForm(givingForm) {
		givingForm.siteId = _REALM.STORE.siteStore.state.site.siteId;
		return givingFormService.updateGivingForm(givingForm).then(function(updatedGivingForm) {
			_REALM.STORE.givingFormStore.state.givingForm = updatedGivingForm;

			var givingFormIndex = _findIndex(_REALM.STORE.givingFormStore.state.givingForms, { formId: updatedGivingForm.formId });
			Object.assign(_REALM.STORE.givingFormStore.state.givingForms[givingFormIndex], updatedGivingForm);

			if (updatedGivingForm.isDefault) {
				resetIsDefault(updatedGivingForm.formId);
			}
			if (updatedGivingForm.isText) {
				resetIsText(updatedGivingForm.formId);
			}
			if (updatedGivingForm.isMobile) {
				resetIsMobile(updatedGivingForm.formId);
			}

			return _REALM.STORE.givingFormStore.state.givingForm;
		});
	},
	clearCurrentGivingForm() {
		_REALM.STORE.givingFormStore.state.givingForm = resetGivingForm();
	},
	getGivingForm(formId) {
		return givingFormService.getGivingForm(formId).then(function(givingForm) {
			_REALM.STORE.givingFormStore.state.givingForm = givingForm;
			_REALM.STORE.givingFormStore.state.givingForm._isDefaultOriginValue = _REALM.STORE.givingFormStore.state.givingForm.isDefault;
			_REALM.STORE.givingFormStore.state.givingForm._isTextOriginValue = _REALM.STORE.givingFormStore.state.givingForm.isText;
			return _REALM.STORE.givingFormStore.state.givingForm;
		});
	},
	getGivingFormByUrl(givingFormUrl) {
		if (_REALM.STORE.layoutStore.isPaymentForm()) {
			return _REALM.STORE.givingFormStore.getDefaultGivingForm();
		}

		return givingFormService
			.getGivingFormByURL(givingFormUrl)
			.then(function(givingForm) {
				if (givingForm === null) {
					return _REALM.STORE.givingFormStore.getDefaultGivingForm();
				}

				if (givingForm.dateDeactivated) {
					return _REALM.VM.$router.push({
						name: 'donor-form'
					});
				}

				_REALM.STORE.givingFormStore.state.givingForm = givingForm;
				if (_REALM.STORE.givingFormStore.useDefaultFormStyles()) {
					overideRealmEmbed();
				}

				return _REALM.STORE.givingFormStore.state.givingForm;
			})
			.catch(function() {
				return _REALM.STORE.givingFormStore.getDefaultGivingForm();
			});
	},
	getDefaultGivingForm() {
		if (_REALM.STORE.layoutStore.isPaymentForm()) {
			return getNonAdminDefaultGivingForm();
		}

		return givingFormService
			.getDefaultGivingForm()
			.then(function(givingForm) {
				if (givingForm === null) {
					return getNonAdminDefaultGivingForm();
				}

				_REALM.STORE.givingFormStore.state.givingForm = givingForm;
				return _REALM.STORE.givingFormStore.state.givingForm;
			})
			.catch(function() {
				return getNonAdminDefaultGivingForm();
			});
	},
	toggleActiveGivingForm(formId) {
		return givingFormService.toggleActiveGivingForm(formId).then(function(givingForm) {
			var givingFormIndex = _findIndex(_REALM.STORE.givingFormStore.state.givingForms, { formId });
			Object.assign(_REALM.STORE.givingFormStore.state.givingForms[givingFormIndex], givingForm);

			return givingForm;
		});
	},
	getGivingFormBaseUrl() {
		return _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/give/';
	},
	isGivingFormEmbed() {
		return [_REALM.STORE.transactionStore.enums.IntegrationType.EMBED, _REALM.STORE.transactionStore.enums.IntegrationType.REALM].includes(_REALM.STORE.transactionStore.state.transaction.integrationType);
	},
	getMintCustomerFromToken(token) {
		return givingFormService.getMintCustomerFromToken(token);
	},
	isTextGiver() {
		return _REALM.STORE.transactionStore.state.transaction.integrationType === _REALM.STORE.transactionStore.enums.IntegrationType.TEXT;
	},
	isGoGiver() {
		return _REALM.STORE.transactionStore.state.transaction.integrationType === _REALM.STORE.transactionStore.enums.IntegrationType.GOMETHOD;
	},
	getLogoBorderRadius(formId = null) {
		const form = formId ? _REALM.STORE.givingFormStore.state.givingForms.find(form => form.formId === formId) : _REALM.STORE.givingFormStore.state.givingForm;
		return typeof form.logoBorderRadius === 'undefined' || form.logoBorderRadius === null ? 50 : form.logoBorderRadius;
	},
	isAuthEnabled() {
		return !_REALM.STORE.layoutStore.isPaymentForm();
	},
	isSinglePageForm() {
		return _REALM.STORE.layoutStore.isPaymentForm();
	},
	canSelectFunds() {
		return !_REALM.STORE.layoutStore.isPaymentForm();
	},
	isOneTimePaymentOnly() {
		return _REALM.STORE.layoutStore.isPaymentForm();
	},
	isMemorialEnabled() {
		return _REALM.STORE.memorialStore.siteHasMemorials() && _REALM.STORE.memorialStore.state.selectableGivingFormMemorials.length > 0 && !_REALM.STORE.layoutStore.isPaymentForm();
	},
	canCreateAccount() {
		return (
			_REALM.STORE.siteStore.state.site.invitationModelSite == 'Open' &&
			!_REALM.STORE.accountStore.isLoggedIn() &&
			!_REALM.STORE.transactionStore.state.transaction.donorHasAccount &&
			!_REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite &&
			_REALM.STORE.givingFormStore.isAuthEnabled()
		);
	},
	canCreateRecurrence() {
		return (
			!_REALM.STORE.givingFormStore.isAdminManaging() &&
			_REALM.STORE.paymentMethodStore.state.addPaymentMethodForm.savePaymentMethod &&
			(_REALM.STORE.transactionStore.state.transaction.recurrenceId === null || _REALM.STORE.transactionStore.state.transaction.recurrenceId === '') &&
			!_REALM.STORE.givingFormStore.isOneTimePaymentOnly()
		);
	},
	canCreatePledge(availableCampaigns) {
		return _REALM.STORE.givingFormStore.state.givingForm.showAddPledge && _REALM.STORE.transactionStore.state.transaction.recurrenceId != null && availableCampaigns.length > 0 && !_REALM.STORE.layoutStore.isPaymentForm();
	},
	useDefaultFormStyles() {
		return _REALM.STORE.givingFormStore.isAdminManaging() || _REALM.STORE.transactionStore.state.transaction.integrationType === _REALM.STORE.transactionStore.enums.IntegrationType.REALM || _REALM.VM.$route.query.recurrenceId;
	}
};
