<template>
	<v-dialog :value="value" :width="width" :max-width="maxWidth" :scrollable="scrollable" :persistent="persistent" :hide-overlay="computedHideOverlay" :content-class="alignTop ? 'align-self-start' : ''" @input="$emit('input', $event)">
		<r-card v-if="value" :style="{ maxHeight: computedMaxHeight }" :type="type">
			<template v-if="this.$slots.title" slot="title">
				<slot name="title"></slot>
			</template>
			<v-container fluid grid-list-lg pa-2>
				<slot></slot>
			</v-container>
			<template v-if="this.$slots.actions" slot="actions">
				<slot name="actions"></slot>
			</template>
		</r-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		width: [String, Number],
		maxWidth: String,
		maxHeight: String,
		scrollable: Boolean,
		hideOverlay: Boolean,
		persistent: Boolean,
		type: String
	},
	computed: {
		computedHideOverlay() {
			return this.hideOverlay;
		},
		computedMaxHeight() {
			return this.maxHeight ? this.maxHeight + 'px' : 'none';
		},
		alignTop() {
			return _REALM.TOOLS.inIframe() && window.parentIFrame;
		}
	},
	watch: {
		value(val) {
			if (val && _REALM.TOOLS.inIframe()) {
				_REALM.TOOLS.scrollTopIframe();
			}

			if (_REALM.TOOLS.inIframe()) {
				window.parentIFrame.sendMessage({
					type: 'dialog',
					value: val
				});
			}
		}
	},
	mounted() {
		this.addStyles();
	},
	methods: {
		addStyles() {
			//If iFrame position top of page
			if (_REALM.TOOLS.inIframe() && !_REALM.STORE.givingFormStore.isGivingFormEmbed()) {
				var all = document.getElementsByClassName('v-dialog');
				for (var i = 0; i < all.length; i++) {
					all[i].style.position = 'absolute';
					all[i].style.top = 0;
					all[i].style.marginTop = 0;
				}
			}
		}
	}
};
</script>
