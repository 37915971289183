import reportService from '../services/reportService';

export function initState() {
	return {};
}

export default {
	state: initState(),
	getSavedQueries() {
		return reportService.getSavedQueries();
	},
	pollCsvStatus(options) {
		reportService.pollStatus(options);
	},
	getReportUrl(reportId, fileType) {
		return reportService.getReportUrl(reportId, fileType);
	}
};
