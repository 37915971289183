import givingNumberService from '../services/givingNumberService';

export function initState() {
	return {
		reassignment: {},
		isPending: true,
		showReassignmentDialog: false,
		showRunReassignmentDialog: false,
		showDeleteReassignmentDialog: false
	};
}

export default {
	state: initState(),
	reassignment() {
		return givingNumberService.reassignment().then(function(reassignment) {
			_REALM.STORE.givingNumberStore.state.reassignment = reassignment;
		});
	},
	saveSettings(args) {
		return givingNumberService.saveSettings(args);
	},
	saveReassignment(args) {
		return givingNumberService.saveReassignment(args);
	},
	runReassignment() {
		return givingNumberService.runReassignment();
	},
	deleteReassignment() {
		return givingNumberService.deleteReassignment();
	},
	getReportUrl(reportId, individualId) {
		var args = {
			reportId: reportId,
			createdByIndividualId: individualId
		};
		return givingNumberService.getReportUrl(args);
	}
};
