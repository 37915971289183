import transactionService from '../services/transactionService';
//import fundStore from '../modules/fundstore';

import { formatDollarsToCents, formatCentsToDollars } from '@/helpers/formatters';

import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import moment from 'moment';
import sumBy from 'lodash/sumBy';

var transactionEnums = {
	mint_PaymentMethod_PaymentMethodType: {
		CHECKING: 0,
		SAVINGS: 1,
		CREDIT: 2,
		DEBIT: 3
	},
	mint_Recurrence_CompletionType: {
		UNTIL_I_CANCEL: 0,
		NUMBER_OF_TIMES: 1,
		STOP_DATE: 2
	},
	mint_PaymentMethod_CreditCardType: {
		VISA: 0,
		MASTERCARD: 1,
		DISCOVER: 2,
		AMERICANEXPRESS: 3
	},
	DateFilterType: {
		DateFilterType_NOT_SET: 0,
		PROCESSTIME: 1,
		MODIFIEDTIME: 2
	},
	DeviceType: {
		DEVICETYPE_NOT_SET: 0,
		DESKTOP: 1,
		MOBILE: 2
	},
	mint_Recurrence_FrequencyType: {
		ONCE: 0,
		WEEKLY: 1,
		EVERY_OTHER_WEEK: 2,
		MONTHLY: 3,
		TWICE_A_MONTH: 4,
		EVERY_THREE_MONTHS: 5
	},
	IntegrationType: {
		INTEGRATIONTYPE_NOT_SET: 0,
		WEBSITE: 1,
		EMBED: 2,
		TEXT: 3,
		PLUGIN: 4,
		REALM: 5,
		GOMETHOD: 6
	},
	mint_PaymentProcessedData_PaymentProcessedType: {
		TEXTGIVING: 0,
		RECONCILIATION: 1
	},
	mint_Recurrence_RecurrenceStatus: {
		ACTIVE: 0,
		DEACTIVATED: 1,
		ONHOLD: 2,
		COMPLETE: 3
	},
	mint_Transaction_TransactionType: {
		TRANSACTION: 0,
		ERROR: 1,
		QUEUE: 2
	},
	Transaction_Type: {
		TRANSACTION: 0,
		VOID: 1,
		REFUND: 2,
		RETURN: 3
	}
};

var resetTransaction = function() {
	return {
		paymentMethodId: null,
		isProcessingCostEnabled: false,
		recurrenceId: null,
		donorHasAccount: false,
		donorHasProfile: false,
		donorSentAccountInvite: false,
		individualId: null,
		externalData: {},
		recurrenceData: {},
		currentCount: 0,
		giftDate: moment(),
		name: null,
		email: null,
		amountsList: [
			{
				amount: null,
				fundId: null,
				memo: null
			}
		],
		completionType: transactionEnums.mint_Recurrence_CompletionType.UNTIL_I_CANCEL,
		isRecurrence: false,
		frequencyType: transactionEnums.mint_Recurrence_FrequencyType.ONCE,
		stopDate: null,
		numberOfTimes: 52,
		firstDayOfMonth: 1,
		secondDayOfMonth: 15,
		totalGiftAmount: 0,
		giftStartTime: moment(),
		recurrenceCount: 1,
		giftDuration: 0,
		textToken: null,
		recurrenceAmounts: [],
		pledgeFundId: null,
		isNewProfile: false,
		sourceId: null,
		memorialId: null
	};
};

var lastGiftKey = 'lastGiftKey';

export function initState() {
	return {
		transactions: [],
		transaction: resetTransaction(),
		batches: [],
		recurrences: [],
		lastGift: {},
		returnUrl: '',
		alreadyPaidAmount: 0
	};
}

var setTransactionData = function() {
	_REALM.STORE.transactionStore.state.transaction.siteId = _REALM.STORE.siteStore.state.site.siteId;

	_REALM.STORE.transactionStore.state.transaction.formId = _REALM.STORE.givingFormStore.state.givingForm.formId;

	_REALM.STORE.transactionStore.state.transaction.individualId = _REALM.STORE.givingFormStore.state.transactionAccount.individualId;
	_REALM.STORE.transactionStore.state.transaction.mintCustomerId = _REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId;
	_REALM.STORE.transactionStore.state.transaction.billingEmail = _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail;
	if (!_REALM.STORE.transactionStore.state.transaction.integrationType) {
		_REALM.STORE.transactionStore.state.transaction.integrationType = transactionEnums.IntegrationType.WEBSITE;
	}

	_REALM.STORE.transactionStore.state.transaction.deviceType = _REALM.TOOLS.browser().mobile ? transactionEnums.DeviceType.MOBILE : transactionEnums.DeviceType.DESKTOP;

	_REALM.STORE.transactionStore.addProcessingCostAmountGroup();
	_REALM.STORE.transactionStore.removeZeroAmountSplits();

	if (!_REALM.STORE.transactionStore.isEditingRecurrence()) {
		_REALM.STORE.transactionStore.state.transaction.lastGiftId = _REALM.STORE.transactionStore.saveLastGiftId();
	}

	if (_REALM.STORE.accountStore.isLoggedIn() || _REALM.STORE.transactionStore.state.transaction.donorHasAccount || _REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite) {
		_REALM.STORE.transactionStore.state.transaction.isNewProfile = false;
	}
};

export default {
	state: initState(),
	resetTransaction() {
		_REALM.STORE.transactionStore.state.transaction = resetTransaction();
	},
	validateTransactionStartDate() {
		if (_REALM.STORE.transactionStore.state.transaction.giftDate.diff(moment().startOf('day'), 'days') < 0 && (_REALM.STORE.transactionStore.state.transaction.recurrenceId === null || _REALM.STORE.transactionStore.state.transaction.currentCount === 0)) {
			_REALM.TOAST.error('Gift Date cannot be in the past');
			return false;
		}

		return true;
	},
	validateTransactionStopDate() {
		if (_REALM.STORE.transactionStore.state.transaction.completionType === _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE && _REALM.STORE.transactionStore.state.transaction.stopDate.diff(moment().startOf('day'), 'days') < 0) {
			_REALM.TOAST.error('End Date cannot be in the past');
			return false;
		}

		return true;
	},
	processTransaction() {
		setTransactionData();
		if (_REALM.STORE.accountStore.isLoggedIn()) {
			return transactionService.processTransaction(_REALM.STORE.transactionStore.state.transaction).then(function(resp) {
				_REALM.STORE.transactionStore.state.transaction = { ..._REALM.STORE.transactionStore.state.transaction, ...resp };
				return _REALM.STORE.transactionStore.state.transaction;
			});
		}

		return transactionService.processUnauthenticatedTransaction(_REALM.STORE.transactionStore.state.transaction).then(function(resp) {
			// Check if invite sent from pledge form
			let donorSentAccountInvite = _REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite;
			_REALM.STORE.transactionStore.state.transaction = { ..._REALM.STORE.transactionStore.state.transaction, ...resp };
			_REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite = donorSentAccountInvite === true ? true : _REALM.STORE.transactionStore.state.transaction.donorSentAccountInvite;
			_REALM.EVENT.emit('payment-processed', _REALM.STORE.transactionStore.state.transaction);
			return _REALM.STORE.transactionStore.state.transaction;
		});
	},
	setEditRecurrence(recurrence) {
		//Don't reset recurrence when already editing
		if (_REALM.STORE.transactionStore.state.transaction.recurrenceId || recurrence === null) {
			return null;
		}

		var integrationType = _REALM.STORE.transactionStore.state.transaction.integrationType;
		_REALM.STORE.transactionStore.resetTransaction();
		_REALM.STORE.transactionStore.state.transaction.integrationType = integrationType;
		var recurrenceAmountsList = [];
		for (var i = 0; i < recurrence.amounts.length; i++) {
			if (_REALM.STORE.givingStore.state.settings.isProcessingCostEnabled && recurrence.amounts[i].solutionFundId === _REALM.STORE.givingStore.state.settings.processingCostFund.fundId) {
				_REALM.STORE.transactionStore.state.transaction.isProcessingCostEnabled = true;
			} else {
				recurrenceAmountsList.push({
					amount: formatDollarsToCents(recurrence.amounts[i].amount),
					memo: recurrence.amounts[i].memo,
					fundId: recurrence.amounts[i].solutionFundId
				});
			}
		}

		_REALM.STORE.transactionStore.state.transaction.paymentMethodId = recurrence.paymentMethodId;
		_REALM.STORE.transactionStore.state.transaction.recurrenceId = recurrence.recurrenceId;
		_REALM.STORE.transactionStore.state.transaction.amountsList = recurrenceAmountsList;
		_REALM.STORE.transactionStore.state.transaction.completionType = recurrence.completionType;
		_REALM.STORE.transactionStore.state.transaction.frequencyType = recurrence.frequencyType;
		_REALM.STORE.transactionStore.state.transaction.recurrenceData = recurrence.recurrenceData;

		_REALM.STORE.transactionStore.state.transaction.currentCount = recurrence.currentCount;
		recurrence.originalStartDate = moment(recurrence.originalStartDate);
		if (recurrence.currentCount == 0 && recurrence.originalStartDate.diff(moment(), 'days') < 0) {
			recurrence.originalStartDate = moment().startOf('day');
		}

		_REALM.STORE.transactionStore.state.transaction.startDate = recurrence.originalStartDate;
		_REALM.STORE.transactionStore.state.transaction.giftDate = recurrence.originalStartDate;

		_REALM.STORE.transactionStore.state.transaction.numberOfTimes = recurrence.recurrenceData.occurrenceCount ? recurrence.recurrenceData.occurrenceCount : 0;
		_REALM.STORE.transactionStore.state.transaction.stopDate = recurrence.recurrenceData.stopDate ? moment(recurrence.recurrenceData.stopDate) : moment().add(1, 'year');

		if (recurrence.frequencyType === transactionEnums.mint_Recurrence_FrequencyType.TWICE_A_MONTH) {
			_REALM.STORE.transactionStore.state.transaction.firstDayOfMonth = recurrence.recurrenceData.day1 ? parseInt(recurrence.recurrenceData.day1, 10) : _REALM.STORE.transactionStore.state.transaction.firstDayOfMonth;
			_REALM.STORE.transactionStore.state.transaction.secondDayOfMonth = recurrence.recurrenceData.day2 ? parseInt(recurrence.recurrenceData.day2, 10) : _REALM.STORE.transactionStore.state.transaction.secondDayOfMonth;
		}

		_REALM.STORE.transactionStore.state.transaction.recurrenceAmounts = recurrence.amounts;

		if (recurrence.externalData.hasOwnProperty('memorialId') && recurrence.externalData.memorialId) {
			_REALM.STORE.transactionStore.state.transaction.memorialId = recurrence.externalData.memorialId;
			_REALM.STORE.memorialStore.buildSelectableGivingFormMemorials(recurrence.externalData.memorialId);
		}
	},
	removeZeroAmountSplits() {
		var i = _REALM.STORE.transactionStore.state.transaction.amountsList.length;
		while (i--) {
			if (_REALM.STORE.transactionStore.state.transaction.amountsList[i].amount == 0) {
				_REALM.STORE.transactionStore.state.transaction.amountsList.splice(i, 1);
			}
		}
	},
	addProcessingCostAmountGroup() {
		var processCostIndex = _findIndex(_REALM.STORE.transactionStore.state.transaction.amountsList, function(o) {
			return _REALM.STORE.givingStore.state.settings.isProcessingCostEnabled && o.fundId == _REALM.STORE.givingStore.state.settings.processingCostFund.fundId;
		});

		//Always remove processing cost from amounts if exist
		if (processCostIndex > -1) {
			_REALM.STORE.transactionStore.state.transaction.amountsList.splice(processCostIndex, 1);
		}

		if (_REALM.STORE.transactionStore.state.transaction.isProcessingCostEnabled) {
			_REALM.STORE.transactionStore.state.transaction.amountsList.push({
				amount: _REALM.STORE.transactionStore.possibleProcessingCostAmount(),
				fundId: _REALM.STORE.givingStore.state.settings.processingCostFund.fundId,
				memo: null,
				isProcessingCostFund: true
			});
		}
	},
	getRecurringGift(recurrenceId) {
		return transactionService.getRecurringGift(recurrenceId);
	},
	calculatePledgeMessage(transaction, individualId) {
		var args = {
			IndividualId: individualId,
			FundId: transaction.amountsList[0].fundId,
			Amount: formatCentsToDollars(transaction.amountsList[0].amount),
			StartDate: transaction.giftDate,
			Frequency: transaction.frequencyType
		};

		var frequencyType = transactionEnums.mint_Recurrence_FrequencyType;
		var completionType = transactionEnums.mint_Recurrence_CompletionType;

		if (transaction.frequencyType !== frequencyType.ONCE && transaction.completionType === completionType.STOP_DATE) {
			args.StopDate = transaction.stopDate;
		} else if (transaction.frequencyType !== frequencyType.ONCE && transaction.completionType === completionType.NUMBER_OF_TIMES) {
			args.NumberOfTimes = transaction.numberOfTimes;
		} else if (transaction.frequencyType !== frequencyType.ONCE && transaction.completionType === completionType.UNTIL_I_CANCEL) {
			args.UntilICancel = true;
		}

		args.Day1 = transaction.giftDate.date();

		if (transaction.frequencyType === transactionEnums.mint_Recurrence_FrequencyType.WEEKLY || transaction.frequencyType === transactionEnums.mint_Recurrence_FrequencyType.EVERY_OTHER_WEEK) {
			args.Day1 = transaction.giftDate.day();
		}

		if (transaction.frequencyType === transactionEnums.mint_Recurrence_FrequencyType.TWICE_A_MONTH) {
			args.Day1 = transaction.firstDayOfMonth;
			args.Day2 = transaction.secondDayOfMonth;
		}

		var fund = _find(_REALM.STORE.fundStore.state.onlineFunds, function(fund) {
			return fund.fundId === args.FundId;
		});

		var fundName;
		if (fund) {
			fundName = fund.displayName ? fund.displayName : fund.name;
		}

		return transactionService.calculatePledgeMessage(args).then(function(result) {
			var messages = [];
			if (result) {
				for (var i = 0; i < result.length; i++) {
					if (result[i].totalGivenToDate + result[i].totalProjected < result[i].totalAmount) {
						var message = {};

						if (
							(transaction.frequencyType !== frequencyType.ONCE && result[i].totalCalculated + result[i].totalGivenToDate + result[i].totalProjected >= result[i].totalAmount) ||
							(transaction.frequencyType === frequencyType.ONCE && result[i].totalCalculated + result[i].totalGivenToDate >= result[i].totalAmount)
						) {
							message.success = true;
							message.text = 'This will complete your pledge to ' + fundName + ' within ' + result[i].startDate + ' - ' + result[i].stopDate;
							messages.push(message);
						} else {
							if (transaction.frequencyType !== frequencyType.ONCE) {
								message.success = false;
								message.text = 'This will not complete your pledge to ' + fundName + ' within ' + result[i].startDate + ' - ' + result[i].stopDate;
								messages.push(message);
							}
						}
					}
				}
			}

			return messages;
		});
	},
	addAmountGroup() {
		_REALM.STORE.transactionStore.state.transaction.amountsList.push({
			amount: null,
			fundId: null,
			memo: null
		});
	},
	removeAmountGroup(index) {
		_REALM.STORE.transactionStore.state.transaction.amountsList.splice(index, 1);
	},
	totalGiftAmount() {
		var totalAmount = sumBy(_REALM.STORE.transactionStore.state.transaction.amountsList, function(amountGroup) {
			return amountGroup.amount && (!_REALM.STORE.givingStore.state.settings.isProcessingCostEnabled || amountGroup.fundId !== _REALM.STORE.givingStore.state.settings.processingCostFund.fundId) ? parseInt(amountGroup.amount, 10) : 0;
		});

		return totalAmount;
	},
	totalGiftAmountWithProcessingCost() {
		var processingCost = _REALM.STORE.transactionStore.state.transaction.isProcessingCostEnabled ? _REALM.STORE.transactionStore.possibleProcessingCostAmount() : 0;
		return _REALM.STORE.transactionStore.totalGiftAmount() + processingCost;
	},
	possibleProcessingCostAmount() {
		var processAmount = 0,
			processingCostPercent = 0,
			paymentMethodType;

		var paymentMethodId = _REALM.STORE.transactionStore.state.transaction.paymentMethodId;
		var paymentMethod = _find(_REALM.STORE.paymentMethodStore.state.paymentMethods, { paymentMethodId });

		if (paymentMethod && paymentMethod.paymentMethodType) {
			if (paymentMethod.paymentMethodType === 'Credit') {
				paymentMethodType = _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CREDIT;
			} else if (['Savings', 'Checking'].includes(paymentMethod.paymentMethodType)) {
				paymentMethodType = _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CHECKING;
			} else if (paymentMethod.paymentMethodType === 'Debit') {
				paymentMethodType = _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.DEBIT;
			}
		} else {
			var accountType = _REALM.STORE.paymentMethodStore.state.addPaymentMethodForm.accountType;
			if (accountType === 'CC') {
				paymentMethodType = _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CREDIT;
			} else if (accountType === 'C') {
				paymentMethodType = _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CHECKING;
			}
		}

		if (paymentMethodType === _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CHECKING || paymentMethodType === _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.SAVINGS) {
			processingCostPercent = _REALM.STORE.givingStore.state.settings.achCostPercent;
		} else if (paymentMethodType === _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.CREDIT || paymentMethodType === _REALM.STORE.transactionStore.enums.mint_PaymentMethod_PaymentMethodType.DEBIT) {
			processingCostPercent = _REALM.STORE.givingStore.state.settings.creditDebitCostPercent;
		}

		processAmount = (processingCostPercent / 100) * _REALM.STORE.transactionStore.totalGiftAmount(false);
		return Math.round((parseFloat(processAmount) * 100) / 100);
	},
	saveLastGiftId() {
		var giftId = _REALM.TOOLS.newGuid();
		var duration = moment.duration('24:00:00')._milliseconds;
		_REALM.STORAGE.set(lastGiftKey, giftId, duration);
		return giftId;
	},
	getLastGift() {
		var lastGiftId = _REALM.STORE.transactionStore.getLastGiftId(),
			emailAddress = _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail,
			editingRecurrence = _REALM.STORE.transactionStore.isEditingRecurrence();

		if (lastGiftId && emailAddress && !editingRecurrence) {
			return transactionService.getLastGift(lastGiftId, emailAddress).then(function(lastGift) {
				_REALM.STORE.transactionStore.state.lastGift = lastGift;
				return _REALM.STORE.transactionStore.state.lastGift;
			});
		} else {
			return Promise.resolve();
		}
	},
	getLastGiftId() {
		return _REALM.STORAGE.get(lastGiftKey);
	},
	isEditingRecurrence() {
		return _REALM.VM.$route.query.recurrenceId ? true : false;
	},
	getRecurrenceCount(args) {
		return transactionService.getRecurrenceCount(args);
	},
	getFrequencyData() {
		const giftFrequency = Object.values(STRATUS.EnumImports.RecurringGiftFrequency).find(freq => freq.Value === _REALM.STORE.transactionStore.state.transaction.frequencyType);
		return giftFrequency || null;
	},
	getGivingFormDataUrlString(ssoReturn = true) {
		const transaction = _REALM.STORE.transactionStore.state.transaction;
		const givingFormData = {
			amountsList: transaction.amountsList,
			completionType: transaction.completionType,
			firstDayOfMonth: transaction.firstDayOfMonth,
			frequencyType: transaction.frequencyType,
			giftDate: transaction.giftDate.format('MM/DD/YYYY'),
			numberOfTimes: transaction.numberOfTimes,
			recurrenceCount: transaction.recurrenceCount,
			secondDayOfMonth: transaction.secondDayOfMonth,
			stopDate: transaction.stopDate ? transaction.stopDate.format('MM/DD/YYYY') : null,
			ssoReturn: ssoReturn
		};

		return JSON.stringify(givingFormData);
	},
	readPaymentToken(token) {
		return transactionService.readPaymentToken(token);
	},
	loadGivingFormQueryData(givingFormData) {
		_REALM.STORE.givingFormStore.state.givingFormQueryData = givingFormData;
		for (const [key, value] of Object.entries(givingFormData)) {
			if (key.includes('address') && !_REALM.STORE.givingFormStore.state.transactionAccount.hasOwnProperty('primaryAddress')) {
				_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress = {};
			}

			switch (key) {
				case 'amountsList':
					_REALM.STORE.transactionStore.state.transaction.amountsList = value;
					break;
				case 'completionType':
					_REALM.STORE.transactionStore.state.transaction.completionType = value;
					break;
				case 'firstDayOfMonth':
					_REALM.STORE.transactionStore.state.transaction.firstDayOfMonth = value;
					break;
				case 'secondDayOfMonth':
					_REALM.STORE.transactionStore.state.transaction.secondDayOfMonth = value;
					break;
				case 'frequencyType':
					_REALM.STORE.transactionStore.state.transaction.frequencyType = value;
					break;
				case 'giftDate':
					_REALM.STORE.transactionStore.state.transaction.giftDate = moment(value);
					break;
				case 'numberOfTimes':
					_REALM.STORE.transactionStore.state.transaction.numberOfTimes = value;
					break;
				case 'recurrenceCount':
					_REALM.STORE.transactionStore.state.transaction.recurrenceCount = value;
					break;
				case 'stopDate':
					_REALM.STORE.transactionStore.state.transaction.stopDate = value ? moment(value) : null;
					break;
				case 'isSsoReturn':
					_REALM.STORE.givingFormStore.state.isSsoReturn = value;
					break;
				case 'name':
					_REALM.STORE.givingFormStore.state.transactionAccount.label = value;
					break;
				case 'email':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail = value;
					break;
				case 'addressStreet1':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.address1 = value;
					break;
				case 'addressStreet2':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.address2 = value;
					break;
				case 'addressCity':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.city = value;
					break;
				case 'addressRegion':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.region = value;
					break;
				case 'addressCountry':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.country = value;
					break;
				case 'addressPostalCode':
					_REALM.STORE.givingFormStore.state.transactionAccount.primaryAddress.postalCode = value;
					break;
				case 'returnUrl':
					_REALM.STORE.transactionStore.state.returnUrl = value;
					break;
				case 'error':
					_REALM.STORE.givingFormStore.state.givingFormError = value;
					break;
				case 'alreadyPaidAmount':
					_REALM.STORE.transactionStore.state.alreadyPaidAmount = value;
					break;
				default:
					break;
			}
		}
	},
	enums: transactionEnums
};
