import realmClient from '../realmClient';
import moment from 'moment';

export default {
	getGivingFormIndividual(individualId) {
		return realmClient.post('Giving/GetGivingFormIndividual', { individualId });
	},
	getMintCustomerFromToken(token) {
		return realmClient.post('Giving/GetMintCustomerFromToken', { token }, { handleError: false });
	},
	addGivingForm(givingForm) {
		return realmClient.post('GivingForm/Add', givingForm).then(function(givingForm) {
			convertTime(givingForm);
			return givingForm;
		});
	},
	updateGivingForm(givingForm) {
		return realmClient.post('GivingForm/Update', givingForm).then(function(givingForm) {
			convertTime(givingForm);
			return givingForm;
		});
	},
	getGivingForm(formId) {
		return realmClient.get('GivingForm/Get', { formId }).then(function(givingForm) {
			convertTime(givingForm);
			return givingForm;
		});
	},
	getDefaultGivingForm() {
		return realmClient.get('GivingForm/GetDefault').then(function(givingForm) {
			if (Object.keys(givingForm).length === 0) {
				return null;
			}

			convertTime(givingForm);
			return givingForm;
		});
	},
	getGivingFormByURL(givingFormUrl) {
		return realmClient.get('GivingForm/GetByUrl', { givingFormUrl }).then(function(givingForm) {
			if (Object.keys(givingForm).length === 0) {
				return null;
			}

			convertTime(givingForm);
			return givingForm;
		});
	},
	listGivingForms() {
		return realmClient.get('GivingForm/List').then(function(givingForms) {
			for (var i = 0; i < givingForms.length; i++) {
				convertTime(givingForms[i]);
			}
			return givingForms;
		});
	},
	toggleActiveGivingForm(formId) {
		return realmClient.post('GivingForm/ToggleActiveGivingForm', { formId });
	}
};

var convertTime = function(givingForm) {
	givingForm.mostRecentGiftTime = givingForm.mostRecentGiftTime ? moment(givingForm.mostRecentGiftTime) : null;
	givingForm.dateDeactivated = givingForm.dateDeactivated ? moment(givingForm.dateDeactivated) : null;
	givingForm.dateCreated = givingForm.dateCreated ? moment(givingForm.dateCreated) : null;
	givingForm.dateModified = givingForm.dateModified ? moment(givingForm.dateModified) : null;
};
