import campaignService from '../services/campaignService';

export function initState() {
	return {
		campaign: {},
		availableCampaigns: []
	};
}

export default {
	state: initState(),
	listAvailableCampaigns(args) {
		return campaignService.listAvailableCampaigns(args).then(availableCampaigns => {
			const transactionFundIds = _REALM.STORE.transactionStore.state.transaction.amountsList.map(function(obj) {
				return obj.fundId;
			});

			_REALM.STORE.campaignStore.state.availableCampaigns = availableCampaigns.filter(function(c) {
				return transactionFundIds.includes(c.fundId) && _REALM.STORE.transactionStore.state.transaction.pledgeFundId != c.fundId && _REALM.STORE.transactionStore.state.transaction.giftDate.isSameOrAfter(c.startDate, 'day');
			});

			return _REALM.STORE.campaignStore.state.availableCampaigns;
		});
	},
	listActiveCampaigns() {
		return campaignService.listActiveCampaigns();
	},
	getCampaign(campaignId) {
		return campaignService.getCampaign(campaignId).then(function(campaign) {
			_REALM.STORE.campaignStore.state.campaign = campaign;
			return _REALM.STORE.campaignStore.state.campaign;
		});
	}
};
