import Vue from 'vue';
export const EventBus = new Vue();

export default {
	emit(name, data) {
		EventBus.$emit(name, data);
	},
	on(self, name, callback) {
		self.onEvent(name, callback);
	}
};
