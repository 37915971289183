<style lang="scss">
.media-image {
	vertical-align: bottom;

	&.circle {
		border-radius: 50%;
	}
}
</style>

<template>
	<div v-if="showMediaImage" :style="computedStyles">
		<img :style="computedStyles" class="media-image" :src="mediaImagePath" @load="imageLoaded" @loaded="imageLoaded" @error="imageError" />
	</div>
</template>

<script>
export default {
	props: {
		imageId: {
			type: String
		},
		defaultText: {
			type: String,
			validator(value) {
				return value.length <= 2;
			}
		},
		imageType: {
			type: Number,
			required: true,
			validator(value) {
				return Object.keys(_REALM.STORE.mediaStore.ImageTypes)
					.map(k => _REALM.STORE.mediaStore.ImageTypes[k])
					.includes(value);
			}
		},
		width: {
			type: Number
		},
		height: {
			type: Number
		},
		borderRadius: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			mediaImageId: null,
			mediaImagePath: null,
			mediaImageType: null,
			originalMediaImageType: null,
			showMediaImage: true,
			showAlternateAvatar: false
		};
	},
	computed: {
		computedStyles() {
			var styles = {};

			if (this.width) {
				styles.maxWidth = this.width + 'px';
			}

			if (this.height) {
				styles.maxHeight = this.height + 'px';
			}

			if (this.color) {
				var hexColor = _REALM.TOOLS.findColor(this.color);
				styles.color = _REALM.TOOLS.getContrastColor(hexColor);
			}

			if (this.borderRadius !== null) {
				styles.borderRadius = this.borderRadius + '%';
			}

			return styles;
		}
	},
	watch: {
		mediaImageType() {
			this.getImageURL();
		}
	},
	created() {
		this.originalMediaImageType = this.imageType;
		this.setDefaultData();
		this.getImageURL();
		var self = this;
		_REALM.EVENT.on(this, this.imageId + '-refresh', function() {
			self.getImageURL(true);
		});
	},
	methods: {
		setDefaultData() {
			this.mediaImageType = this.imageType;
			this.mediaImageId = this.imageId;
		},
		getImageURL(refresh) {
			if (refresh) {
				this.showMediaImage = true;
				this.showAlternateAvatar = false;
				this.setDefaultData();
			}

			if (this.mediaImageId) {
				this.mediaImagePath = _REALM.STORE.mediaStore.getImageURL(this.mediaImageType, this.mediaImageId, this.width, this.height);
			}
		},
		imageLoaded() {
			this.showMediaImage = true;
			this.showAlternateAvatar = false;
			this.$emit('imageLoaded');
		},
		imageError() {
			this.showMediaImage = false;
			this.$emit('imageError');
			if (this.originalMediaImageType == _REALM.STORE.mediaStore.ImageTypes.LOGO) {
				this.handleGivingFormImageError(); //Handle non admin managed giving form
			}
		},
		handleGivingFormImageError() {
			this.showMediaImage = true;

			this.mediaImagePath = _REALM.CONFIG.StratusHost + '/Assets/SPA/images/giving/giving-form-default-logo@2x.png';
		}
	}
};
</script>
