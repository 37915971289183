import _each from 'lodash/each';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';
import _isPlainObject from 'lodash/isPlainObject';
import _mapValues from 'lodash/mapValues';

import browserDetect from 'browser-detect';
import cssVariables from '@/scss/variables.scss';
import confirmDialog from '@/helpers/confirmDialog/index';
import iframeHelper from '@/helpers/iframeHelper';

export function buildSelectList(dataList, valueKey, textKey, subTextKey) {
	var selectList = [];
	_each(dataList, function(data) {
		selectList.push({
			text: data[textKey],
			value: data[valueKey],
			subText: data[subTextKey]
		});
	});

	return selectList;
}

export function buildEnumSelectList(dataList) {
	var selectList = [];
	for (var key in dataList) {
		var enumValue = dataList[key];

		selectList.push({
			value: enumValue,
			text: key
		});
	}

	return selectList;
}

export function newGuid() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}

export function noDashGuid(value) {
	return value.replace(/-/g, '');
}

export function isGuid(value) {
	if (value && value !== '00000000-0000-0000-0000-000000000000') {
		return true;
	}
	return false;
}

export function isNullOrEmpty(value) {
	return !value || !value.trim();
}

var storeBrowser;
export function browser() {
	if (!storeBrowser) {
		storeBrowser = browserDetect();
		storeBrowser.isAndroid = storeBrowser.os ? storeBrowser.os.toLowerCase().includes('android') : false;
		storeBrowser.isIOS = storeBrowser.os ? storeBrowser.os.toLowerCase() === 'os x' : false;
	}

	return storeBrowser;
}

export function downloadUrl(url, fileName) {
	var a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	a.href = url;
	a.download = fileName;
	a.click();
	a.remove();
}

export function buildSiteLink(route) {
	return _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/' + route;
}

export function copyToClipboard(value) {
	try {
		var range;
		var copyElement = document.createElement('DIV');
		copyElement.textContent = value;
		document.body.appendChild(copyElement);

		if (document.selection) {
			range = document.body.createTextRange();
			range.moveToElementText(copyElement);
			range.select();
		} else if (window.getSelection) {
			range = document.createRange();
			range.selectNode(copyElement);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
		}

		var copySuccess = document.execCommand('copy');

		copyElement.remove();

		if (copySuccess) {
			_REALM.TOAST.success('Copied to clipboard.');
		} else {
			_REALM.TOAST.error('Unable to copy.');
		}
	} catch (error) {
		_REALM.TOAST.error('Unable to copy.');
	}
}

export function getContrastColor(hex) {
	hex = _REALM.TOOLS.findColor(hex);
	if (hex === '#ffffff') {
		return cssVariables['color-black'];
	} else if (hex === '#000000') {
		return cssVariables['color-white'];
	}

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	var rgb = result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
		  }
		: null;

	return rgb && (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 >= 192 ? cssVariables['color-black'] : cssVariables['color-white'];
}

export function findColor(color) {
	if (color && !color.includes('#') && cssVariables['color-' + color]) {
		color = cssVariables['color-' + color];
	}

	//Convert hex to 6 digit #000 = #000000
	if (color && color.length === 4) {
		color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
	}

	return color;
}

export function confirm(message, yesCallback, noCallback) {
	return confirmDialog(message, yesCallback, noCallback);
}

export function toCamelCase(value) {
	let clonedValue = _cloneDeep(value);

	if (_isString(clonedValue)) {
		return clonedValue;
	} else if (_isArray(clonedValue)) {
		return _map(clonedValue, _REALM.TOOLS.toCamelCase);
	} else if (_isObject(clonedValue)) {
		clonedValue = _mapKeys(clonedValue, (value, key) => {
			return _camelCase(key);
		});

		return _mapValues(clonedValue, value => {
			if (_isPlainObject(value)) {
				return _REALM.TOOLS.toCamelCase(value);
			} else if (_isArray(value)) {
				return _map(value, _REALM.TOOLS.toCamelCase);
			} else {
				return value;
			}
		});
	} else if (typeof value === 'boolean') {
		return value;
	}
}

export function trimWhiteSpace(str) {
	return str ? str.split(' ').join('') : '';
}

export function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export function scrollTopIframe() {
	_REALM.TOOLS.scrollTo(0, 0);
	const appElement = document.querySelector('.v-application');
	if (appElement) {
		appElement.scrollIntoView();
	}
}

export function scrollBottomIframe() {
	iframeHelper.sendMessage('scroll-bottom');
}

export function scrollMidPage() {
	iframeHelper.sendMessage('scroll-mid-page');
}

export function scrollTo(top, left) {
	if (_REALM.TOOLS.inIframe()) {
		var scrollData = { top: top, left: left };
		iframeHelper.sendMessage('scroll-to', scrollData);
	} else {
		window.scroll({
			top: top ? top : 0,
			left: left ? left : 0,
			behavior: 'smooth'
		});
	}
}
