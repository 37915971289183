import paymentMethodService from '../services/paymentMethodService';
import moment from 'moment';

export function initState() {
	return {
		addPaymentMethodForm: {
			savePaymentMethod: true,
			nickname: null,
			accountType: null,
			expMonth: moment().format('MM'),
			expYear: moment().format('YYYY'),
			routingNumber: null,
			billingName: null,
			billingCountry: null,
			billingAddress1: null,
			billingAddress2: null,
			billingCity: null,
			billingState: null,
			billingProvince: null,
			billingPostalCode: null,
			emailAddress: null
		},
		paymentMethods: []
	};
}

export default {
	state: initState(),
	resetAddPaymentMethodFormState() {
		const currentState = _REALM.STORE.paymentMethodStore.state.addPaymentMethodForm;
		const newState = initState();
		Object.assign(currentState, newState.addPaymentMethodForm);
		delete currentState.cvv2;
	},
	reassignRecurringGifts(oldPaymentMethodId, newPaymentMethodId) {
		return paymentMethodService.reassignRecurringGifts(oldPaymentMethodId, newPaymentMethodId);
	},
	cancelPaymentMethodRecurrences(paymentMethodId) {
		return paymentMethodService.cancelPaymentMethodRecurrences(paymentMethodId);
	},
	deletePaymentMethod(paymentMethodId, individualId) {
		return paymentMethodService.deletePaymentMethod(paymentMethodId, individualId);
	},
	getPaymentMethod(paymentMethodId) {
		return paymentMethodService.getPaymentMethod(paymentMethodId);
	},
	listPaymentMethods(mintCustomerId, includeRecurrences = false, handleError = true) {
		if (_REALM.STORE.accountStore.isLoggedIn() && !_REALM.STORE.givingFormStore.isTextGiver()) {
			return paymentMethodService.listPaymentMethods(mintCustomerId, includeRecurrences, handleError).then(function(response) {
				_REALM.STORE.paymentMethodStore.state.paymentMethods = response;
				return _REALM.STORE.paymentMethodStore.state.paymentMethods;
			});
		} else {
			return new Promise(function(resolve) {
				resolve([]);
			});
		}
	},
	listPaymentMethodsByTextToken(token) {
		if (_REALM.STORE.givingFormStore.isTextGiver()) {
			return paymentMethodService.listPaymentMethodsByTextToken(token).then(function(response) {
				_REALM.STORE.paymentMethodStore.state.paymentMethods = response;
				return _REALM.STORE.paymentMethodStore.state.paymentMethods;
			});
		} else {
			return new Promise(function(resolve) {
				resolve([]);
			});
		}
	}
};
