const errors = {
	'400': 'Bad Request',
	'401': 'Unauthorized',
	'403': 'Forbidden',
	'404': 'Not Found',
	'500': 'Internal Server Error'
};

// Create route for each error code
export default Object.keys(errors).map(errorCode => ({
	name: errorCode,
	path: errorCode,
	component: () => import('@/router/pages/ErrorPage.vue'),
	props: { code: errorCode, status: errors[errorCode] },
	meta: {
		title: errors[errorCode],
		requiresAuth: false
	}
}));
