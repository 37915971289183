<style lang="scss">
@import '@/scss/variables.scss';
.eg-card-wrapper {
	.rounded-card {
		border-radius: 8px;
	}
	.shared-padding {
		padding: 16px !important;

		@media (min-width: $screen-sm-min) {
			padding: 18px !important;
		}

		@media (min-width: $screen-md-min) {
			padding: 24px !important;
		}
	}

	&.v-card.hover-highlight:hover {
		color: $white;
		background-color: $primary;
	}

	.card-title {
		@extend .shared-padding;
		h6 {
			margin-right: 60px !important;
		}
	}

	.card-actions {
		@extend .shared-padding;
	}

	.title-actions {
		position: absolute;
		right: 16px !important;

		@media (min-width: $screen-sm-min) {
			right: 18px !important;
		}

		@media (min-width: $screen-md-min) {
			right: 24px !important;
		}
	}

	.card-text {
		min-height: 65px !important;

		@extend .shared-padding;
		&.card-has-title {
			padding-top: 0 !important;
		}
	}

	&.theme--light {
		.card-text {
			color: $black !important;
		}
	}

	&.theme--dark {
		.card-text {
			color: $white !important;
		}
	}
}
</style>

<template>
	<v-card :hover="hover" :style="computedStyles" :to="to" :dark="computedDark" :color="computedColor" :text="text" class="eg-card-wrapper" :class="{ 'rounded-card': rounded }" light tile :outlined="outlined">
		<div v-if="$slots.header" class="d-flex flex pa-0">
			<slot name="header"></slot>
		</div>
		<v-responsive v-if="$slots.media">
			<slot name="media"></slot>
		</v-responsive>
		<v-card-title v-if="$slots.title" class="card-title" :class="{ 'white--text': type != null, warning: type === 'warning', danger: type === 'danger', primary: type === 'primary', success: type === 'success' }">
			<h5>
				<slot name="title"></slot>
			</h5>
			<span v-if="$slots.titleActions" class="title-actions">
				<slot name="titleActions"></slot>
			</span>
		</v-card-title>
		<v-card-text v-if="$slots.default" :class="{ 'card-has-title': $slots.title }" class="card-text">
			<span v-if="$slots.titleActions && !$slots.title" class="title-actions">
				<slot name="titleActions"></slot>
			</span>
			<slot></slot>
		</v-card-text>
		<v-card-actions v-if="$slots.actions" class="card-actions">
			<slot name="actions"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	props: {
		color: String,
		text: Boolean,
		border: String,
		dark: Boolean,
		to: Object,
		hover: Boolean,
		outlined: Boolean,
		rounded: Boolean,
		type: {
			validator: function(value) {
				return ['success', 'warning', 'danger', 'primary', ''].includes(value);
			}
		}
	},
	data() {
		return {
			layoutState: _REALM.STORE.layoutStore.state
		};
	},
	computed: {
		computedStyles() {
			return {
				border: this.border
			};
		},
		computedDark() {
			return this.dark || this.layoutState.darkTheme;
		},
		computedColor() {
			return _REALM.TOOLS.findColor(this.color);
		}
	}
};
</script>
