import Vue from 'vue';
import Toast from './Toast.vue';

const colors = ['success', 'info', 'error', 'warning'];

const defaultOptions = {
	text: '',
	icon: '',
	color: 'info',
	timeout: 5000
};

let toastComponent = null;

function createToastComponent() {
	const component = new Vue(Toast);
	document.getElementById('app').appendChild(component.$mount().$el);
	return component;
}

function getToastComponent() {
	if (!toastComponent) {
		toastComponent = createToastComponent();
	}

	return toastComponent;
}

function show(options = {}) {
	getToastComponent().show({ ...defaultOptions, ...options });
}

function close() {
	getToastComponent().close();
}

function createShorthands() {
	const shorthands = {};

	colors.forEach(color => (shorthands[color] = (text, options = {}) => show({ color, text, ...options })));

	return shorthands;
}

export default {
	show,
	close,
	getToastComponent,
	defaultOptions,
	...createShorthands()
};
