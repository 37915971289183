<template>
	<v-form ref="vForm" v-model="validForm" lazy-validation @submit.prevent="submitMethod">
		<slot></slot>
	</v-form>
</template>

<script>
import _find from 'lodash/find';
import _each from 'lodash/each';
export default {
	props: {
		value: Boolean,
		submitMethod: Function
	},
	data: function() {
		return {
			validForm: false,
			formChildren: []
		};
	},
	mounted() {
		this.focusOnFirstInput();
	},
	methods: {
		validate() {
			var formInputs = this.$refs.vForm.inputs;

			for (var i = 0; i < formInputs.length; i++) {
				var input = formInputs[i];
				if (!input.validate(true) && input._uid) {
					var childrenComponents = this.findChildren(this.$refs.vForm.$children);
					var goToInput = _find(childrenComponents, ['_uid', input._uid]);

					if (_REALM.TOOLS.inIframe()) {
						var rect = goToInput.$el.getBoundingClientRect();
						var top = rect.top - 100;
						_REALM.TOOLS.scrollTo(top);
					} else {
						this.$vuetify.goTo(goToInput, { offset: -100 });
					}

					break;
				}
			}

			return this.$refs.vForm.validate();
		},
		focusOnFirstInput() {
			//Dont autofocus on mobile
			if (_REALM.TOOLS.browser().mobile) {
				return;
			}
			var self = this;
			var childrenComponents = self.findChildren(self.$refs.vForm.$children);

			if (self.$refs.vForm.inputs && self.$refs.vForm.inputs[0] && self.$refs.vForm.inputs[0]._uid) {
				var goToInput = _find(childrenComponents, ['_uid', self.$refs.vForm.inputs[0]._uid]);
				self.$nextTick(function() {
					setTimeout(function() {
						if (goToInput && goToInput.$refs && goToInput.$refs.input && !self.fieldHasFocus()) {
							goToInput.$refs.input.focus();
						}
					}, 200);
				});
			}
		},
		findChildren(children) {
			var self = this;
			_each(children, function(child) {
				if (child.$children.length > 0) {
					self.findChildren(child.$children);
				}
				self.formChildren.push(child);
			});

			return self.formChildren;
		},
		fieldHasFocus() {
			var focused = document.activeElement;
			if (!focused || focused == document.body) {
				focused = null;
			} else if (document.querySelector) {
				focused = document.querySelector(':focus');
			}

			return focused;
		}
	}
};
</script>
