<style lang="scss">
@import '@/scss/variables.scss';

.table-wrapper {
	@media (max-width: $screen-xs-max) {
		table {
			&.v-table {
				tbody,
				thead {
					td,
					th {
						padding: 0 8px !important;
					}
				}
			}
		}
	}

	.min-width {
		width: 1%;
		white-space: nowrap;
	}
}
</style>
<template>
	<v-data-table v-scroll="onScroll" :headers="computedHeaders" :items="computedItems" :loading="isLoading" disable-pagination :server-items-length="totalItems" :must-sort="true" hide-default-footer class="table-wrapper">
		<template v-slot:body="{ items }">
			<tbody>
				<slot :items="items"></slot>
			</tbody>
		</template>
	</v-data-table>
</template>

<script>
import _findIndex from 'lodash/findIndex';
import _orderBy from 'lodash/orderBy';
import _snakeCase from 'lodash/snakeCase';
import moment from 'moment';
const _defaultLimit = 50;
export default {
	props: {
		headers: Array,
		items: Array,
		actions: Boolean,
		loadItems: Function,
		initSort: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: false,
			page: -1,
			hasMore: true,
			internalItems: [],
			pagination: {
				sortBy: null,
				rowsPerPage: Number.MAX_SAFE_INTEGER
			},
			loadDateTime: null
		};
	},
	computed: {
		computedHeaders() {
			var headers = this.headers;
			if (this.actions) {
				headers.push({
					text: '',
					value: 'actions',
					sortable: false
				});
			}

			return headers;
		},
		totalItems() {
			return this.computedItems.length;
		},
		computedItems() {
			var self = this;
			var sortType = this.pagination.descending ? 'desc' : 'asc';
			return self.items
				? _orderBy(
						self.items,
						function(e) {
							var sortValue = e[self.pagination.sortBy];
							if (sortValue && Array.isArray(sortValue) && sortValue.length > 0) {
								sortValue = sortValue[0];
							}

							if (sortValue && typeof sortValue === 'string' && sortValue.length > 0) {
								sortValue = sortValue.toLowerCase();

								if (!isNaN(sortValue)) {
									sortValue = sortValue.padStart(50, '0');
								}
							}

							return sortValue;
						},
						[sortType]
				  )
				: this.internalItems;
		},
		computedArgs() {
			return {
				loadDateTime: this.loadDateTime,
				sortBy: _snakeCase(this.pagination.sortBy),
				sortDescending: this.pagination.descending,
				offset: this.page < 1 ? 0 : this.page * _defaultLimit + 1,
				limit: _defaultLimit
			};
		}
	},
	watch: {
		pagination: {
			handler() {
				this.refreshItems();
			},
			deep: true
		}
	},
	created() {
		if (this.items === undefined && this.loadItems === undefined) {
			console.error('Table component required items prop or loadItems prop', this.$el);
		}

		this.pagination.descending = this.initSort.descending;
		this.pagination.sortBy = this.initSort.sortBy;
	},
	methods: {
		addItem(item) {
			this.internalItems.unshift(item);
		},
		removeItem(item) {
			var index = _findIndex(this.items, item);
			this.internalItems.splice(index, 1);
		},
		getItems() {
			return this.internalItems;
		},
		updateItem(index, item) {
			this.internalItems[index] = item;
		},
		refreshItems() {
			this.page = -1;
			this.internalItems = [];
			this.hasMore = true;
			this.loadDateTime = moment.utc();
			this.internalLoadItems();
		},
		internalLoadItems() {
			var self = this;
			if (self.loadItems && !self.isLoading && self.hasMore) {
				self.isLoading = true;
				self.page++;
				self
					.loadItems(self.computedArgs)
					.then(function(items) {
						if (items.length === 0) {
							self.hasMore = false;
						}

						self.internalItems = self.internalItems.concat(items);

						//Load more until user can scroll
						if (!self.canScroll()) {
							self.internalLoadItems();
						}
					})
					.finally(function() {
						self.isLoading = false;
					});
			}
		},
		canScroll() {
			return document.documentElement.scrollHeight > document.documentElement.clientHeight;
		},
		onScroll(e) {
			var d = e.target.documentElement;
			var offset = d.scrollTop + window.innerHeight;
			var height = d.offsetHeight;

			if (height - offset <= 400) {
				this.internalLoadItems();
			}
		}
	}
};
</script>
