/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mint_payment_method_pb = require('./mint/payment_method_pb.js');
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.exportSymbol('proto.realm.mintproxy.CacheValueRequest', null, global);
goog.exportSymbol('proto.realm.mintproxy.CacheValueResponse', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetAddPaymentMethodFormRequest', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetAddPaymentMethodFormResponse', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetBankNameRequest', null, global);
goog.exportSymbol('proto.realm.mintproxy.GetBankNameResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetAddPaymentMethodFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetAddPaymentMethodFormRequest.displayName = 'proto.realm.mintproxy.GetAddPaymentMethodFormRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetAddPaymentMethodFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: (f = msg.getCustomerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    originalPaymentMethodId: (f = msg.getOriginalPaymentMethodId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    includeCreditCardLogos: jspb.Message.getFieldWithDefault(msg, 4, false),
    includeCheckingHelpers: jspb.Message.getFieldWithDefault(msg, 5, false),
    includeFieldNamesInValidation: jspb.Message.getFieldWithDefault(msg, 6, false),
    requireBillingFields: jspb.Message.getFieldWithDefault(msg, 7, false),
    includeBlankState: jspb.Message.getFieldWithDefault(msg, 8, false),
    requiredClass: jspb.Message.getFieldWithDefault(msg, 9, ""),
    errorValidationClass: jspb.Message.getFieldWithDefault(msg, 10, ""),
    successValidationClass: jspb.Message.getFieldWithDefault(msg, 11, ""),
    validateOnSubmitOnly: jspb.Message.getFieldWithDefault(msg, 12, false),
    defaultExpirationDate: jspb.Message.getFieldWithDefault(msg, 13, false),
    addedByStaff: jspb.Message.getFieldWithDefault(msg, 14, false),
    addedByGuest: jspb.Message.getFieldWithDefault(msg, 15, false),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    isTextPaymentMethod: jspb.Message.getFieldWithDefault(msg, 17, false),
    autoStyleAccountNumber: jspb.Message.getFieldWithDefault(msg, 18, false),
    useVue: jspb.Message.getFieldWithDefault(msg, 19, false),
    allowAch: jspb.Message.getFieldWithDefault(msg, 20, false),
    allowCreditCard: jspb.Message.getFieldWithDefault(msg, 21, false),
    debitOnly: jspb.Message.getFieldWithDefault(msg, 22, false),
    merchantId: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormRequest}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetAddPaymentMethodFormRequest;
  return proto.realm.mintproxy.GetAddPaymentMethodFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormRequest}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOriginalPaymentMethodId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCreditCardLogos(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCheckingHelpers(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeFieldNamesInValidation(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireBillingFields(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBlankState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequiredClass(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorValidationClass(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessValidationClass(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidateOnSubmitOnly(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultExpirationDate(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByStaff(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByGuest(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTextPaymentMethod(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoStyleAccountNumber(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVue(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAch(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCreditCard(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebitOnly(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetAddPaymentMethodFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOriginalPaymentMethodId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIncludeCreditCardLogos();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIncludeCheckingHelpers();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIncludeFieldNamesInValidation();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRequireBillingFields();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIncludeBlankState();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRequiredClass();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getErrorValidationClass();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSuccessValidationClass();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getValidateOnSubmitOnly();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDefaultExpirationDate();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAddedByStaff();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAddedByGuest();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIsTextPaymentMethod();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAutoStyleAccountNumber();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getUseVue();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAllowAch();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getAllowCreditCard();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getDebitOnly();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue customer_id = 2;
 * @return {?proto.realm.mintproxy.StringValue}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getCustomerId = function() {
  return /** @type{?proto.realm.mintproxy.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/** @param {?proto.realm.mintproxy.StringValue|undefined} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setCustomerId = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.clearCustomerId = function() {
  this.setCustomerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue original_payment_method_id = 3;
 * @return {?proto.realm.mintproxy.StringValue}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getOriginalPaymentMethodId = function() {
  return /** @type{?proto.realm.mintproxy.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/** @param {?proto.realm.mintproxy.StringValue|undefined} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setOriginalPaymentMethodId = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.clearOriginalPaymentMethodId = function() {
  this.setOriginalPaymentMethodId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.hasOriginalPaymentMethodId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool include_credit_card_logos = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getIncludeCreditCardLogos = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setIncludeCreditCardLogos = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool include_checking_helpers = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getIncludeCheckingHelpers = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setIncludeCheckingHelpers = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool include_field_names_in_validation = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getIncludeFieldNamesInValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setIncludeFieldNamesInValidation = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool require_billing_fields = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getRequireBillingFields = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setRequireBillingFields = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool include_blank_state = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getIncludeBlankState = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setIncludeBlankState = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string required_class = 9;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getRequiredClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setRequiredClass = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string error_validation_class = 10;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getErrorValidationClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setErrorValidationClass = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string success_validation_class = 11;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getSuccessValidationClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setSuccessValidationClass = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool validate_on_submit_only = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getValidateOnSubmitOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setValidateOnSubmitOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool default_expiration_date = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getDefaultExpirationDate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setDefaultExpirationDate = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool added_by_staff = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getAddedByStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setAddedByStaff = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool added_by_guest = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getAddedByGuest = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setAddedByGuest = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string phone_number = 16;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool is_text_payment_method = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getIsTextPaymentMethod = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setIsTextPaymentMethod = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool auto_style_account_number = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getAutoStyleAccountNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setAutoStyleAccountNumber = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool use_vue = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getUseVue = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setUseVue = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool allow_ach = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getAllowAch = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setAllowAch = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool allow_credit_card = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getAllowCreditCard = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setAllowCreditCard = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool debit_only = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getDebitOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setDebitOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string merchant_id = 23;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormRequest.prototype.setMerchantId = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetAddPaymentMethodFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetAddPaymentMethodFormResponse.displayName = 'proto.realm.mintproxy.GetAddPaymentMethodFormResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetAddPaymentMethodFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && mint_payment_method_pb.GetAddPaymentMethodFormOutput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormResponse}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetAddPaymentMethodFormResponse;
  return proto.realm.mintproxy.GetAddPaymentMethodFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormResponse}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.GetAddPaymentMethodFormOutput;
      reader.readMessage(value,mint_payment_method_pb.GetAddPaymentMethodFormOutput.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetAddPaymentMethodFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.GetAddPaymentMethodFormOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.GetAddPaymentMethodFormOutput data = 1;
 * @return {?proto.realm.mintproxy.GetAddPaymentMethodFormOutput}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.getData = function() {
  return /** @type{?proto.realm.mintproxy.GetAddPaymentMethodFormOutput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.GetAddPaymentMethodFormOutput, 1));
};


/** @param {?proto.realm.mintproxy.GetAddPaymentMethodFormOutput|undefined} value */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.displayName = 'proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest;
  return proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.setSiteId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string merchant_id = 2;
 * @return {string}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesRequest.prototype.setMerchantId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.displayName = 'proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && mint_payment_method_pb.GetAddPaymentMethodFormVariablesOutput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse;
  return proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.GetAddPaymentMethodFormVariablesOutput;
      reader.readMessage(value,mint_payment_method_pb.GetAddPaymentMethodFormVariablesOutput.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.GetAddPaymentMethodFormVariablesOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.GetAddPaymentMethodFormVariablesOutput data = 1;
 * @return {?proto.realm.mintproxy.GetAddPaymentMethodFormVariablesOutput}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.getData = function() {
  return /** @type{?proto.realm.mintproxy.GetAddPaymentMethodFormVariablesOutput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.GetAddPaymentMethodFormVariablesOutput, 1));
};


/** @param {?proto.realm.mintproxy.GetAddPaymentMethodFormVariablesOutput|undefined} value */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetAddPaymentMethodFormVariablesResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.CacheValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.CacheValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.CacheValueRequest.displayName = 'proto.realm.mintproxy.CacheValueRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.CacheValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.CacheValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.CacheValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.CacheValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    args: (f = msg.getArgs()) && mint_payment_method_pb.CacheValueInput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.CacheValueRequest}
 */
proto.realm.mintproxy.CacheValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.CacheValueRequest;
  return proto.realm.mintproxy.CacheValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.CacheValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.CacheValueRequest}
 */
proto.realm.mintproxy.CacheValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.CacheValueInput;
      reader.readMessage(value,mint_payment_method_pb.CacheValueInput.deserializeBinaryFromReader);
      msg.setArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.CacheValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.CacheValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.CacheValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.CacheValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArgs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.CacheValueInput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.CacheValueInput args = 1;
 * @return {?proto.realm.mintproxy.CacheValueInput}
 */
proto.realm.mintproxy.CacheValueRequest.prototype.getArgs = function() {
  return /** @type{?proto.realm.mintproxy.CacheValueInput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.CacheValueInput, 1));
};


/** @param {?proto.realm.mintproxy.CacheValueInput|undefined} value */
proto.realm.mintproxy.CacheValueRequest.prototype.setArgs = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.CacheValueRequest.prototype.clearArgs = function() {
  this.setArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.CacheValueRequest.prototype.hasArgs = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.CacheValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.CacheValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.CacheValueResponse.displayName = 'proto.realm.mintproxy.CacheValueResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.CacheValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.CacheValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.CacheValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.CacheValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && mint_payment_method_pb.CacheValueOutput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.CacheValueResponse}
 */
proto.realm.mintproxy.CacheValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.CacheValueResponse;
  return proto.realm.mintproxy.CacheValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.CacheValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.CacheValueResponse}
 */
proto.realm.mintproxy.CacheValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.CacheValueOutput;
      reader.readMessage(value,mint_payment_method_pb.CacheValueOutput.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.CacheValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.CacheValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.CacheValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.CacheValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.CacheValueOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.CacheValueOutput data = 1;
 * @return {?proto.realm.mintproxy.CacheValueOutput}
 */
proto.realm.mintproxy.CacheValueResponse.prototype.getData = function() {
  return /** @type{?proto.realm.mintproxy.CacheValueOutput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.CacheValueOutput, 1));
};


/** @param {?proto.realm.mintproxy.CacheValueOutput|undefined} value */
proto.realm.mintproxy.CacheValueResponse.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.CacheValueResponse.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.CacheValueResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetBankNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetBankNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetBankNameRequest.displayName = 'proto.realm.mintproxy.GetBankNameRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetBankNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetBankNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetBankNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetBankNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    args: (f = msg.getArgs()) && mint_payment_method_pb.GetBankNameInput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetBankNameRequest}
 */
proto.realm.mintproxy.GetBankNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetBankNameRequest;
  return proto.realm.mintproxy.GetBankNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetBankNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetBankNameRequest}
 */
proto.realm.mintproxy.GetBankNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.GetBankNameInput;
      reader.readMessage(value,mint_payment_method_pb.GetBankNameInput.deserializeBinaryFromReader);
      msg.setArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetBankNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetBankNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetBankNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetBankNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArgs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.GetBankNameInput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.GetBankNameInput args = 1;
 * @return {?proto.realm.mintproxy.GetBankNameInput}
 */
proto.realm.mintproxy.GetBankNameRequest.prototype.getArgs = function() {
  return /** @type{?proto.realm.mintproxy.GetBankNameInput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.GetBankNameInput, 1));
};


/** @param {?proto.realm.mintproxy.GetBankNameInput|undefined} value */
proto.realm.mintproxy.GetBankNameRequest.prototype.setArgs = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.GetBankNameRequest.prototype.clearArgs = function() {
  this.setArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetBankNameRequest.prototype.hasArgs = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.realm.mintproxy.GetBankNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.realm.mintproxy.GetBankNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.realm.mintproxy.GetBankNameResponse.displayName = 'proto.realm.mintproxy.GetBankNameResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.realm.mintproxy.GetBankNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.realm.mintproxy.GetBankNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.realm.mintproxy.GetBankNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetBankNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && mint_payment_method_pb.GetBankNameOutput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.realm.mintproxy.GetBankNameResponse}
 */
proto.realm.mintproxy.GetBankNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.realm.mintproxy.GetBankNameResponse;
  return proto.realm.mintproxy.GetBankNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.realm.mintproxy.GetBankNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.realm.mintproxy.GetBankNameResponse}
 */
proto.realm.mintproxy.GetBankNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mint_payment_method_pb.GetBankNameOutput;
      reader.readMessage(value,mint_payment_method_pb.GetBankNameOutput.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.realm.mintproxy.GetBankNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.realm.mintproxy.GetBankNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.realm.mintproxy.GetBankNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.realm.mintproxy.GetBankNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mint_payment_method_pb.GetBankNameOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional mint.GetBankNameOutput data = 1;
 * @return {?proto.realm.mintproxy.GetBankNameOutput}
 */
proto.realm.mintproxy.GetBankNameResponse.prototype.getData = function() {
  return /** @type{?proto.realm.mintproxy.GetBankNameOutput} */ (
    jspb.Message.getWrapperField(this, mint_payment_method_pb.GetBankNameOutput, 1));
};


/** @param {?proto.realm.mintproxy.GetBankNameOutput|undefined} value */
proto.realm.mintproxy.GetBankNameResponse.prototype.setData = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.realm.mintproxy.GetBankNameResponse.prototype.clearData = function() {
  this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.realm.mintproxy.GetBankNameResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.realm.mintproxy);
