/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.exportSymbol('proto.mint.CacheValueInput', null, global);
goog.exportSymbol('proto.mint.CacheValueOutput', null, global);
goog.exportSymbol('proto.mint.DeactivatePaymentMethodInput', null, global);
goog.exportSymbol('proto.mint.GetAddPaymentMethodFormInput', null, global);
goog.exportSymbol('proto.mint.GetAddPaymentMethodFormOutput', null, global);
goog.exportSymbol('proto.mint.GetAddPaymentMethodFormVariablesInput', null, global);
goog.exportSymbol('proto.mint.GetAddPaymentMethodFormVariablesOutput', null, global);
goog.exportSymbol('proto.mint.GetBankNameInput', null, global);
goog.exportSymbol('proto.mint.GetBankNameOutput', null, global);
goog.exportSymbol('proto.mint.GetPaymentMethodInput', null, global);
goog.exportSymbol('proto.mint.GetPaymentMethodOutput', null, global);
goog.exportSymbol('proto.mint.ListPaymentMethodsInput', null, global);
goog.exportSymbol('proto.mint.ListPaymentMethodsOutput', null, global);
goog.exportSymbol('proto.mint.PaymentMethod', null, global);
goog.exportSymbol('proto.mint.PaymentMethod.CreditCardType', null, global);
goog.exportSymbol('proto.mint.PaymentMethod.PaymentMethodType', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.PaymentMethod.displayName = 'proto.mint.PaymentMethod';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: (f = msg.getCustomerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    merchantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    last4: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creditCardType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    expirationMonth: (f = msg.getExpirationMonth()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    expirationYear: (f = msg.getExpirationYear()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    billingName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    billingCountryCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    billingAddress1: jspb.Message.getFieldWithDefault(msg, 12, ""),
    billingAddress2: jspb.Message.getFieldWithDefault(msg, 13, ""),
    billingCity: jspb.Message.getFieldWithDefault(msg, 14, ""),
    billingRegion: jspb.Message.getFieldWithDefault(msg, 15, ""),
    billingPostalCode: jspb.Message.getFieldWithDefault(msg, 16, ""),
    billingEmail: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isSaved: jspb.Message.getFieldWithDefault(msg, 18, false),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 19, ""),
    addedByStaff: jspb.Message.getFieldWithDefault(msg, 20, false),
    addedByGuest: jspb.Message.getFieldWithDefault(msg, 21, false),
    dateDeactivated: (f = msg.getDateDeactivated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.PaymentMethod}
 */
proto.mint.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.PaymentMethod;
  return proto.mint.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.PaymentMethod}
 */
proto.mint.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 6:
      var value = /** @type {!proto.mint.PaymentMethod.PaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 7:
      var value = /** @type {!proto.mint.PaymentMethod.CreditCardType} */ (reader.readEnum());
      msg.setCreditCardType(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExpirationMonth(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExpirationYear(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCountryCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingAddress1(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingAddress2(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCity(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingRegion(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingPostalCode(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingEmail(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSaved(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByStaff(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByGuest(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateDeactivated(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreditCardType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getExpirationMonth();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExpirationYear();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getBillingName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBillingCountryCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBillingAddress1();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBillingAddress2();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBillingCity();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBillingRegion();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBillingPostalCode();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBillingEmail();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsSaved();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddedByStaff();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getAddedByGuest();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getDateDeactivated();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mint.PaymentMethod.PaymentMethodType = {
  CHECKING: 0,
  SAVINGS: 1,
  CREDIT: 2,
  DEBIT: 3
};

/**
 * @enum {number}
 */
proto.mint.PaymentMethod.CreditCardType = {
  VISA: 0,
  MASTERCARD: 1,
  DISCOVER: 2,
  AMERICANEXPRESS: 3
};

/**
 * optional string payment_method_id = 1;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setPaymentMethodId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue customer_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.mint.PaymentMethod.prototype.getCustomerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/** @param {?proto.google.protobuf.StringValue|undefined} value */
proto.mint.PaymentMethod.prototype.setCustomerId = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.mint.PaymentMethod.prototype.clearCustomerId = function() {
  this.setCustomerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.PaymentMethod.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string merchant_id = 3;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setMerchantId = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last4 = 5;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setLast4 = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PaymentMethodType payment_method_type = 6;
 * @return {!proto.mint.PaymentMethod.PaymentMethodType}
 */
proto.mint.PaymentMethod.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.mint.PaymentMethod.PaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.mint.PaymentMethod.PaymentMethodType} value */
proto.mint.PaymentMethod.prototype.setPaymentMethodType = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional CreditCardType credit_card_type = 7;
 * @return {!proto.mint.PaymentMethod.CreditCardType}
 */
proto.mint.PaymentMethod.prototype.getCreditCardType = function() {
  return /** @type {!proto.mint.PaymentMethod.CreditCardType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.mint.PaymentMethod.CreditCardType} value */
proto.mint.PaymentMethod.prototype.setCreditCardType = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value expiration_month = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.mint.PaymentMethod.prototype.getExpirationMonth = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/** @param {?proto.google.protobuf.Int32Value|undefined} value */
proto.mint.PaymentMethod.prototype.setExpirationMonth = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.mint.PaymentMethod.prototype.clearExpirationMonth = function() {
  this.setExpirationMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.PaymentMethod.prototype.hasExpirationMonth = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value expiration_year = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.mint.PaymentMethod.prototype.getExpirationYear = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/** @param {?proto.google.protobuf.Int32Value|undefined} value */
proto.mint.PaymentMethod.prototype.setExpirationYear = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.mint.PaymentMethod.prototype.clearExpirationYear = function() {
  this.setExpirationYear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.PaymentMethod.prototype.hasExpirationYear = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string billing_name = 10;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingName = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string billing_country_code = 11;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingCountryCode = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string billing_address1 = 12;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingAddress1 = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string billing_address2 = 13;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingAddress2 = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string billing_city = 14;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingCity = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string billing_region = 15;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingRegion = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string billing_postal_code = 16;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingPostalCode = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string billing_email = 17;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getBillingEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setBillingEmail = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_saved = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.PaymentMethod.prototype.getIsSaved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.mint.PaymentMethod.prototype.setIsSaved = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string ip_address = 19;
 * @return {string}
 */
proto.mint.PaymentMethod.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.mint.PaymentMethod.prototype.setIpAddress = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool added_by_staff = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.PaymentMethod.prototype.getAddedByStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.mint.PaymentMethod.prototype.setAddedByStaff = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool added_by_guest = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.PaymentMethod.prototype.getAddedByGuest = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.mint.PaymentMethod.prototype.setAddedByGuest = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp date_deactivated = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mint.PaymentMethod.prototype.getDateDeactivated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.mint.PaymentMethod.prototype.setDateDeactivated = function(value) {
  jspb.Message.setWrapperField(this, 22, value);
};


proto.mint.PaymentMethod.prototype.clearDateDeactivated = function() {
  this.setDateDeactivated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.PaymentMethod.prototype.hasDateDeactivated = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mint.PaymentMethod.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.mint.PaymentMethod.prototype.setExpirationDate = function(value) {
  jspb.Message.setWrapperField(this, 23, value);
};


proto.mint.PaymentMethod.prototype.clearExpirationDate = function() {
  this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.PaymentMethod.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 23) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetPaymentMethodInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetPaymentMethodInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetPaymentMethodInput.displayName = 'proto.mint.GetPaymentMethodInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetPaymentMethodInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetPaymentMethodInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetPaymentMethodInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetPaymentMethodInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetPaymentMethodInput}
 */
proto.mint.GetPaymentMethodInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetPaymentMethodInput;
  return proto.mint.GetPaymentMethodInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetPaymentMethodInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetPaymentMethodInput}
 */
proto.mint.GetPaymentMethodInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetPaymentMethodInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetPaymentMethodInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetPaymentMethodInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetPaymentMethodInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payment_method_id = 1;
 * @return {string}
 */
proto.mint.GetPaymentMethodInput.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetPaymentMethodInput.prototype.setPaymentMethodId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetPaymentMethodOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetPaymentMethodOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetPaymentMethodOutput.displayName = 'proto.mint.GetPaymentMethodOutput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetPaymentMethodOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetPaymentMethodOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetPaymentMethodOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetPaymentMethodOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethod: (f = msg.getPaymentMethod()) && proto.mint.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetPaymentMethodOutput}
 */
proto.mint.GetPaymentMethodOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetPaymentMethodOutput;
  return proto.mint.GetPaymentMethodOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetPaymentMethodOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetPaymentMethodOutput}
 */
proto.mint.GetPaymentMethodOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mint.PaymentMethod;
      reader.readMessage(value,proto.mint.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetPaymentMethodOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetPaymentMethodOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetPaymentMethodOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetPaymentMethodOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mint.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaymentMethod payment_method = 1;
 * @return {?proto.mint.PaymentMethod}
 */
proto.mint.GetPaymentMethodOutput.prototype.getPaymentMethod = function() {
  return /** @type{?proto.mint.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.mint.PaymentMethod, 1));
};


/** @param {?proto.mint.PaymentMethod|undefined} value */
proto.mint.GetPaymentMethodOutput.prototype.setPaymentMethod = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.mint.GetPaymentMethodOutput.prototype.clearPaymentMethod = function() {
  this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.GetPaymentMethodOutput.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.ListPaymentMethodsInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.ListPaymentMethodsInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.ListPaymentMethodsInput.displayName = 'proto.mint.ListPaymentMethodsInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.ListPaymentMethodsInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.ListPaymentMethodsInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.ListPaymentMethodsInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.ListPaymentMethodsInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filterByMerchant: jspb.Message.getFieldWithDefault(msg, 2, false),
    isDebitOnly: jspb.Message.getFieldWithDefault(msg, 3, false),
    includeCredit: jspb.Message.getFieldWithDefault(msg, 4, false),
    includeAch: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.ListPaymentMethodsInput}
 */
proto.mint.ListPaymentMethodsInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.ListPaymentMethodsInput;
  return proto.mint.ListPaymentMethodsInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.ListPaymentMethodsInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.ListPaymentMethodsInput}
 */
proto.mint.ListPaymentMethodsInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilterByMerchant(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDebitOnly(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCredit(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.ListPaymentMethodsInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.ListPaymentMethodsInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.ListPaymentMethodsInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.ListPaymentMethodsInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilterByMerchant();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsDebitOnly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeCredit();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIncludeAch();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.mint.ListPaymentMethodsInput.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.ListPaymentMethodsInput.prototype.setCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool filter_by_merchant = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.ListPaymentMethodsInput.prototype.getFilterByMerchant = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.mint.ListPaymentMethodsInput.prototype.setFilterByMerchant = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_debit_only = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.ListPaymentMethodsInput.prototype.getIsDebitOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.mint.ListPaymentMethodsInput.prototype.setIsDebitOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_credit = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.ListPaymentMethodsInput.prototype.getIncludeCredit = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.mint.ListPaymentMethodsInput.prototype.setIncludeCredit = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool include_ach = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.ListPaymentMethodsInput.prototype.getIncludeAch = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.mint.ListPaymentMethodsInput.prototype.setIncludeAch = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.ListPaymentMethodsOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mint.ListPaymentMethodsOutput.repeatedFields_, null);
};
goog.inherits(proto.mint.ListPaymentMethodsOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.ListPaymentMethodsOutput.displayName = 'proto.mint.ListPaymentMethodsOutput';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mint.ListPaymentMethodsOutput.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.ListPaymentMethodsOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.ListPaymentMethodsOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.ListPaymentMethodsOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.ListPaymentMethodsOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethodsList: jspb.Message.toObjectList(msg.getPaymentMethodsList(),
    proto.mint.PaymentMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.ListPaymentMethodsOutput}
 */
proto.mint.ListPaymentMethodsOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.ListPaymentMethodsOutput;
  return proto.mint.ListPaymentMethodsOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.ListPaymentMethodsOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.ListPaymentMethodsOutput}
 */
proto.mint.ListPaymentMethodsOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mint.PaymentMethod;
      reader.readMessage(value,proto.mint.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymentMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.ListPaymentMethodsOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.ListPaymentMethodsOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.ListPaymentMethodsOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.ListPaymentMethodsOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mint.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PaymentMethod payment_methods = 1;
 * @return {!Array<!proto.mint.PaymentMethod>}
 */
proto.mint.ListPaymentMethodsOutput.prototype.getPaymentMethodsList = function() {
  return /** @type{!Array<!proto.mint.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mint.PaymentMethod, 1));
};


/** @param {!Array<!proto.mint.PaymentMethod>} value */
proto.mint.ListPaymentMethodsOutput.prototype.setPaymentMethodsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mint.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mint.PaymentMethod}
 */
proto.mint.ListPaymentMethodsOutput.prototype.addPaymentMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mint.PaymentMethod, opt_index);
};


proto.mint.ListPaymentMethodsOutput.prototype.clearPaymentMethodsList = function() {
  this.setPaymentMethodsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetAddPaymentMethodFormInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetAddPaymentMethodFormInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetAddPaymentMethodFormInput.displayName = 'proto.mint.GetAddPaymentMethodFormInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetAddPaymentMethodFormInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetAddPaymentMethodFormInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: (f = msg.getCustomerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    originalPaymentMethodId: (f = msg.getOriginalPaymentMethodId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDebitOnly: jspb.Message.getFieldWithDefault(msg, 4, false),
    allowCreditCard: jspb.Message.getFieldWithDefault(msg, 5, false),
    allowAch: jspb.Message.getFieldWithDefault(msg, 6, false),
    includeCreditCardLogos: jspb.Message.getFieldWithDefault(msg, 7, false),
    includeCheckingHelpers: jspb.Message.getFieldWithDefault(msg, 8, false),
    includeFieldNamesInValidation: jspb.Message.getFieldWithDefault(msg, 9, false),
    requireBillingFields: jspb.Message.getFieldWithDefault(msg, 10, false),
    includeBlankState: jspb.Message.getFieldWithDefault(msg, 11, false),
    requiredClass: jspb.Message.getFieldWithDefault(msg, 12, ""),
    errorValidationClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    successValidationClass: jspb.Message.getFieldWithDefault(msg, 14, ""),
    validateOnSubmitOnly: jspb.Message.getFieldWithDefault(msg, 15, false),
    defaultExpirationDate: jspb.Message.getFieldWithDefault(msg, 16, false),
    addedByStaff: jspb.Message.getFieldWithDefault(msg, 17, false),
    addedByGuest: jspb.Message.getFieldWithDefault(msg, 18, false),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    isTextPaymentMethod: jspb.Message.getFieldWithDefault(msg, 20, false),
    autoStyleAccountNumber: jspb.Message.getFieldWithDefault(msg, 21, false),
    useVue: jspb.Message.getFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetAddPaymentMethodFormInput}
 */
proto.mint.GetAddPaymentMethodFormInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetAddPaymentMethodFormInput;
  return proto.mint.GetAddPaymentMethodFormInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetAddPaymentMethodFormInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetAddPaymentMethodFormInput}
 */
proto.mint.GetAddPaymentMethodFormInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOriginalPaymentMethodId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDebitOnly(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCreditCard(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAch(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCreditCardLogos(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCheckingHelpers(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeFieldNamesInValidation(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireBillingFields(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBlankState(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequiredClass(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorValidationClass(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessValidationClass(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidateOnSubmitOnly(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultExpirationDate(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByStaff(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddedByGuest(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTextPaymentMethod(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoStyleAccountNumber(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetAddPaymentMethodFormInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetAddPaymentMethodFormInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOriginalPaymentMethodId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDebitOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAllowCreditCard();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAllowAch();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIncludeCreditCardLogos();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIncludeCheckingHelpers();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIncludeFieldNamesInValidation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getRequireBillingFields();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIncludeBlankState();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getRequiredClass();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getErrorValidationClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSuccessValidationClass();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getValidateOnSubmitOnly();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDefaultExpirationDate();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getAddedByStaff();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAddedByGuest();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getIsTextPaymentMethod();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getAutoStyleAccountNumber();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUseVue();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional string merchant_id = 1;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setMerchantId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue customer_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getCustomerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/** @param {?proto.google.protobuf.StringValue|undefined} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setCustomerId = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.mint.GetAddPaymentMethodFormInput.prototype.clearCustomerId = function() {
  this.setCustomerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue original_payment_method_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getOriginalPaymentMethodId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/** @param {?proto.google.protobuf.StringValue|undefined} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setOriginalPaymentMethodId = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.mint.GetAddPaymentMethodFormInput.prototype.clearOriginalPaymentMethodId = function() {
  this.setOriginalPaymentMethodId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.hasOriginalPaymentMethodId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_debit_only = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIsDebitOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIsDebitOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool allow_credit_card = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getAllowCreditCard = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setAllowCreditCard = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool allow_ach = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getAllowAch = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setAllowAch = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool include_credit_card_logos = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIncludeCreditCardLogos = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIncludeCreditCardLogos = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool include_checking_helpers = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIncludeCheckingHelpers = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIncludeCheckingHelpers = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool include_field_names_in_validation = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIncludeFieldNamesInValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIncludeFieldNamesInValidation = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool require_billing_fields = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getRequireBillingFields = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setRequireBillingFields = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool include_blank_state = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIncludeBlankState = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIncludeBlankState = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string required_class = 12;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getRequiredClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setRequiredClass = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string error_validation_class = 13;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getErrorValidationClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setErrorValidationClass = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string success_validation_class = 14;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getSuccessValidationClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setSuccessValidationClass = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool validate_on_submit_only = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getValidateOnSubmitOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setValidateOnSubmitOnly = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool default_expiration_date = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getDefaultExpirationDate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setDefaultExpirationDate = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool added_by_staff = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getAddedByStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setAddedByStaff = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool added_by_guest = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getAddedByGuest = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setAddedByGuest = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string phone_number = 19;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool is_text_payment_method = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getIsTextPaymentMethod = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setIsTextPaymentMethod = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool auto_style_account_number = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getAutoStyleAccountNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setAutoStyleAccountNumber = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool use_vue = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.GetAddPaymentMethodFormInput.prototype.getUseVue = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.mint.GetAddPaymentMethodFormInput.prototype.setUseVue = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetAddPaymentMethodFormOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetAddPaymentMethodFormOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetAddPaymentMethodFormOutput.displayName = 'proto.mint.GetAddPaymentMethodFormOutput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetAddPaymentMethodFormOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetAddPaymentMethodFormOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetAddPaymentMethodFormOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    html: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetAddPaymentMethodFormOutput}
 */
proto.mint.GetAddPaymentMethodFormOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetAddPaymentMethodFormOutput;
  return proto.mint.GetAddPaymentMethodFormOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetAddPaymentMethodFormOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetAddPaymentMethodFormOutput}
 */
proto.mint.GetAddPaymentMethodFormOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetAddPaymentMethodFormOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetAddPaymentMethodFormOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetAddPaymentMethodFormOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHtml();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string html = 1;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormOutput.prototype.getHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormOutput.prototype.setHtml = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetAddPaymentMethodFormVariablesInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetAddPaymentMethodFormVariablesInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetAddPaymentMethodFormVariablesInput.displayName = 'proto.mint.GetAddPaymentMethodFormVariablesInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetAddPaymentMethodFormVariablesInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    merchantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetAddPaymentMethodFormVariablesInput}
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetAddPaymentMethodFormVariablesInput;
  return proto.mint.GetAddPaymentMethodFormVariablesInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetAddPaymentMethodFormVariablesInput}
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetAddPaymentMethodFormVariablesInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerchantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string merchant_id = 1;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormVariablesInput.prototype.getMerchantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormVariablesInput.prototype.setMerchantId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetAddPaymentMethodFormVariablesOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetAddPaymentMethodFormVariablesOutput.displayName = 'proto.mint.GetAddPaymentMethodFormVariablesOutput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetAddPaymentMethodFormVariablesOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    nvpvar: jspb.Message.getFieldWithDefault(msg, 1, ""),
    acsRequestId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetAddPaymentMethodFormVariablesOutput}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetAddPaymentMethodFormVariablesOutput;
  return proto.mint.GetAddPaymentMethodFormVariablesOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetAddPaymentMethodFormVariablesOutput}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNvpvar(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcsRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetAddPaymentMethodFormVariablesOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetAddPaymentMethodFormVariablesOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNvpvar();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAcsRequestId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string nvpvar = 1;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.getNvpvar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.setNvpvar = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string acs_request_id = 2;
 * @return {string}
 */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.getAcsRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.mint.GetAddPaymentMethodFormVariablesOutput.prototype.setAcsRequestId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.CacheValueInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.CacheValueInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.CacheValueInput.displayName = 'proto.mint.CacheValueInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.CacheValueInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.CacheValueInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.CacheValueInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.CacheValueInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.CacheValueInput}
 */
proto.mint.CacheValueInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.CacheValueInput;
  return proto.mint.CacheValueInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.CacheValueInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.CacheValueInput}
 */
proto.mint.CacheValueInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.CacheValueInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.CacheValueInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.CacheValueInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.CacheValueInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mint.CacheValueInput.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.CacheValueInput.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.mint.CacheValueInput.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.mint.CacheValueInput.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.CacheValueOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.CacheValueOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.CacheValueOutput.displayName = 'proto.mint.CacheValueOutput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.CacheValueOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.CacheValueOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.CacheValueOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.CacheValueOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.CacheValueOutput}
 */
proto.mint.CacheValueOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.CacheValueOutput;
  return proto.mint.CacheValueOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.CacheValueOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.CacheValueOutput}
 */
proto.mint.CacheValueOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.CacheValueOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.CacheValueOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.CacheValueOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.CacheValueOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.mint.CacheValueOutput.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.mint.CacheValueOutput.prototype.setSuccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetBankNameInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetBankNameInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetBankNameInput.displayName = 'proto.mint.GetBankNameInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetBankNameInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetBankNameInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetBankNameInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetBankNameInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetBankNameInput}
 */
proto.mint.GetBankNameInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetBankNameInput;
  return proto.mint.GetBankNameInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetBankNameInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetBankNameInput}
 */
proto.mint.GetBankNameInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetBankNameInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetBankNameInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetBankNameInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetBankNameInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mint.GetBankNameInput.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetBankNameInput.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.GetBankNameOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.GetBankNameOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.GetBankNameOutput.displayName = 'proto.mint.GetBankNameOutput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.GetBankNameOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.GetBankNameOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.GetBankNameOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetBankNameOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.GetBankNameOutput}
 */
proto.mint.GetBankNameOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.GetBankNameOutput;
  return proto.mint.GetBankNameOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.GetBankNameOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.GetBankNameOutput}
 */
proto.mint.GetBankNameOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.GetBankNameOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.GetBankNameOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.GetBankNameOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.GetBankNameOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.mint.GetBankNameOutput.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.GetBankNameOutput.prototype.setBankName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mint.DeactivatePaymentMethodInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mint.DeactivatePaymentMethodInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.mint.DeactivatePaymentMethodInput.displayName = 'proto.mint.DeactivatePaymentMethodInput';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mint.DeactivatePaymentMethodInput.prototype.toObject = function(opt_includeInstance) {
  return proto.mint.DeactivatePaymentMethodInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mint.DeactivatePaymentMethodInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.DeactivatePaymentMethodInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mint.DeactivatePaymentMethodInput}
 */
proto.mint.DeactivatePaymentMethodInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mint.DeactivatePaymentMethodInput;
  return proto.mint.DeactivatePaymentMethodInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mint.DeactivatePaymentMethodInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mint.DeactivatePaymentMethodInput}
 */
proto.mint.DeactivatePaymentMethodInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mint.DeactivatePaymentMethodInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mint.DeactivatePaymentMethodInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mint.DeactivatePaymentMethodInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mint.DeactivatePaymentMethodInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payment_method_id = 1;
 * @return {string}
 */
proto.mint.DeactivatePaymentMethodInput.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.mint.DeactivatePaymentMethodInput.prototype.setPaymentMethodId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.mint);
