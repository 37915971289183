import mintProxyMesaService from '../services/mintproxy.mesa';
var mesa = require('../mesaClient.js').default;
mintProxyMesaService.init(mesa.do, _REALM.CONFIG.MesaHost);

export function initState() {
	return {
		signUpUrl: null,
		merchant: {},
		merchants: [],
		depositAccounts: [],
		addPaymentMethodFormHtml: null,
		recurrences: []
	};
}

export default {
	state: initState(),
	getMerchant() {
		//return mintProxyService.getMerchant({ args: { merchantId }, siteId: _REALM.STORE.siteStore.state.site.siteId }).then(function(response) {
		//	response.data.merchant._status = merchantAccountStatusHelper(response.data.merchant);
		//	_REALM.STORE.transactionStore.state.merchant = response.data.merchant;
		//	return _REALM.STORE.transactionStore.state.merchant;
		//});
	},
	getSiteMerchant() {
		//if (_REALM.STORE.givingStore.state.settings.merchantId) {
		//	return _REALM.STORE.transactionStore.getMerchant(_REALM.STORE.givingStore.state.settings.merchantId);
		//} else if (_REALM.STORE.siteStore.state.site.siteNumber) {
		//	return _REALM.STORE.transactionStore.listMerchants(_REALM.STORE.siteStore.state.site.siteNumber).then(function(merchants) {
		//		if (merchants && merchants.length == 0) {
		//			_REALM.STORE.transactionStore.getMerchantSetupURL(_REALM.STORE.siteStore.state.site);
		//			return null;
		//		} else {
		//			_REALM.STORE.transactionStore.state.merchant = merchants[0];
		//			_REALM.STORE.givingStore.state.settings.merchantId = _REALM.STORE.transactionStore.state.merchant.merchantId;
		//			_REALM.STORE.siteStore.updateSiteSettings(_REALM.STORE.givingStore.state.settings);
		//			return _REALM.STORE.transactionStore.state.merchant;
		//		}
		//	});
		//}
		//return new Promise(function(resolve) {
		//	resolve();
		//});
	},
	listDepositAccounts: function() {
		//var merchantId = _REALM.STORE.transactionStore.state.merchant.merchantId ? _REALM.STORE.transactionStore.state.merchant.merchantId : _REALM.STORE.givingStore.state.settings.merchantId;
		//return mintProxyService.listDepositAccounts({ args: { merchantId }, siteId: _REALM.STORE.siteStore.state.site.siteId }).then(function(response) {
		//	_REALM.STORE.transactionStore.state.depositAccounts = response.data.depositAccountListList;
		//	return _REALM.STORE.transactionStore.state.depositAccounts;
		//});
	},
	getAddPaymentMethodForm: function(editPaymentMethodId = null) {
		var args = {
			debitOnly: !_REALM.STORE.givingStore.state.settings.allowCreditCard && _REALM.STORE.givingStore.state.settings.allowDebitCard,
			customerId: _REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId,
			useVue: true,
			allowAch: _REALM.STORE.givingStore.state.settings.allowAch,
			allowCreditCard: _REALM.STORE.givingStore.state.settings.allowCreditCard || _REALM.STORE.givingStore.state.settings.allowDebitCard, //allowCreditCard needs to be true for Debit only
			merchantId: _REALM.STORE.givingStore.state.settings.merchantId
		};

		if (editPaymentMethodId) {
			args.originalPaymentMethodId = editPaymentMethodId;
		}

		var addPaymentMethodFormCacheKey = `getAddPaymentMethodForm.${args.siteId}.customerId.${args.customerId}`;

		//Cache html to preload form to when adding a payment method.
		return _REALM.STORE.CACHE.verify(addPaymentMethodFormCacheKey).catch(function() {
			return mintProxyMesaService.getAddPaymentMethodForm(args, { handleError: false }).then(function(resp) {
				_REALM.STORE.transactionStore.state.addPaymentMethodFormHtml = _REALM.STORE.CACHE.set(addPaymentMethodFormCacheKey, resp.data.html);
				return _REALM.STORE.transactionStore.state.addPaymentMethodFormHtml;
			});
		});
	},
	getAddPaymentMethodFormVariables: function() {
		return mintProxyMesaService
			.getAddPaymentMethodFormVariables({
				merchantId: _REALM.STORE.givingStore.state.settings.merchantId
			})
			.then(function(resp) {
				return resp.data;
			});
	},
	cacheValue: function(id, value) {
		return mintProxyMesaService
			.cacheValue({
				args: {
					id,
					value
				}
			})
			.then(function(resp) {
				return resp.data;
			});
	},
	getBankName: function(routingNumber) {
		return mintProxyMesaService
			.getBankName({
				args: {
					id: routingNumber
				}
			})
			.then(function(resp) {
				return resp.data.bankName;
			});
	},
	enums: [] //mintProxyService.enums
};
