import memorialService from '../services/memorialService';

export function initState() {
	return {
		activeMemorials: [],
		inactiveMemorials: [],
		sortableMemorials: [],
		selectableGivingFormMemorials: [],
		editingSortableMemorials: false
	};
}

export default {
	state: initState(),
	siteHasMemorials() {
		return _REALM.STORE.siteStore.hasExperiment('MEMORIALS') && _REALM.STORE.memorialStore.state.activeMemorials.length > 0;
	},
	listActive: async function() {
		_REALM.STORE.memorialStore.state.activeMemorials = await memorialService.listMemorials(true);
		return _REALM.STORE.memorialStore.state.activeMemorials;
	},
	listInactive: async function() {
		_REALM.STORE.memorialStore.state.inactiveMemorials = await memorialService.listMemorials(false);
		return _REALM.STORE.memorialStore.state.inactiveMemorials;
	},
	buildSortableMemorials() {
		const memorialState = _REALM.STORE.memorialStore.state;
		const formMemorials = _REALM.STORE.givingFormStore.state.givingForm.memorials ?? [];
		const unsortedMemorials = memorialState.activeMemorials.map(m => {
			const formHasMemorial = formMemorials.some(memorialId => memorialId === m.memorialId);
			const sortBy = formHasMemorial ? formMemorials.indexOf(m.memorialId) : 9999;
			return { name: m.name, memorialId: m.memorialId, _isChecked: formHasMemorial, _sortBy: sortBy };
		});

		memorialState.sortableMemorials = unsortedMemorials.sort((a, b) => a._sortBy - b._sortBy);
	},
	getCheckedMemorialIds() {
		return _REALM.STORE.memorialStore.state.sortableMemorials.filter(m => m._isChecked).map(m => m.memorialId);
	},
	buildSelectableGivingFormMemorials(recurrenceMemorialId = null) {
		const selectableMemorials = [];
		const givingFormMemorials = _REALM.STORE.givingFormStore.state.givingForm.memorials;
		const { activeMemorials, inactiveMemorials } = _REALM.STORE.memorialStore.state;

		if (_REALM.STORE.givingFormStore.isAdminManaging() && !_REALM.STORE.givingFormStore.isPreviewGivingFormMode()) {
			for (const activeMemorial of activeMemorials) {
				selectableMemorials.push({ text: activeMemorial.displayName || activeMemorial.name, value: activeMemorial.memorialId });
			}
		} else if (givingFormMemorials) {
			for (const memorialId of givingFormMemorials) {
				const memorial = activeMemorials.find(m => m.memorialId === memorialId);
				if (memorial) {
					selectableMemorials.push({ text: memorial.displayName || memorial.name, value: memorial.memorialId });
				}
			}
		}

		if (recurrenceMemorialId && !selectableMemorials.some(m => m.value === recurrenceMemorialId)) {
			_REALM.STORE.transactionStore.state.transaction.memorialId = null;
		}

		_REALM.STORE.memorialStore.state.selectableGivingFormMemorials = selectableMemorials;
	}
};
