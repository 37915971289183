<style lang="scss">
@import '@/scss/main.scss';
</style>

<template>
	<router-view></router-view>
</template>

<script>
export default {};
</script>
