<template>
	<h4 class="mb-3">
		<slot></slot>
	</h4>
</template>

<script>
export default {
	data() {
		return {
			layoutState: _REALM.STORE.layoutStore.state
		};
	}
};
</script>
