export default function(routes, isAdminRoute) {
	for (var i = 0; i < routes.length; i++) {
		if (!routes[i].meta) {
			routes[i].meta = {};
		}

		if (!routes[i].meta.hasOwnProperty('requiresAuth')) {
			routes[i].meta.requiresAuth = true;
		}

		routes[i].meta.isAdminRoute = () => isAdminRoute;
	}

	return routes;
}
