import _find from 'lodash/find';

export default function(fundId) {
	if (_REALM.STORE.fundStore.state.funds.length === 0) {
		console.error('No funds loaded or exist');
	}

	var fund = _find(_REALM.STORE.fundStore.state.funds, { id: fundId });
	return fund ? fund.name : '';
}
