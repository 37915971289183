var isUndefined = require('lodash/isUndefined'),
	_checkLocalStorage = true,
	_localStorageEnabled = false;
var localStorageCheck = function() {
	if (_checkLocalStorage) {
		_checkLocalStorage = false;
		var test = 'localStorageTest';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			_localStorageEnabled = true;
		} catch (e) {
			console.log(`Browser's localStorage is disabled`);
		}
	}

	return _localStorageEnabled;
};

var buildSiteKey = function(key) {
	if (!isUndefined(_REALM.STORE.siteStore.state.site.siteId)) {
		return _REALM.STORE.siteStore.state.site.siteId + '.' + key.toString();
	}
	return key.toString();
};

var _set = function(key, value, ttlMilliseconds) {
	var data = { value: value };
	if (typeof ttlMilliseconds === 'number') {
		data._expiresAt = new Date().getTime() + ttlMilliseconds / 1;
	}
	if (localStorageCheck()) {
		localStorage.setItem(key, JSON.stringify(data));
	}
};

export function set(key, value, ttlMilliseconds) {
	_set(buildSiteKey(key), value, ttlMilliseconds);
}

export function setGlobal(key, value, ttlMilliseconds) {
	_set(key, value, ttlMilliseconds);
}

var _get = function(key) {
	if (localStorageCheck()) {
		var data = JSON.parse(localStorage.getItem(key));
		if (data !== null) {
			if (data._expiresAt && data._expiresAt < new Date().getTime()) {
				_remove(key);
				return null;
			}

			return data.value;
		}

		return null;
	}

	return null;
};

export function get(key) {
	return _get(buildSiteKey(key));
}

export function getGlobal(key) {
	return _get(key);
}

var _remove = function(key) {
	if (localStorageCheck()) {
		localStorage.removeItem(key);
	}
};

export function remove(key) {
	_remove(buildSiteKey(key));
}

export function removeGlobal(key) {
	_remove(key);
}

export function getPromise(key) {
	return new Promise(function(resolve, reject) {
		var cachedValue = get(key);
		if (cachedValue) {
			resolve(cachedValue);
		} else {
			reject();
		}
	});
}
