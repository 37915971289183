<template>
	<a :href="computedHref" :target="target">
		<slot></slot>
	</a>
</template>

<script>
export default {
	props: {
		route: {
			type: String,
			required: true
		},
		target: {
			type: String,
			default: '_parent'
		}
	},
	computed: {
		computedHref() {
			return _REALM.TOOLS.buildSiteLink(this.route);
		},
		inIframe() {
			return _REALM.TOOLS.inIframe();
		}
	}
};
</script>
