import realmClient from '../realmClient';

export default {
	savePledge: function(args) {
		return realmClient.post('Pledge/SavePledges', args);
	},
	addPledge: function(args) {
		if (args.Amount) {
			args.Amount = args.Amount.replace(',', '');
		}

		return realmClient.post('Pledge/AddPledge', args);
	},
	getRecurrenceCount: function(args) {
		return realmClient.post('Pledge/GetRecurrenceCount', args);
	}
};
