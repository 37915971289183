import _words from 'lodash/words';

const digitWords = {
	zero: 0,
	one: 1,
	two: 2,
	three: 3,
	four: 4,
	five: 5,
	six: 6,
	seven: 7,
	eight: 8,
	nine: 9
};

export default function(value) {
	const words = _words(value);
	let newString = value;

	for (const word of words) {
		const lowerCased = word.toLowerCase();
		if (digitWords.hasOwnProperty(lowerCased)) {
			const wordDetails = _words(newString, word);
			newString = newString.slice(0, wordDetails.index) + digitWords[lowerCased] + newString.slice(wordDetails.index + word.length);
		}
	}

	return newString;
}
