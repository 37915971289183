import Vue from 'vue';
import * as Sentry from '@sentry/browser';

var sentryEnvironment = 'Testing';
if (_REALM.CONFIG.StratusHost.includes('localhost')) {
	sentryEnvironment = 'Local';
} else if (_REALM.CONFIG.StratusHost.includes('onrealm.org')) {
	sentryEnvironment = 'Production';
}

if (sentryEnvironment !== 'Local') {
	Sentry.init({
		Vue,
		dsn: 'https://9f93e0ad67324012bfc491fe5c5f38ce@sentry.io/1794053',
		integrations: [
			new Sentry.Integrations.GlobalHandlers({
				onunhandledrejection: false
			})
		],
		environment: sentryEnvironment,
		denyUrls: [/https:\/\/www\.gstatic\.com\/recaptcha\/releases/i]
	});
}
export default Sentry;
