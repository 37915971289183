export default function(cardinal) {
	const mod10 = cardinal % 10,
		mod100 = cardinal % 100;

	if (mod10 === 1 && mod100 !== 11) {
		return `${cardinal}st`;
	}

	if (mod10 === 2 && mod100 !== 12) {
		return `${cardinal}nd`;
	}

	if (mod10 === 3 && mod100 !== 13) {
		return `${cardinal}rd`;
	}

	return `${cardinal}th`;
}
