import _capitalizeFirstLetter from '@/filters/CapitalizeFirstLetter';

export default function(integrationType) {
	if (integrationType.value == _REALM.STORE.transactionStore.enums.IntegrationType.EMBED) {
		if (_REALM.STORE.accountStore.state.authAccount.permissions.user.isSTAFF || _REALM.STORE.accountStore.state.authAccount.permissions.canELEVATE) {
			return 'Embed';
		}

		return 'Website';
	}

	if (integrationType.value == _REALM.STORE.transactionStore.enums.IntegrationType.PLUGIN) {
		return 'Mobile';
	}

	return _capitalizeFirstLetter(integrationType.text);
}
