import realmClient from '../realmClient';
import moment from 'moment';
export default {
	listPledgeForms(individualId) {
		return realmClient.get('PledgeForm/List', { individualId }).then(function(pledgeForms) {
			for (var i = 0; i < pledgeForms.length; i++) {
				pledgeForms[i].campaignStartDate = moment(pledgeForms[i].campaignStartDate);
				pledgeForms[i].campaignStopDate = moment(pledgeForms[i].campaignStopDate);
			}

			return pledgeForms;
		});
	},
	staffListPledgeForms() {
		return realmClient.get('PledgeForm/StaffList').then(function(pledgeForms) {
			for (var i = 0; i < pledgeForms.length; i++) {
				pledgeForms[i].campaignStartDate = moment(pledgeForms[i].campaignStartDate);
				pledgeForms[i].campaignStopDate = moment(pledgeForms[i].campaignStopDate);
			}

			return pledgeForms;
		});
	},
	getPledgeForm(campaignId) {
		return realmClient.get('PledgeForm/Get', { campaignId }).then(function(pledgeForm) {
			pledgeForm.campaignStartDate = moment(pledgeForm.campaignStartDate);
			pledgeForm.campaignStopDate = moment(pledgeForm.campaignStopDate);
			return pledgeForm;
		});
	},
	getPledgeFormByUrl(pledgeFormUrl) {
		return realmClient.get('PledgeForm/GetByUrl', { pledgeFormUrl }, { handleError: false }).then(function(pledgeForm) {
			pledgeForm.campaignStartDate = moment(pledgeForm.campaignStartDate);
			pledgeForm.campaignStopDate = moment(pledgeForm.campaignStopDate);
			return pledgeForm;
		});
	},
	addPledgeForm(pledgeForm) {
		return realmClient.post('PledgeForm/Add', pledgeForm);
	},
	updatePledgeForm(pledgeForm) {
		return realmClient.post('PledgeForm/Update', pledgeForm);
	}
};
