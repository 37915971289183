var _firstLoad = true;
import realmClient from '@/store/realmClient';

export function showLoader(to, from, next) {
	if (_firstLoad) {
		_REALM.LOADER.show();
		_firstLoad = false;
	}

	next();
}

export function setSiteUrl(to, from, next) {
	_REALM.CONFIG.siteUrl = to.params.siteUrl;
	next();
}

export function refreshToken(to, from, next) {
	const cacheToken = 'guard.refresh.token';
	return _REALM.STORE.CACHE.verify(cacheToken)
		.then(next)
		.catch(function() {
			_REALM.STORE.CACHE.set(cacheToken, true);
			return realmClient.refreshToken().then(next);
		});
}

export function analyticsIdentify(to, from, next) {
	if (_REALM.CONFIG.AnalyticsEnabled) {
		_REALM.PENDO.init();
	}

	next();
}

export function loadSite(to, from, next) {
	var hasSiteUrl = function() {
		_REALM.STORE.siteStore.getSite().then(setAppReady);
	};

	var setAppReady = function() {
		_REALM.SENTRY.configureScope(scope => {
			scope.setExtra('Site', _REALM.STORE.siteStore.state.site);
		});
		next();
	};

	if (to.name === 'go') {
		setAppReady();
	} else {
		hasSiteUrl();
	}
}

export function isLoggedIn(to, from, next) {
	// Don't use auth on payment forms for the time being
	if (to.name === 'donor-payment-form') {
		_REALM.STORE.accountStore.clearLogin();
		return next();
	}

	_REALM.STORE.accountStore
		.getAuthAccount()
		.then(function() {
			if (to.meta.requiresAuth && !_REALM.STORE.accountStore.isLoggedIn()) {
				const returnUrl = _REALM.CONFIG.StratusHost + to.fullPath;
				return next({ name: 'login', params: to.params, query: { returnUrl }, replace: true });
			}

			return next();
		})
		.catch(function() {
			return next();
		});
}

export function beforeRoute(to, from, next) {
	if (to.meta.beforeRoute) {
		return to.meta.beforeRoute(to, from, next);
	}

	//Need to scroll to top of page for iframes on page navigation
	_REALM.TOOLS.scrollTopIframe();

	return next();
}

export function setHtmlMeta(to, from, next) {
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) {
		return next();
	}

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map(tagDef => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach(tag => document.head.appendChild(tag));

	next();
}
