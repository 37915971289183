<style lang="scss">
.chip-wrapper {
	text-transform: uppercase;
}
</style>
<template>
	<v-chip :small="small" :color="computedColor" class="chip-wrapper white--text caption"><slot></slot></v-chip>
</template>

<script>
export default {
	props: ['small', 'color'],
	computed: {
		computedColor() {
			return this.color || 'primary';
		}
	}
};
</script>
