export default [
	{
		name: 'login',
		path: 'login',
		component: () => import('@/router/pages/account/LoginPage.vue'),
		props: route => ({ returnUrl: route.query.returnUrl }),
		meta: {
			title: 'Login',
			requiresAuth: false
		}
	}
];
