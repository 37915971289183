<template>
	<div>
		<div v-if="value" class="text-no-wrap">{{ value.format('MM/DD/YYYY') }}</div>
		<div v-if="value" class="text-no-wrap">{{ value.format('LT') }}</div>
		<div v-if="!value">--</div>
	</div>
</template>

<script>
export default {
	props: ['value']
};
</script>
