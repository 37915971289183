import realmClient from '../realmClient';
import axios from 'axios';

export default {
	getMediaServiceToken() {
		return realmClient.post('Media/GetMediaServiceToken');
	},
	uploadImage(imageId, mediaImageType, imageBlob) {
		return _REALM.STORE.mediaStore.getMediaServiceToken().then(function(quickToken) {
			var formData = new FormData();
			formData.append('file', imageBlob, imageId + '.' + _REALM.STORE.mediaStore.getImageExtension(mediaImageType));
			formData.append('siteid', _REALM.TOOLS.noDashGuid(_REALM.STORE.siteStore.state.site.siteId));
			formData.append('imgtype', mediaImageType);
			formData.append('guid', _REALM.TOOLS.noDashGuid(imageId));
			formData.append('token', quickToken);

			var config = {
				headers: {
					'Content-Type': undefined // axios will turn it into the appropriate setting
				}
			};

			return axios.post(_REALM.CONFIG.MediaHost + '/imageupload', formData, config);
		});
	},
	deleteImage(fileName, options, hash, imageType) {
		return _REALM.STORE.mediaStore.getMediaServiceToken().then(function(quickToken) {
			//[EP]/wipeimg/{QT}/{HA}/{SI}/{IT}/{OP}/{ID}.(ext)
			var url = _REALM.CONFIG.MediaHost + '/wipeimg';
			url += '/' + quickToken;
			url += '/' + hash;
			url += '/' + _REALM.TOOLS.noDashGuid(_REALM.STORE.siteStore.state.site.siteId);
			url += '/' + imageType;
			url += '/' + options;
			url += '/' + fileName;

			return axios.post(url);
		});
	}
};
