import realmClient from '../realmClient';
import moment from 'moment';

export default {
	listAvailableCampaigns(args) {
		return realmClient.get('Campaign/ListAvailableCampaigns', args).then(function(campaigns) {
			for (var i = 0; i < campaigns.length; i++) {
				campaigns[i].startDate = moment(campaigns[i].startDate);
				campaigns[i].stopDate = moment(campaigns[i].stopDate);
			}

			return campaigns;
		});
	},
	listActiveCampaigns() {
		return realmClient.get('Campaign/ListActiveCampaigns').then(function(campaigns) {
			for (var i = 0; i < campaigns.length; i++) {
				campaigns[i].startDate = moment(campaigns[i].startDate);
				campaigns[i].stopDate = moment(campaigns[i].stopDate);
			}

			return campaigns;
		});
	},
	getCampaign(campaignId) {
		var individualId = null;
		if (_REALM.STORE.accountStore.isLoggedIn()) {
			individualId = _REALM.STORE.accountStore.state.authAccount.individualId;
		}

		return realmClient.get('Campaign/Get', { campaignId, individualId });
	}
};
