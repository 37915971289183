<style lang="scss">
.eg-text-input {
	&.full-prefix {
		.v-text-field__prefix {
			width: auto;
			padding-right: 0;
			white-space: nowrap;
		}
	}

	&.text-right input {
		text-align: right;
	}

	&.input-size-large {
		font-size: 2rem;
		input {
			max-height: 3.5rem;
			padding: 0 !important; // Fixes text clipping bug on Safari - see https://github.com/vuetifyjs/vuetify/issues/12671
			font-weight: bold;
		}
		.v-text-field__prefix {
			align-self: flex-start;
			padding-top: 0.9rem;
			font-size: 1.2rem;
			font-weight: bold;
		}
	}

	&.input-size-x-large {
		font-size: 3rem;
		font-weight: bold;
		input {
			max-height: 3.5rem;
			padding: 0 !important; // Fixes text clipping bug on Safari - see https://github.com/vuetifyjs/vuetify/issues/12671
		}
		label {
			font-size: 1.2rem;
		}
		.v-text-field__prefix {
			align-self: flex-start;
			padding-top: 0.75rem;
			font-size: 1.5rem;
		}
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	// Remove autofill yellow color
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: all 5000s ease-in-out 0s !important;
	}
}
</style>

<template>
	<v-text-field
		ref="egTextInput"
		v-model="internalValue"
		v-money="atmMask"
		:class="{ 'full-prefix': longPrefix, 'input-size-large': large, 'input-size-x-large': xLarge }"
		:prefix="computedPrefix"
		:placeholder="placeholder"
		:disabled="disabled"
		:hint="computedHint"
		:tabindex="tabindex"
		:autocomplete="computedAutoComplete"
		:name="name"
		:mask="computedMask"
		:counter="counter"
		:append-icon="computedAppendIcon"
		:success-messages="successMessages"
		:error-messages="errorMessages"
		:type="computedType"
		:rules="computedRules"
		:label="computedLabel"
		:required="required"
		:loading="loading"
		:maxlength="maxlength"
		:persistent-hint="computedPersistentHint"
		:suffix="suffix"
		:reverse="reverse"
		:filled="filled"
		:readonly="readonly"
		:validate-on-blur="true"
		:autocorrect="disableAutocorrect === undefined ? 'on' : 'off'"
		:autocapitalize="disableAutocorrect === undefined ? 'on' : 'off'"
		:spellcheck="disableAutocorrect === undefined"
		:autofocus="autofocus"
		:clearable="clearable"
		:return-masked-value="returnMaskedValue"
		:outlined="outlined"
		:dense="dense"
		:hide-details="hideDetails"
		class="eg-text-input"
		@click:append="onAppend"
		@input="onInput"
		@blur="onBlur"
		@focus="onFocus"
		@keydown="onKeyDown"
	>
		<template v-slot:progress>
			<slot name="progress"></slot>
		</template>
	</v-text-field>
</template>

<script>
import { formatDollarsToCents, formatCentsToDollars, decimalToInt, intToDecimal } from '@/helpers/formatters';
var nameParser = require('parse-full-name');

import Vue from 'vue';
import money from 'v-money';
Vue.use(money, { precision: 2 });

export default {
	props: {
		value: [String, Number],
		prefix: String,
		prefixColor: String,
		rules: Array,
		label: String,
		required: Boolean,
		type: String,
		maxlength: [String, Number],
		minlength: [String, Number],
		uniqueValue: Array,
		counter: Boolean,
		appendIcon: String,
		successMessages: [String, Array],
		errorMessages: [String, Array],
		autocomplete: String,
		name: String,
		tabindex: Number,
		hint: String,
		disabled: Boolean,
		placeholder: String,
		persistentHint: Boolean,
		suffix: String,
		reverse: Boolean,
		loading: Boolean,
		filled: Boolean,
		readonly: Boolean,
		disableAutocorrect: Boolean,
		autofocus: Boolean,
		minValue: Number,
		clearable: Boolean,
		pattern: String,
		mask: String,
		outlined: String,
		dense: String,
		hideDetails: [Boolean, String],
		decimalFormat: {
			type: Array,
			default() {
				return [1, 2];
			},
			validator(value) {
				return value.length === 2;
			}
		},
		country: String, //Used to change input mask for US or CA
		large: Boolean,
		xLarge: Boolean
	},
	data() {
		return {
			internalValue: null,
			passwordVisible: false,
			atmMask: null
		};
	},
	computed: {
		isAtm() {
			return this.type === 'atm';
		},
		isDecimal() {
			return this.type === 'decimal';
		},
		computedMask() {
			if (this.type === 'tel') {
				return 'phone';
			}

			if (this.type === 'postalCode') {
				if (this.country === 'US') {
					if (this.value && this.value.length > 5) {
						return '#####-####';
					}

					return '######'; // intentionally 6 so the 6th character isn't restricted from being typed
				} else if (this.country === 'CA') {
					return 'A#A #A#';
				}
			}

			if (this.isDecimal) {
				var beforeDecimal = new Array(this.decimalFormat[0] + 1).join('#');
				var afterDecimal = new Array(this.decimalFormat[1] + 1).join('#');
				return beforeDecimal + '.' + afterDecimal;
			}

			return this.mask;
		},
		returnMaskedValue() {
			return this.isDecimal || this.type === 'postalCode';
		},
		computedType() {
			if (this.isDecimal || this.isAtm || this.type === 'number') {
				return 'tel';
			}

			if (this.type === 'name') {
				return 'text';
			}

			if (this.type === 'password') {
				return this.passwordVisible ? 'text' : 'password';
			}

			return this.type;
		},
		computedPrefix() {
			var prefix = this.prefix;
			if (!this.prefix) {
				if (this.isAtm) {
					prefix = '$';
				}
			}

			return prefix;
		},
		computedAutoComplete() {
			var defaultAutocomplete = this.autocomplete;
			if (!this.autocomplete) {
				switch (this.type) {
					case 'tel':
						defaultAutocomplete = 'tel';
						break;
					case 'email':
						defaultAutocomplete = 'email';
						break;
					case 'password':
						defaultAutocomplete = 'password';
						break;
					case 'atm':
						defaultAutocomplete = 'off';
						break;
					case 'url':
						defaultAutocomplete = 'url';
						break;
				}
			}

			return defaultAutocomplete;
		},
		computedRules() {
			var computedRules = this.rules || [];

			if (this.required) {
				computedRules.push(v => (v && v.toString().length > 0) || `${this.label} is required`);
			}

			if (this.type === 'email') {
				computedRules.push(v => !v || /^([\w-+.']+)*@[\w-.]*\.([\w-]+)*$/.test(v) || `${this.label} must be valid`);
			} else if (this.type === 'tel') {
				computedRules.push(v => !v || v.match(/\d/g).length === 10 || `${this.label} must be valid`);
			} else if (this.type === 'url') {
				/* eslint-disable-next-line no-useless-escape */
				const urlRegEx = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
				computedRules.push(v => urlRegEx.test(v) || `${this.label} must be valid`);
			} else if (this.type === 'alphanumeric') {
				computedRules.push(v => !v || /^[a-zA-Z0-9 ]*$/g.test(v) || `${this.label} may only contain letters or numbers`);
			} else if (this.type === 'number') {
				computedRules.push(v => !v || /^[0-9]*$/g.test(v) || `${this.label} may only contain numbers`);
			} else if (this.isDecimal) {
				computedRules.push(v => !v || /^[0-9.]*$/g.test(v) || `${this.label} may only contain numbers`);
			}

			if (this.type === 'name') {
				computedRules.push(v => !v || this.validateName(v) || `You must provide a First and Last name`);
			}

			if (this.isAtm && this.minValue) {
				computedRules.push(v => !v || formatDollarsToCents(v) >= this.minValue || (!this.required && formatDollarsToCents(v) === 0) || `${this.label} must be greater than ${formatCentsToDollars(this.minValue, { addSymbol: true })}`);
			} else if (this.minValue && this.type === 'number') {
				computedRules.push(v => !v || v >= this.minValue || `${this.label} must be ${this.minValue} or greater`);
			}

			if (this.minlength) {
				computedRules.push(v => (v && v.length >= this.minlength) || `${this.label} must be greater than ${this.minlength} characters`);
			}

			if (this.maxlength) {
				computedRules.push(v => !v || v.length <= this.maxlength || `${this.label} must be less than ${this.maxlength} characters`);
			}

			if (this.uniqueValue) {
				computedRules.push(v => !v || !this.uniqueValue.includes(v) || `${this.label} is not available`);
			}

			if (this.computedPattern) {
				var reg = new RegExp('^' + this.computedPattern + '*$');
				computedRules.push(v => !v || reg.test(v) || `${this.label} is not valid`);
			}

			return computedRules;
		},
		computedLabel() {
			if (this.prefix && this.prefix.length > 1) {
				return null;
			}

			return this.label;
		},
		computedHint() {
			if (this.longPrefix && !this.hint && this.label && this.label.length > 0) {
				return this.label;
			}

			return this.hint;
		},
		longPrefix() {
			return this.prefix && this.prefix.length > 1;
		},
		computedPersistentHint() {
			if (this.longPrefix && !this.hint && this.label && this.label.length > 0) {
				return true;
			}

			return this.persistentHint;
		},
		computedAppendIcon() {
			if (this.type === 'password') {
				return this.passwordVisible ? 'visibility_off' : 'visibility';
			}

			return this.appendIcon;
		},
		computedPattern() {
			if (!this.pattern && this.type == 'number') {
				return '[0-9]';
			}

			return this.pattern;
		}
	},
	watch: {
		value(newVal) {
			this.setInternalValueFromExternalChange(newVal);
		},
		prefixColor() {
			this.setPrefixColor();
		}
	},
	mounted() {
		this.alertRequiredFields();
		this.setInternalValueFromExternalChange(this.value);
		this.handleAtm();
		if (this.prefixColor) {
			this.setPrefixColor();
		}
	},
	methods: {
		handleAtm() {
			if (this.isAtm) {
				this.atmMask = {
					decimal: '.',
					thousands: ',',
					prefix: '',
					suffix: '',
					precision: 2,
					masked: false
				};
			}
		},
		setInternalValueFromExternalChange(newVal) {
			var compareValue = this.internalValue;
			if (this.isAtm) {
				newVal = formatCentsToDollars(newVal);
				//Force formatting for compare to have 2 decimals '30.00'
				compareValue = formatCentsToDollars(formatDollarsToCents(compareValue));
			}

			if (this.isDecimal) {
				newVal = intToDecimal(newVal, this.decimalFormat[1]);
			}

			if (compareValue != newVal) {
				this.internalValue = newVal;
				if (this.isAtm) {
					this.$refs.egTextInput.$el.getElementsByTagName('input')[0].value = newVal;
				}
			}
		},
		alertRequiredFields() {
			if (this.required && this.label === undefined) {
				console.error('Label required for required input', this.$el);
			}

			if (this.type === 'postalCode' && this.country === undefined) {
				console.error('Country required for postalCode input', this.$el);
			}
		},
		onInput(val) {
			if (this.isAtm) {
				val = formatDollarsToCents(val);
			}

			if (this.isDecimal) {
				val = decimalToInt(val);
			}

			if (typeof val === 'string' && this.type !== 'password') {
				val = val.trim();
			}

			this.emitInput(val);
		},
		emitInput(emitValue) {
			this.$emit('input', emitValue);
		},
		onBlur() {
			if (this.isAtm) {
				this.internalValue = formatCentsToDollars(this.value);
			}

			if (this.isDecimal) {
				this.internalValue = intToDecimal(this.value, this.decimalFormat[1]);
			}

			this.$emit('blur');
		},
		onAppend() {
			if (this.type === 'password') {
				this.passwordVisible = !this.passwordVisible;
			}

			this.$emit('append');
		},
		onFocus() {
			this.$emit('focus');
		},
		onKeyDown(event) {
			//Dont allow negative amounts
			if (this.isAtm && event.key === '-') {
				event.preventDefault();
				return false;
			}

			if (this.computedPattern) {
				return this.restrictRegexPattern(event);
			}

			return true;
		},
		restrictRegexPattern(event) {
			// Exceptions like backspace, commas and arrow keys
			var exceptions = [8, 9, 13, 35, 36, 37, 38, 39, 40, 45, 46, 229];
			if (exceptions.includes(event.which)) {
				return true;
			}

			var re = new RegExp(this.computedPattern);
			if (re.test(event.key)) {
				return true;
			}

			event.preventDefault();
			return false;
		},
		setPrefixColor() {
			var prefix = this.$refs.egTextInput.$refs.prefix;
			if (prefix && this.prefixColor) {
				prefix.style.color = this.prefixColor;
			}
		},
		validateName(name) {
			//Converts to middile initial to have a period to fix bug with "E" or "Y"
			name = name ? name.replace(/\s([A-Z])\s/, ' $1. ') : '';
			var parsedName = nameParser.parseFullName(name);
			return parsedName.first !== '' && parsedName.last !== '';
		}
	}
};
</script>
