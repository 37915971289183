import '@mdi/font/css/materialdesignicons.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { iframeResizerContentWindow } from 'iframe-resizer'; // eslint-disable-line no-unused-vars

import Vue from 'vue';

_REALM.SENTRY = require('@/logging/sentry').default;
_REALM.STORE = require('@/store/register.js').default;
_REALM.MESA = require('@/store/mesaClient.js').default;
_REALM.STORAGE = require('@/helpers/storage');
_REALM.EVENT = require('@/helpers/event').default;
_REALM.TOAST = require('@/helpers/toast/index').default;
_REALM.LOADER = require('@/helpers/loader/index');
_REALM.TOOLS = require('@/helpers/tools');
_REALM.PENDO = require('@/helpers/pendo');
_REALM.ERRORHANDLER = require('@/helpers/errorHandler').default;

import App from './App.vue';
import router from './router/router';

// Vuetify
import vuetify from '@/plugins/vuetify';

//Register shared components globally
import '@/components/global/register';

//Register shared filters globally
import '@/filters/register';

//Register MESA and globally handle errors by default
_REALM.MESA.init(function(error) {
	_REALM.TOAST.error(error.message);
});

//Register Pendo
if (_REALM.CONFIG.AnalyticsEnabled) {
	_REALM.PENDO.install(_REALM.CONFIG.PendoKey);
}

Vue.config.productionTip = process.env.NODE_ENV === 'production';

import EventBus from '@/helpers/EventBus.vue';
Vue.mixin(EventBus);

_REALM.VM = new Vue({
	vuetify,
	router,
	render: h => h(App)
}).$mount('#app');
