<style lang="scss">
@import '@/scss/variables.scss';

.include-item-list-wrapper {
	list-style: none;

	.list-group-item {
		cursor: move;
		cursor: -webkit-grabbing;
		background-color: $default;
		border: 1px solid $grey;
	}

	.item-actions {
		position: absolute;
		right: 0 !important;
	}
}
</style>

<template>
	<div>
		<draggable :value="value" :options="dragOptions" :move="onMove" tag="ul" class="include-item-list-wrapper" @input="val => $emit('input', val)" @start="isDragging = true" @end="isDragging = false">
			<li v-for="(item, index) in value" :key="index" class="list-group-item mb-1">
				<v-layout wrap align-center class="mx-4">
					<v-flex class="d-inline-flex mb-4" xs12>
						<v-checkbox v-model="item._isChecked" dense :label="item.name" hide-details prepend-icon="drag_indicator"></v-checkbox>
						<v-spacer></v-spacer>
						<div class="mt-5">
							<slot name="itemActions" :item="item"></slot>
						</div>
					</v-flex>
				</v-layout>
			</li>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
	components: {
		draggable
	},
	props: {
		value: Array
	},
	data() {
		return {
			editable: true,
			isDragging: false,
			delayedDragging: false
		};
	},
	computed: {
		dragOptions() {
			return {
				animation: 0,
				group: 'item-name',
				disabled: !this.editable,
				ghostClass: 'sortable-ghost'
			};
		}
	},
	watch: {
		isDragging(newValue) {
			if (newValue) {
				this.delayedDragging = true;
				return;
			}

			this.$nextTick(() => {
				this.delayedDragging = false;
			});
		}
	},
	methods: {
		onMove({ relatedContext, draggedContext }) {
			const relatedElement = relatedContext.element;
			return (!relatedElement || !relatedElement.fixed) && !draggedContext.element.fixed;
		}
	}
};
</script>
