import realmClient from '../realmClient';

export default {
	listOnline: function(args) {
		return realmClient.post('Funds/ListOnline', args).then(function(funds) {
			//Translate fund.id to fund.fundId
			for (var i = 0; i < funds.length; i++) {
				funds[i].fundId = funds[i].id;
				delete funds[i].id;
			}

			return funds;
		});
	}
};
