<template>
	<v-radio-group :value="value" @change="$emit('input', $event)">
		<slot></slot>
	</v-radio-group>
</template>

<script>
export default {
	props: {
		value: [String, Boolean]
	}
};
</script>
