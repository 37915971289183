import fundService from '../services/fundService';
import _each from 'lodash/each';
import _find from 'lodash/find';

export function initState() {
	return {
		onlineFunds: []
	};
}

const getGivingFormFundsFromQuery = function() {
	const queryFunds = [];
	const queryFundId = _REALM.VM.$route.query.fundId;
	if (queryFundId) {
		queryFunds.push({ fundId: queryFundId.toLowerCase(), isMemoEnabled: false });
	}

	const queryData = _REALM.STORE.givingFormStore.state.givingFormQueryData;
	if (queryData.hasOwnProperty('amountsList')) {
		for (const fundAmount of queryData.amountsList) {
			queryFunds.push({ fundId: fundAmount.fundId, isMemoEnabled: false });
		}
	}

	return queryFunds;
};

export default {
	state: initState(),
	listOnline(args) {
		if (!_REALM.STORE.layoutStore.isPaymentForm()) {
			args.FundType = 'Giving';
		}

		return fundService.listOnline(args).then(function(onlineFunds) {
			_REALM.STORE.fundStore.state.onlineFunds = onlineFunds;
			return _REALM.STORE.fundStore.state.onlineFunds;
		});
	},
	getFund() {
		//return fundService.getFund({ id: fundId }).then(function(response) {
		//	return response.fund;
		//});
	},
	getSelectableFunds() {
		var funds = _REALM.STORE.fundStore.state.onlineFunds;

		if (!_REALM.STORE.layoutStore.isGivingForm()) {
			// return all funds
			return _REALM.TOOLS.buildSelectList(funds, 'fundId', 'name');
		}

		var selectableFunds = [];

		var givingFormFunds = _REALM.STORE.givingFormStore.state.givingForm.funds ?? [];

		if (!_REALM.STORE.givingFormStore.isAdminManaging()) {
			//When editing recurrence add funds that are in recurrence
			_each(_REALM.STORE.transactionStore.state.transaction.recurrenceAmounts, function(recurrenceAmount) {
				var fundInForm = _find(givingFormFunds, function(fund) {
					return fund.fundId === recurrenceAmount.solutionFundId;
				});

				if (!fundInForm) {
					givingFormFunds.push({
						fundId: recurrenceAmount.solutionFundId
					});
				}
			});

			const queryFunds = getGivingFormFundsFromQuery();
			for (const queryFund of queryFunds) {
				if (!givingFormFunds.some(f => f.fundId === queryFund.fundId)) {
					givingFormFunds.push(queryFund);
				}
			}
		}

		_each(givingFormFunds, function(givingFormFund) {
			var fund = _find(funds, function(fund) {
				return fund.fundId === givingFormFund.fundId;
			});

			if (fund) {
				selectableFunds.push({
					text: fund.displayName ? fund.displayName : fund.name,
					value: fund.fundId
				});
			}
		});

		return selectableFunds;
	}
};
