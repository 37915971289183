import goToPage from '@/router/goToPage';
import addDefaultMeta from '@/router/routes/addDefaultMeta';

var routes = [
	{
		name: 'go',
		path: '/:siteUrl/-/go',
		beforeEnter: (to, from, next) => {
			goToPage(to, next);
		},
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/:siteUrl/-/account',
		component: () => import('@/router/layouts/BaseLayout.vue'),
		children: addDefaultMeta(require('@/router/routes/accountUrl').default, false)
	},
	{
		path: '/:siteUrl/-/giving',
		component: () => import('@/router/layouts/BaseLayout.vue'),
		children: [...addDefaultMeta(require('@/router/routes/givingAdminUrl').default, true), ...addDefaultMeta(require('@/router/routes/givingCongregantUrl').default, false)]
	},
	{
		path: '/:siteUrl/-/form',
		component: () => import('@/router/layouts/DonorLayout.vue'),
		children: addDefaultMeta(require('@/router/routes/donorUrl').default, false)
	},
	{
		path: '/:siteUrl/-/give/*',
		redirect: '/:siteUrl/-/form/give/*'
	},
	{
		path: '/:siteUrl/-/error',
		component: () => import('@/router/layouts/BaseLayout.vue'),
		children: addDefaultMeta(require('@/router/routes/errorUrl').default, false)
	},
	{
		path: '/:siteUrl/*',
		redirect: '/:siteUrl/-/error/404/'
	}
];

export default addDefaultMeta(routes);
