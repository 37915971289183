<style lang="scss">
@import '@/scss/variables.scss';
.radio-wrapper {
	.v-label {
		color: $black;
	}
}
</style>

<template>
	<v-radio class="radio-wrapper" :label="label" :value="value" @change="$emit('input', $event)"></v-radio>
</template>

<script>
export default {
	props: {
		value: [String, Boolean],
		label: [String]
	}
};
</script>
