// Globally register shared filters for convenience, because they
// will be used very frequently. Filters are registered using the
// PascalCased version of their file name.

import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireFilter = require.context('.', true, /[\w-]+\.js$/);

requireFilter.keys().forEach(fileName => {
	const filterConfig = requireFilter(fileName);

	const componentName = upperFirst(
		camelCase(
			fileName
				// Remove folders
				.split('/')
				.pop()
				// Remove the file extension from the end
				.replace(/\.\w+$/, '')
		)
	);
	// Globally register the component
	Vue.filter(componentName, filterConfig.default || filterConfig);
});
