import capitalizeFirstLetter from '@/filters/CapitalizeFirstLetter';

export default function(transaction) {
	if (transaction.paymentMethodType.isCHECKING() || transaction.paymentMethodType.isSAVINGS()) {
		return capitalizeFirstLetter(transaction.paymentMethodType.text);
	}

	var ccType = capitalizeFirstLetter(transaction.creditCardType.text);
	if (transaction.creditCardType.isMASTERCARD()) {
		ccType = 'MasterCard';
	} else if (transaction.creditCardType.isAMERICANEXPRESS()) {
		ccType = 'AMEX';
	}

	return capitalizeFirstLetter(transaction.paymentMethodType.text) + ': ' + ccType;
}
