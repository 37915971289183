import realmClient from '../realmClient';

export default {
	getAuthAccount() {
		return realmClient.post('Spa/AuthAccount');
	},
	checkUnauthenticatedEmail(emailAddress) {
		return realmClient.get('Unauthenticated/CheckUnauthenticatedEmail', { emailAddress }).then(function(resp) {
			return resp && resp.individualId !== null;
		});
	},
	IndividualIdByEmail(emailAddress) {
		return realmClient.get('Unauthenticated/IndividualIdByEmail', { emailAddress }).then(function(resp) {
			return resp;
		});
	},
	addPassword(newAccount) {
		return realmClient.post('Account/Register', newAccount);
	}
};
