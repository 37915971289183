import realmClient from '../realmClient';

export default {
	getRecurrences(args) {
		return realmClient.post('Gifts/Recurrences', args).then(response => response.items);
	},
	getRecurrenceNotifications() {
		return realmClient.post('Giving/GetRecurrenceNotifications');
	},
	deleteRecurrenceNotifications(notificationIds) {
		return realmClient.post('Giving/DeleteRecurringPaymentMessage', { ids: notificationIds });
	},
	placeRecurrenceOnHold(recurrenceId, reason) {
		return realmClient.post('Giving/PlaceRecurrenceOnHold', { recurrenceId, reason });
	},
	cancelRecurrence(recurrenceId, reason) {
		return realmClient.post('Giving/DeleteRecurringGift', { recurrenceId, reason });
	}
};
