<style lang="scss">
.banner-wrapper {
	.toggle-banner-button {
		z-index: 0;
		width: 50px;
		height: 50px;
	}

	.close-banner {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.banner {
		z-index: 2;
		animation: openBanner 0.25s;

		@keyframes openBanner {
			from {
				// transition: all 250ms ease-in-out;
				transform: scale(0);
				transform-origin: top right;
			}
			to {
				transform: scale(1);
				transform-origin: top right;
			}
		}
	}
}
</style>

<template>
	<v-layout wrap class="banner-wrapper">
		<v-flex v-if="!showBanner" class="text-right" xs12 pa-0>
			<v-btn color="primary" fab depressed class="toggle-banner-button ma-0" @click="showBanner = !showBanner">
				<v-icon class="toggle-banner-button-icon" medium>
					<slot v-if="this.$slots.icon" name="icon"></slot>
					<template v-else>
						feedback
					</template>
				</v-icon>
			</v-btn>
		</v-flex>

		<v-flex v-if="showBanner">
			<r-card raised color="grey" class="banner">
				<template v-if="this.$slots.title || this.$slots.icon" slot="title">
					<v-icon v-if="this.$slots.icon" large class="primary--text"><slot name="icon"></slot></v-icon>
					&nbsp;
					<span class="display-1">
						<slot name="title"></slot>
					</span>

					<v-icon class="close-banner" medium @click="showBanner = !showBanner">clear</v-icon>
				</template>
				<v-flex xs12>
					<slot name="text"></slot>
				</v-flex>
			</r-card>
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	props: {
		showBannerOnInit: Boolean
	},
	data() {
		return {
			showBanner: false
		};
	},
	created() {
		this.showBanner = typeof this.showBannerOnInit === 'boolean' ? this.showBannerOnInit : false;
	}
};
</script>
