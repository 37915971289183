<script>
import { EventBus } from '@/helpers/event';
export default {
	data() {
		return {
			EVENTSTRACKER: []
		};
	},
	destroyed() {
		if (this.EVENTSTRACKER) {
			this.EVENTSTRACKER.forEach(event => {
				EventBus.$off(event.name, event.func);
			});
		}
	},
	methods: {
		onEvent(name, func) {
			this.EVENTSTRACKER.push({
				name: name,
				func: func
			});

			EventBus.$on(name, func);
		}
	}
};
</script>
