<template>
	<r-dialog :value="value" max-width="700px" @input="$emit('input', $event)">
		<template slot="title">
			Please provide a reason...
		</template>
		<r-form :id="'form' + _uid" ref="reasonForm" :submitMethod="submitReason">
			<r-text-input v-model="reason" label="Reason" required></r-text-input>
		</r-form>

		<template slot="actions">
			<v-spacer></v-spacer>
			<r-btn color="default" @click="closeDialog">Cancel</r-btn>
			<r-btn :form="'form' + _uid" type="submit">Continue</r-btn>
		</template>
	</r-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		submitCallback: {
			type: Function,
			required: true
		}
	},
	data() {
		return {
			reason: null
		};
	},
	methods: {
		submitReason() {
			if (this.$refs.reasonForm.validate()) {
				this.closeDialog();
				this.submitCallback(this.reason);
				this.reason = null;
			}
		},
		closeDialog() {
			this.$emit('input', false);
		}
	}
};
</script>
