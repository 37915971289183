import accountService from '../services/accountService';
//import _cloneDeep from 'lodash/cloneDeep';
//import capitalizeFirstLetter from '@/filters/CapitalizeFirstLetter';
//import _sortBy from 'lodash/sortBy';

//var mesa = require('../mesaClient.js').default;
//accountService.init(mesa.do, window.location.origin);

//var jwtDecode = require('jwt-decode');
//var nameParser = require('parse-full-name');

export function initState() {
	return {
		newAccount: {},
		authAccount: resetAccount()
	};
}

var resetAccount = function() {
	return {
		primaryAddress: {
			country: typeof _REALM !== 'undefined' && _REALM.STORE && _REALM.STORE.siteStore.state.site && _REALM.STORE.siteStore.state.site.country ? _REALM.STORE.siteStore.state.site.country : 'US'
		}
	};
};

export default {
	state: initState(),
	isLoggedIn() {
		return _REALM.STORE.accountStore.state.authAccount.accountId !== undefined;
	},
	emailHasAccount(emailAddress) {
		return accountService.checkUnauthenticatedEmail(emailAddress);
	},
	IndividualIdByEmail(resp) {
		return accountService.IndividualIdByEmail(resp);
	},
	getAuthAccount() {
		const cacheToken = `auth.account.cache`;
		return _REALM.STORE.CACHE.verify(cacheToken).catch(function() {
			return accountService
				.getAuthAccount()
				.then(function(resp) {
					_REALM.STORE.accountStore.state.authAccount = _REALM.STORE.CACHE.set(cacheToken, resp);
					return _REALM.STORE.accountStore.state.authAccount;
				})
				.catch(function() {
					_REALM.STORE.accountStore.clearLogin();
				});
		});
	},
	clearLogin() {
		_REALM.STORE.accountStore.state.authAccount = resetAccount();
	},
	accountAdded() {
		_REALM.STORE.accountStore.state.newAccount = {};
		_REALM.EVENT.emit('account-registered');
	},
	addPassword() {
		let request = {
			registerInputViewModel: {
				EmailAddress: _REALM.STORE.accountStore.state.newAccount.email,
				Password: _REALM.STORE.accountStore.state.newAccount.password,
				ConfirmPassword: _REALM.STORE.accountStore.state.newAccount.confirmPassword,
				ReCaptcha: _REALM.STORE.accountStore.state.newAccount.reCaptchaValue,
				individualId: _REALM.STORE.transactionStore.state.transaction.individualId
			}
		};

		return accountService.addPassword(request).then(function(resp) {
			return _REALM.STORE.accountStore.accountAdded(resp);
		});
	},
	redirectToSSO() {
		window.location.href = _REALM.CONFIG.SignInUrl;
	}
};
