<template>
	<r-dialog v-model="showConfirmDialog" width="400" scrollable persistent>
		<template slot="title">
			Are you sure?
		</template>
		<div class="subheading text-pre-line">{{ message }}</div>
		<template slot="actions">
			<r-btn @click="yesClicked">Yes</r-btn>
			<r-btn text @click="noClicked">Cancel</r-btn>
		</template>
	</r-dialog>
</template>
<script>
import vuetify from '@/plugins/vuetify';
export default {
	vuetify: vuetify,
	data() {
		return {
			message: null,
			showConfirmDialog: false,
			yesCallback: null,
			noCallback: null
		};
	},

	methods: {
		confirm(message, yesCallback, noCallback) {
			this.message = message;
			this.yesCallback = yesCallback;
			this.noCallback = noCallback;

			if (_REALM.TOOLS.inIframe()) {
				_REALM.TOOLS.scrollTopIframe();
			}

			this.showConfirmDialog = true;
		},
		yesClicked() {
			this.showConfirmDialog = false;

			if (_REALM.TOOLS.inIframe()) {
				_REALM.TOOLS.scrollBottomIframe();
			}

			if (typeof this.yesCallback === 'function') {
				this.yesCallback();
			}
		},
		noClicked() {
			this.showConfirmDialog = false;

			if (_REALM.TOOLS.inIframe()) {
				_REALM.TOOLS.scrollBottomIframe();
			}

			if (typeof this.noCallback === 'function') {
				this.noCallback();
			}
		}
	}
};
</script>
