<template>
	<v-alert :value="true" :color="computedColor" :outlined="outlined" :text="text" :type="type" class="break-word" :dismissible="dismissible" @input="$emit('input', $event)">
		<slot></slot>
	</v-alert>
</template>

<script>
export default {
	props: {
		color: String,
		outlined: Boolean,
		text: Boolean,
		type: String,
		dismissible: Boolean
	},
	computed: {
		computedColor() {
			if (this.type) {
				return this.type;
			}

			if (!this.color) {
				return 'success';
			}

			return _REALM.TOOLS.findColor(this.color);
		}
	},
	mounted() {
		if (_REALM.TOOLS.inIframe()) {
			_REALM.TOOLS.scrollTopIframe();
		}
	}
};
</script>
