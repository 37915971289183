export function intToDecimal(value, precision) {
	value = (value + '').replace(/[^\d.-]/g, '');
	return parseFloat(value / 100).toFixed(precision);
}

export function decimalToInt(value) {
	value = (value + '').replace(/[^\d.-]/g, '');
	if (value && value.includes('.')) {
		value = value.substring(0, value.indexOf('.') + 3);
	}

	return value ? Math.round(parseFloat(value) * 100) : 0;
}

export function formatCentsToDollars(value, options) {
	const defaults = {
		addSymbol: false
	};

	options = Object.assign({}, defaults, options);

	value = (value + '').replace(/[^\d.-]/g, '');
	value = parseFloat(value / 100);
	value = value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	return options.addSymbol ? value : value.replace('$', '');
}

export function formatDollarsToCents(value) {
	value = (value + '').replace(/[^\d.-]/g, '');
	if (value && value.includes('.')) {
		value = value.substring(0, value.indexOf('.') + 3);
	}

	return value ? Math.round(parseFloat(value) * 100) : 0;
}

export function formatDollars(value, options) {
	const defaults = {
		addSymbol: false
	};

	options = Object.assign({}, defaults, options);

	value = (value + '').replace(/[^\d.-]/g, '');
	value = parseFloat(value);
	value = value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	return options.addSymbol ? value : value.replace('$', '');
}

export function depositAccountNameFormatter(depositAccount) {
	return depositAccount ? depositAccount.name + ' (***' + depositAccount.last4 + ')' : null;
}
