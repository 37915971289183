export default [
	{
		path: '',
		redirect: { name: 'giving-forms' }
	},
	{
		name: 'giving-forms',
		path: 'giving-forms',
		component: () => import('@/router/pages/giving/GivingFormsPage.vue'),
		meta: {
			title: 'Giving Forms'
		}
	},
	{
		name: 'giving-number-settings',
		path: 'settings/giving-numbers',
		component: () => import('@/router/pages/giving/GivingNumberSettingsPage.vue'),
		meta: {
			title: 'Giving Number Settings'
		}
	},
	{
		name: 'giving-form',
		path: 'giving-form/:page?/:formId?',
		component: () => import('@/router/pages/giving/GivingFormPage.vue'),
		beforeEnter: (to, from, next) => {
			_REALM.STORE.givingStore.settings().then(function() {
				if (!to.params.page) {
					next({ name: 'giving-form', params: { siteUrl: to.params.siteUrl, page: 'general-settings' } });
				}

				next();
			});
		},
		meta: {
			title: 'Giving Form Setup',
			metaTags: [
				{
					name: 'description',
					content: 'Giving Form Setup'
				}
			]
		}
	},
	{
		name: 'pledge-form',
		path: 'pledge-form/:page?/:campaignId?',
		component: () => import('@/router/pages/giving/PledgeFormPage.vue'),
		beforeEnter: (to, from, next) => {
			_REALM.STORE.givingStore.settings().then(function() {
				if (!to.params.page) {
					next({ name: 'pledge-form', params: { siteUrl: to.params.siteUrl, page: 'general-settings' } });
				}

				next();
			});
		},
		meta: {
			title: 'Pledge Form Setup',
			metaTags: [
				{
					name: 'description',
					content: 'Pledge Form Setup'
				}
			]
		}
	}
];
