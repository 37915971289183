import realmClient from '../realmClient';
import { formatCentsToDollars } from '@/helpers/formatters';
import _find from 'lodash/find';

var processingLoadingMessagingTimeout;

var translateIntToDecimalAmountList = function(amountsList) {
	var decimalAmountsList = [];
	for (var i = 0; i < amountsList.length; i++) {
		var fund = _find(_REALM.STORE.fundStore.state.onlineFunds, function(fund) {
			return fund.fundId === amountsList[i].fundId;
		});

		if (!fund && _REALM.STORE.givingStore.state.settings.processingCostFund.fundId === amountsList[i].fundId) {
			fund = _REALM.STORE.givingStore.state.settings.processingCostFund;
		}

		var amount = {
			fundName: fund.displayName ? fund.displayName : fund.name,
			fundId: amountsList[i].fundId,
			amount: formatCentsToDollars(amountsList[i].amount),
			memo: amountsList[i].memo
		};

		decimalAmountsList.push(amount);
	}

	return decimalAmountsList;
};

var translateRecurrenceJson = function(transaction) {
	var recurrenceType = 'Once';
	if ([1, 2].includes(transaction.frequencyType)) {
		recurrenceType = 'Weekly';
	} else if ([3, 4, 5].includes(transaction.frequencyType)) {
		recurrenceType = 'Monthly';
	}

	var recurrence = {
		recurrenceType: recurrenceType,
		RecurrenceData: {
			startDate: transaction.giftDate,
			day1: transaction.giftDate.date(),
			every3Months: transaction.frequencyType === 5,
			everyOther: transaction.frequencyType === 2,
			dayOfWeek: transaction.giftDate.day()
		}
	};

	var isExistingWithCount = transaction.currentCount > 0 && _REALM.STORE.transactionStore.state.transaction.recurrenceId;

	if (isExistingWithCount && transaction.recurrenceData.day1) {
		recurrence.RecurrenceData.day1 = transaction.recurrenceData.day1;
	}

	if (isExistingWithCount && transaction.recurrenceData.dayOfWeek) {
		recurrence.RecurrenceData.dayOfWeek = transaction.recurrenceData.dayOfWeek;
	}

	if (transaction.frequencyType === 4) {
		recurrence.RecurrenceData.day1 = transaction.firstDayOfMonth;
		recurrence.RecurrenceData.day2 = transaction.secondDayOfMonth;
	}

	if (transaction.completionType === 2) {
		recurrence.RecurrenceData.stopDate = transaction.stopDate;
	}

	if (transaction.completionType === 1) {
		recurrence.RecurrenceData.occurrenceCount = transaction.numberOfTimes;
	}

	return JSON.stringify(recurrence);
};

var handleTransactionResponse = function(response) {
	resetProcessingLoadingMessaging();
	if (response.recurringGiftArgs) {
		let returnArgs = _REALM.TOOLS.toCamelCase(JSON.parse(response.recurringGiftArgs));
		returnArgs.individualId = response.individualId;
		returnArgs.donorSentAccountInvite = response.donorSentAccountInvite == 'true';
		return returnArgs;
	} else {
		response.donorSentAccountInvite = response.donorSentAccountInvite == 'true';
		return response;
	}
};

var retryGetLastGift = function(handleError) {
	var lastGiftId = _REALM.STORE.transactionStore.getLastGiftId();
	if (!lastGiftId) {
		if (handleError) {
			_REALM.TOAST.error('Error occured processing gift');
		}

		return Promise.reject();
	}

	return realmClient.post('Unauthenticated/RetryGetLastGift', { lastGiftId, textToken: _REALM.STORE.transactionStore.state.transaction.textToken, sourceId: _REALM.STORE.transactionStore.state.transaction.sourceId }, { handleError: handleError }).then(handleTransactionResponse);
};

var handleTransactionError = function() {
	return retryGetLastGift(false).catch(function() {
		return retryGetLastGift(true).catch(function(error) {
			resetProcessingLoadingMessaging();
			_REALM.LOADER.hide();
			throw error;
		});
	});
};

var resetProcessingLoadingMessaging = function() {
	clearTimeout(processingLoadingMessagingTimeout);
};

var processingLoadingMessaging = function() {
	var transactionWording = _REALM.STORE.transactionStore.state.transaction.isRecurrence ? 'schedule' : 'process';
	var transactionType = _REALM.STORE.layoutStore.isPaymentForm() ? 'payment' : 'gift';
	var loadingText = `Please wait while we ${transactionWording} your ${transactionType}.`;
	_REALM.LOADER.show({ text: loadingText });
	//Show messages at 8 seconds, 30 seconds and 60 seconds to let the user know the gift is still processing
	processingLoadingMessagingTimeout = setTimeout(function() {
		_REALM.LOADER.show({ text: loadingText, subText: 'Sometimes, this can take a minute.' });
		processingLoadingMessagingTimeout = setTimeout(function() {
			const trafficType = _REALM.STORE.layoutStore.isPaymentForm() ? 'payment' : 'giving';
			_REALM.LOADER.show({ text: loadingText, subText: `We're experiencing a high amount of ${trafficType} traffic right now.` });
			processingLoadingMessagingTimeout = setTimeout(function() {
				_REALM.LOADER.show({
					text: loadingText,
					subText: "This is taking a little longer than expected, but we'll continue to process for two more minutes."
				});

				processingLoadingMessagingTimeout = setTimeout(function() {
					_REALM.LOADER.show({
						text: loadingText,
						subText: "This is taking a little longer than expected, but we'll continue to process for one more minute."
					});
				}, 60000);
			}, 30000);
		}, 22000);
	}, 8000);
};

var getReferenceText = function(transaction) {
	if (_REALM.STORE.givingFormStore.isGoGiver()) {
		return 'GoMethod';
	} else if (isTransactionPayment(transaction)) {
		return 'Payment';
	} else if (_REALM.STORE.givingFormStore.isTextGiver()) {
		return 'Text Gift';
	} else if (_REALM.TOOLS.browser().mobile) {
		return 'MobileGift';
	}

	return 'OnlineGift';
};

const isTransactionPayment = function(transaction) {
	const fund = _REALM.STORE.fundStore.state.onlineFunds.find(f => f.fundId === transaction.amountsList[0].fundId);
	return fund.fundType === 'Payments';
};
export default {
	processUnauthenticatedTransaction(transaction) {
		var unauthenticatedTransaction = {
			FundAmountGroups: translateIntToDecimalAmountList(transaction.amountsList),
			formId: transaction.formId,
			PaymentMethodId: transaction.paymentMethodId,
			MintCustomerId: transaction.mintCustomerId,
			BillingEmail: transaction.billingEmail,
			RecurrenceJson: translateRecurrenceJson(transaction),
			Frequency: transaction.frequencyType,
			BeginDate: transaction.giftDate,
			RecurrenceId: transaction.recurrenceId,
			ReferenceText: getReferenceText(transaction),
			LastGiftId: transaction.lastGiftId,
			TextToken: transaction.textToken,
			IndividualId: transaction.individualId,
			IsNewProfile: transaction.isNewProfile,
			SourceId: transaction.sourceId,
			MemorialId: transaction.memorialId
		};

		if (_REALM.VM.$route.query.token && _REALM.STORE.layoutStore.isPaymentForm()) {
			unauthenticatedTransaction.RequestToken = _REALM.VM.$route.query.token;
		}

		processingLoadingMessaging();
		return realmClient
			.post('Unauthenticated/SpaProcessOnlineGift', { args: JSON.stringify(unauthenticatedTransaction) }, { handleError: false })
			.then(handleTransactionResponse)
			.catch(handleTransactionError);
	},
	getRecurringGift(recurrenceId) {
		return realmClient.post('Giving/GetRecurringGift', { recurrenceId }).then(function(recurrence) {
			if (recurrence.status === 'Complete') {
				_REALM.STORE.givingFormStore.state.editingRecurrenceIsComplete = true;
				return null;
			}

			if (recurrence.recurrenceData.occurrenceCount) {
				recurrence.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.NUMBER_OF_TIMES;
			} else if (recurrence.recurrenceData.stopDate) {
				recurrence.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE;
			} else {
				recurrence.completionType = _REALM.STORE.transactionStore.enums.mint_Recurrence_CompletionType.UNTIL_I_CANCEL;
			}

			if (recurrence.recurrenceType === 'Weekly' && recurrence.recurrenceData.everyOther == 'true') {
				recurrence.frequencyType = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_OTHER_WEEK;
			} else if (recurrence.recurrenceType === 'Weekly') {
				recurrence.frequencyType = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.WEEKLY;
			} else if (recurrence.recurrenceType === 'Monthly' && recurrence.recurrenceData.every3Months == 'true') {
				recurrence.frequencyType = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_THREE_MONTHS;
			} else if (recurrence.recurrenceType === 'Monthly' && recurrence.recurrenceData.day2) {
				recurrence.frequencyType = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.TWICE_A_MONTH;
			} else if (recurrence.recurrenceType === 'Monthly') {
				recurrence.frequencyType = _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.MONTHLY;
			}

			return recurrence;
		});
	},
	processTransaction(transaction) {
		var authTransaction = {
			FundAmountGroups: translateIntToDecimalAmountList(transaction.amountsList),
			formId: transaction.formId,
			PaymentMethodId: transaction.paymentMethodId,
			IndividualId: transaction.individualId,
			MintCustomerId: transaction.mintCustomerId,
			BillingEmail: transaction.billingEmail,
			RecurrenceJson: translateRecurrenceJson(transaction),
			Frequency: transaction.frequencyType,
			BeginDate: transaction.giftDate,
			RecurrenceId: transaction.recurrenceId,
			ReferenceText: getReferenceText(transaction),
			Reason: transaction.reason,
			LastGiftId: transaction.lastGiftId,
			MemorialId: transaction.memorialId
		};

		if (_REALM.VM.$route.query.token && _REALM.STORE.layoutStore.isPaymentForm()) {
			authTransaction.RequestToken = _REALM.VM.$route.query.token;
		}

		processingLoadingMessaging();
		return realmClient
			.post('Giving/SpaProcessOnlineGift', { args: JSON.stringify(authTransaction) }, { handleError: false })
			.then(handleTransactionResponse)
			.catch(handleTransactionError);
	},
	calculatePledgeMessage(args) {
		return realmClient.post('Giving/GetPledgeProjectedTotals', { args: JSON.stringify(args) });
	},
	getLastGift(lastGiftId, emailAddress) {
		return realmClient.post('Unauthenticated/GetLastGift', { lastGiftId, emailAddress }, { handleError: false });
	},
	getRecurrenceCount(args) {
		return realmClient.post('Giving/GetRecurrenceCount', args);
	},
	readPaymentToken(token) {
		return realmClient.post('Unauthenticated/ReadPaymentToken', { token }).then(response => JSON.parse(decodeURIComponent(response.givingFormData)));
	}
};
