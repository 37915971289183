var autoHideTimeout;
import Vue from 'vue';
import fullPageLoader from './FullPageLoader.vue';

let fullPageLoaderComponent = null;

function createComponent() {
	const component = new Vue(fullPageLoader);
	var appId = document.getElementById('app');
	if (appId.innerHTML !== '') {
		appId.appendChild(component.$mount().$el);
	} else {
		document.body.appendChild(component.$mount().$el);
	}

	return component;
}

function getComponent() {
	if (!fullPageLoaderComponent) {
		fullPageLoaderComponent = createComponent();
	}

	return fullPageLoaderComponent;
}

export function hide() {
	getComponent().hide();
	clearTimeout(autoHideTimeout);
	fullPageLoaderComponent = null;
}

export function show(options) {
	_REALM.LOADER.hide();
	getComponent().show(options);

	autoHideTimeout = setTimeout(function() {
		_REALM.LOADER.hide();
	}, 300000);
}
