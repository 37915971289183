import realmClient from '../realmClient';

var handleSaveGivingNumberResponse = function(settings) {
	return _REALM.STORE.givingStore.resetCache(settings);
};

export default {
	saveSettings: function(args) {
		return realmClient.post('Giving/SaveGivingNumberSettings', args).then(handleSaveGivingNumberResponse);
	},
	reassignment: function() {
		return realmClient.get('Giving/GetGivingNumberReassignment');
	},
	saveReassignment: function(args) {
		return realmClient.post('Giving/SaveGivingNumberReassignment', args);
	},
	runReassignment: function() {
		return realmClient.post('Giving/RunGivingNumberReassignment');
	},
	deleteReassignment: function() {
		return realmClient.get('Giving/DeleteGivingNumberReassignment');
	},
	getReportUrl(args) {
		return realmClient.post('Giving/GetReassignmentCsvUrl', args);
	}
};
