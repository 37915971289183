<style lang="scss">
.text-area-wrapper {
	textarea::placeholder {
		color: rgba(0, 0, 0, 0.5) !important;
	}
}
</style>

<template>
	<v-textarea class="text-area-wrapper" :rules="computedRules" :value="value" :required="required" :maxlength="maxlength" :label="label" :rows="1" :placeholder="placeholder" auto-grow @input="$emit('input', $event)"></v-textarea>
</template>

<script>
export default {
	props: {
		required: Boolean,
		rules: Array,
		value: [String, Number],
		maxlength: [String, Number],
		minlength: [String, Number],
		label: String,
		placeholder: String
	},
	computed: {
		computedRules: function() {
			var computedRules = this.rules || [];

			if (this.required) {
				computedRules.push(v => (v && v.toString().length > 0) || `${this.label} is required`);
			}

			if (this.minlength) {
				computedRules.push(v => (v && v.length >= this.minlength) || `${this.label} must be greater than ${this.minlength} characters`);
			}

			if (this.maxlength) {
				computedRules.push(v => !v || v.length <= this.maxlength || `${this.label} must be less than ${this.maxlength} characters`);
			}

			return computedRules;
		}
	},
	mounted() {
		if (this.required && this.label === undefined) {
			console.error('Label required for required text area', this.$el);
		}
	}
};
</script>
