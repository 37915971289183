import axios from 'axios';
import moment from 'moment';
import realmClient from './realmClient';

var _errorHandler;

let _lastRetrievedGrantAt = null,
	_identityGrant = null;

const getIdentityGrant = function() {
	if (_REALM.STORE.accountStore.isLoggedIn() === false) {
		return new Promise(function(resolve) {
			resolve(null);
		});
	}

	if (_identityGrant && _lastRetrievedGrantAt && moment().diff(_lastRetrievedGrantAt, 'minutes') < 5) {
		return new Promise(function(resolve) {
			resolve(_identityGrant);
		});
	}

	return realmClient.post('Account/IdentityGrant').then(function(response) {
		_identityGrant = response.grant;
		_lastRetrievedGrantAt = moment();
		return _identityGrant;
	});
};

export default {
	init: function(errorHandler) {
		_errorHandler = errorHandler;
	},
	do: function(url, req, config) {
		return getIdentityGrant().then(function(identityGrant) {
			var defaultConfig = {
				handleError: true
			};

			config = { ...defaultConfig, ...config };
			var axiosConfig = {
				headers: {
					'Content-Type': 'application/protobuf+base64',
					authorization: identityGrant ? identityGrant : ''
				}
			};

			return executePost(url, req, config, axiosConfig);
		});
	}
};

var executePost = function(url, req, config, axiosConfig) {
	return axios
		.post(url, req, axiosConfig)
		.then(function(resp) {
			if (resp && resp.status && resp.status === 200) {
				return resp.data;
			}

			// not a valid mesa return, so try to convert it to an error and reject
			_REALM.LOADER.hide();
			throw returnAsMesaError(resp);
		})
		.catch(function(error) {
			_REALM.LOADER.hide();

			var mesaError = returnAsMesaError(error);

			if (mesaError.code == errorCodes.UNAUTHENTICATED) {
				//TODO Redirect to login page
			}

			// check for handling of errors and a configured global _errorHandler from init to call
			if (typeof config.handleError === 'boolean' && config.handleError && typeof _errorHandler === 'function') {
				_errorHandler(mesaError);
			}

			throw mesaError;
		});
};

var errorCodes = Object.freeze({
	NO_ERROR: 0,
	UNKNOWN: 1,
	INTERNAL: 2,
	UNAVAILABLE: 3,
	CANCELED: 4,
	ABORTED: 5,
	BAD_ROUTE: 6,
	UNIMPLEMENTED: 7,
	DEADLINE_EXCEEDED: 8,
	RESOURCE_EXHAUSTED: 9,
	INVALID_ARGUMENT: 10,
	FAILED_PRECONDITION: 11,
	OUT_OF_RANGE: 12,
	NOT_FOUND: 13,
	ALREADY_EXISTS: 14,
	PERMISSION_DENIED: 15,
	UNAUTHENTICATED: 16,
	DATA_LOSS: 17,
	TEAPOT: 18
});

var returnAsMesaError = function(error) {
	if (error && error.response && error.response.data && error.response.data.code && error.response.data.code_text && error.response.data.message) {
		error.response.data.message = cleanErrorMessage(error.response.data.message);
		return error.response.data;
	}

	return {
		code: 2,
		code_text: 'internal',
		message: cleanErrorMessage(error.message),
		meta: {
			error: error
		},
		stack: null
	};
};

var cleanErrorMessage = function(message) {
	if (!message) {
		return message;
	}

	message = cleanMintMessage(message);
	return message;
};

var cleanMintMessage = function(message) {
	var colonIndex = message.indexOf(':');
	if (colonIndex > -1) {
		var fieldName = message.substring(0, colonIndex);
		message = message.replace(fieldName + ': ' + fieldName, fieldName);
	}
	return message;
};
