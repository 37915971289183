export default [
	{
		name: 'donor-form',
		path: 'give/:givingFormUrl?',
		component: () => import('@/router/pages/giving/DonorFormPage.vue'),
		meta: {
			title: 'Give Now',
			requiresAuth: false,
			beforeRoute(to, from, next) {
				_REALM.STORE.givingStore.settings().then(next);
			}
		}
	},
	{
		name: 'donor-payment-form',
		path: 'pay/:givingFormUrl?',
		component: () => import('@/router/pages/giving/DonorFormPage.vue'),
		meta: {
			title: 'Pay Now',
			requiresAuth: false,
			beforeRoute(to, from, next) {
				_REALM.STORE.givingStore.settings().then(next);
			}
		}
	},
	{
		name: 'donor-pledge-form',
		path: 'pledge/:pledgeFormUrl?',
		component: () => import('@/router/pages/giving/DonorPledgeFormPage.vue'),
		meta: {
			title: 'Pledge Now',
			requiresAuth: false,
			beforeRoute(to, from, next) {
				_REALM.STORE.givingStore.settings().then(next);
			}
		}
	}
];
