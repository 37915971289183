/* eslint-disable no-unused-vars */

// Code generated by protoc-gen-mesa v2.0.0, DO NOT EDIT.
// This file will be overridden by protoc-gen-mesa!
// source: mintproxy.proto

/*
Package mintproxy is a generated mRPC stub package.
This code was generated with mesa.ac.st/mrpc/cmd/protoc-gen-mesa v2.0.0.

It was genenerated from these files:
   mintproxy.proto
*/

var moment = require('moment');
var _cloneDeep = require('lodash/cloneDeep');

var _doFunc = null;
var _sourceURL = null;

var mintProxy = require('./mintproxy_pb.js');
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
var mint_payment_method_pb = require('./mint/payment_method_pb.js');

var mintProxyPath = '/realm.MintProxy/';

export default {
	init: function(doFunc, sourceURL) {
		_doFunc = doFunc;
		_sourceURL = sourceURL;
	},

	// =============================================================================
	// Methods
	// =============================================================================

	// getAddPaymentMethodForm calls the `GetAddPaymentMethodForm` endpoint on the MintProxy service.
	//
	// getAddPaymentMethodFormRequest Object Properties:
	//      siteId (string)
	//      customerId (wrappers.StringValue)
	//      originalPaymentMethodId (wrappers.StringValue)
	//      includeCreditCardLogos (bool)
	//      includeCheckingHelpers (bool)
	//      includeFieldNamesInValidation (bool)
	//      requireBillingFields (bool)
	//      includeBlankState (bool)
	//      requiredClass (string)
	//      errorValidationClass (string)
	//      successValidationClass (string)
	//      validateOnSubmitOnly (bool)
	//      defaultExpirationDate (bool)
	//      addedByStaff (bool)
	//      addedByGuest (bool)
	//      phoneNumber (string)
	//      isTextPaymentMethod (bool)
	//      autoStyleAccountNumber (bool)
	//      useVue (bool)
	//      allowAch (bool)
	//      allowCreditCard (bool)
	//      debitOnly (bool)
	//      merchantId (string)
	//
	// GetAddPaymentMethodForm returns the html for a new AddPaymentMethodForm
	getAddPaymentMethodForm: function(getAddPaymentMethodFormRequest, config) {
		if (typeof getAddPaymentMethodFormRequest.serializeBinary !== 'function') {
			getAddPaymentMethodFormRequest = this.builders.buildGetAddPaymentMethodFormRequest(getAddPaymentMethodFormRequest);
		}
		return _doFunc(_sourceURL + mintProxyPath + 'GetAddPaymentMethodForm', getAddPaymentMethodFormRequest.serializeBinary(), config).then(function(resp) {
			var result = mintProxy.GetAddPaymentMethodFormResponse.toObject(false, mintProxy.GetAddPaymentMethodFormResponse.deserializeBinary(resp));
			return result;
		});
	},

	// getAddPaymentMethodFormVariables calls the `GetAddPaymentMethodFormVariables` endpoint on the MintProxy service.
	//
	// getAddPaymentMethodFormVariablesRequest Object Properties:
	//      siteId (string)
	//      merchantId (string)
	//
	// GetAddPaymentMethodFormVariables returns new variables used in calling Vanco
	getAddPaymentMethodFormVariables: function(getAddPaymentMethodFormVariablesRequest, config) {
		if (typeof getAddPaymentMethodFormVariablesRequest.serializeBinary !== 'function') {
			getAddPaymentMethodFormVariablesRequest = this.builders.buildGetAddPaymentMethodFormVariablesRequest(getAddPaymentMethodFormVariablesRequest);
		}
		return _doFunc(_sourceURL + mintProxyPath + 'GetAddPaymentMethodFormVariables', getAddPaymentMethodFormVariablesRequest.serializeBinary(), config).then(function(resp) {
			var result = mintProxy.GetAddPaymentMethodFormVariablesResponse.toObject(false, mintProxy.GetAddPaymentMethodFormVariablesResponse.deserializeBinary(resp));
			return result;
		});
	},

	// cacheValue calls the `CacheValue` endpoint on the MintProxy service.
	//
	// cacheValueRequest Object Properties:
	//      args (mint.CacheValueInput)
	//
	// CacheValue adds the recaptcha value to cache for checkign when vanco adds a payment method to The Mint
	cacheValue: function(cacheValueRequest, config) {
		if (typeof cacheValueRequest.serializeBinary !== 'function') {
			cacheValueRequest = this.builders.buildCacheValueRequest(cacheValueRequest);
		}
		return _doFunc(_sourceURL + mintProxyPath + 'CacheValue', cacheValueRequest.serializeBinary(), config).then(function(resp) {
			var result = mintProxy.CacheValueResponse.toObject(false, mintProxy.CacheValueResponse.deserializeBinary(resp));
			return result;
		});
	},

	// getBankName calls the `GetBankName` endpoint on the MintProxy service.
	//
	// getBankNameRequest Object Properties:
	//      args (mint.GetBankNameInput)
	//
	// GetBankName gets the Bank Name for the requested Routing Number
	getBankName: function(getBankNameRequest, config) {
		if (typeof getBankNameRequest.serializeBinary !== 'function') {
			getBankNameRequest = this.builders.buildGetBankNameRequest(getBankNameRequest);
		}
		return _doFunc(_sourceURL + mintProxyPath + 'GetBankName', getBankNameRequest.serializeBinary(), config).then(function(resp) {
			var result = mintProxy.GetBankNameResponse.toObject(false, mintProxy.GetBankNameResponse.deserializeBinary(resp));
			return result;
		});
	},

	// =============================================================================
	// Constructors
	// =============================================================================

	builders: {
		// buildCacheValueInput returns a mintProxy.CacheValueInput object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.CacheValueInput Object Properties:
		//      id (string)
		//      value (string)
		//
		buildCacheValueInput: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = findImportConstructor('mint/payment_method.proto', 'CacheValueInput');
			return setRequest(msg, objClone);
		},
		// buildCacheValueRequest returns a mintProxy.CacheValueRequest object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.CacheValueRequest Object Properties:
		//      args (mint.CacheValueInput)
		//
		buildCacheValueRequest: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = new mintProxy.CacheValueRequest();
			objClone.args = this.buildCacheValueInput(obj.args);
			return setRequest(msg, objClone);
		},
		// buildGetAddPaymentMethodFormRequest returns a mintProxy.GetAddPaymentMethodFormRequest object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.GetAddPaymentMethodFormRequest Object Properties:
		//      siteId (string)
		//      customerId (wrappers.StringValue)
		//      originalPaymentMethodId (wrappers.StringValue)
		//      includeCreditCardLogos (bool)
		//      includeCheckingHelpers (bool)
		//      includeFieldNamesInValidation (bool)
		//      requireBillingFields (bool)
		//      includeBlankState (bool)
		//      requiredClass (string)
		//      errorValidationClass (string)
		//      successValidationClass (string)
		//      validateOnSubmitOnly (bool)
		//      defaultExpirationDate (bool)
		//      addedByStaff (bool)
		//      addedByGuest (bool)
		//      phoneNumber (string)
		//      isTextPaymentMethod (bool)
		//      autoStyleAccountNumber (bool)
		//      useVue (bool)
		//      allowAch (bool)
		//      allowCreditCard (bool)
		//      debitOnly (bool)
		//      merchantId (string)
		//
		buildGetAddPaymentMethodFormRequest: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = new mintProxy.GetAddPaymentMethodFormRequest();
			if (typeof obj.customerId !== 'undefined') {
				objClone.customerId = this.buildStringValue(obj.customerId);
			}

			if (typeof obj.originalPaymentMethodId !== 'undefined') {
				objClone.originalPaymentMethodId = this.buildStringValue(obj.originalPaymentMethodId);
			}

			return setRequest(msg, objClone);
		},
		// buildGetAddPaymentMethodFormVariablesRequest returns a mintProxy.GetAddPaymentMethodFormVariablesRequest object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.GetAddPaymentMethodFormVariablesRequest Object Properties:
		//      siteId (string)
		//      merchantId (string)
		//
		buildGetAddPaymentMethodFormVariablesRequest: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = new mintProxy.GetAddPaymentMethodFormVariablesRequest();
			return setRequest(msg, objClone);
		},
		// buildGetBankNameInput returns a mintProxy.GetBankNameInput object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.GetBankNameInput Object Properties:
		//      id (string)
		//
		buildGetBankNameInput: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = findImportConstructor('mint/payment_method.proto', 'GetBankNameInput');
			return setRequest(msg, objClone);
		},
		// buildGetBankNameRequest returns a mintProxy.GetBankNameRequest object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// mintProxy.GetBankNameRequest Object Properties:
		//      args (mint.GetBankNameInput)
		//
		buildGetBankNameRequest: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			var msg = new mintProxy.GetBankNameRequest();
			objClone.args = this.buildGetBankNameInput(obj.args);
			return setRequest(msg, objClone);
		},
		// buildStringValue returns a google_protobuf_wrappers_pb.StringValue object built from a javascript value
		buildStringValue: function(value) {
			if (typeof value === 'undefined' || value == null || value == '') {
				return;
			}
			return this.buildStringValuez({ value });
		},

		// buildStringValuez returns a google_protobuf_wrappers_pb.StringValue object built from a conventionally identical object (useful for Two-Way Databinding)
		//
		// google_protobuf_wrappers_pb.StringValue Object Properties:
		//      value (string)
		//
		buildStringValuez: function(obj) {
			if (!obj) {
				return;
			}
			if (typeof obj !== 'object') {
				throw 'obj must be an Object';
			}
			var objClone = _cloneDeep(obj);
			return setRequest(new google_protobuf_wrappers_pb.StringValue(), objClone);
		}
	}
};

// =============================================================================
// Imports
// =============================================================================
var findImportConstructor = function(parentName, objectName) {
	if (parentName === 'mint/payment_method.proto') {
		return new mint_payment_method_pb[objectName]();
	}

	throw 'No Import Constructor Found';
};

// =============================================================================
// Helpers
// =============================================================================

// setRequest takes a protobuf js object as the request and a conventionally equivalent obj
// It loops through all set functions on the protobuf object and gets the corresponding value out of the obj
// Returning a valid protobuf object suitable to be used in the API calls
var setRequest = function(req, obj) {
	for (var prop in req) {
		if (prop.startsWith('set')) {
			var fieldName = prop.substr(3);
			fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

			if (typeof obj[fieldName] !== 'undefined') {
				req[prop](obj[fieldName]);
			}
		}
	}

	return req;
};
