import pledgeService from '../services/pledgeService';
import moment from 'moment';
import { formatDollarsToCents, formatCentsToDollars } from '@/helpers/formatters';
export function initState() {
	return {
		pledge: {
			frequency: null,
			startDate: null,
			stopDate: null,
			isNewProfile: false,
			hasRecurringGift: false
		}
	};
}

export default {
	state: initState(),
	savePledge(args) {
		return pledgeService.savePledge(args).then(function(response) {
			_REALM.STORE.pledgeStore.state.pledge = response;
			_REALM.STORE.pledgeStore.state.pledge.startDate = moment(response.startDate);
			_REALM.STORE.pledgeStore.state.pledge.stopDate = moment(response.stopDate);
			_REALM.STORE.pledgeStore.state.pledge.amount = formatDollarsToCents(response.amount);
			return _REALM.STORE.pledgeStore.state.pledge;
		});
	},
	addPledge(args) {
		return pledgeService.addPledge(args).then(function(response) {
			let donorHasAccount = _REALM.STORE.pledgeStore.state.pledge.donorHasAccount;
			_REALM.STORE.pledgeStore.state.pledge = response;

			_REALM.STORE.pledgeStore.state.pledge.donorHasAccount = donorHasAccount;
			_REALM.STORE.pledgeStore.state.pledge.startDate = moment(response.startDate);
			_REALM.STORE.pledgeStore.state.pledge.stopDate = moment(response.stopDate);
			_REALM.STORE.pledgeStore.state.pledge.amount = formatDollarsToCents(response.amount);
			_REALM.STORE.pledgeStore.state.pledge.isNewProfile = response.isNewProfile;
			_REALM.STORE.pledgeStore.state.pledge.hasRecurringGift = response.hasRecurringGift;

			if (!_REALM.STORE.accountStore.isLoggedIn()) {
				_REALM.STORE.transactionStore.state.transaction.individualId = response.individualId;
			}

			return _REALM.STORE.pledgeStore.state.pledge;
		});
	},
	getRecurrenceCount(args) {
		return pledgeService.getRecurrenceCount(args);
	},
	buildAddPledgeArgs() {
		var isMobile = _REALM.TOOLS.browser().mobile;
		var isText = false;
		var isEmbed = _REALM.STORE.pledgeFormStore.isPledgeFormEmbed();

		return {
			Id: null,
			CampaignId: _REALM.STORE.pledgeFormStore.state.pledgeForm.campaignId,
			IndividualId: _REALM.STORE.pledgeFormStore.state.pledgeAccount.individualId,
			Email: _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail,
			Label: _REALM.STORE.pledgeFormStore.state.pledgeAccount.label,
			Amount: formatCentsToDollars(_REALM.STORE.pledgeStore.state.pledge.amount),
			Frequency: _REALM.STORE.pledgeStore.state.pledge.frequency,
			StartDate: _REALM.STORE.pledgeStore.state.pledge.startDate.format('MM/DD/YYYY'),
			StopDate: _REALM.STORE.pledgeStore.state.pledge.stopDate.format('MM/DD/YYYY'),
			Source: isMobile ? 'Mobile' : isText ? 'Text' : isEmbed ? 'Embed' : 'Online',
			FundId: _REALM.STORE.pledgeFormStore.state.pledgeForm.fundId
		};
	}
};
