<template>
	<v-snackbar v-if="active" v-model="active" :timeout="timeout" :color="computedColor" :multi-line="true" top center>
		<v-icon v-if="icon.length > 0" dark left>{{ icon }}</v-icon>
		{{ computedText }}
		<template v-slot:action="{ attrs }">
			<v-btn text icon v-bind="attrs" @click="active = false">
				<v-icon>close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>
<script>
import vuetify from '@/plugins/vuetify';
export default {
	vuetify: vuetify,
	data() {
		return {
			active: false,
			text: null,
			icon: null,
			color: null,
			timeout: null
		};
	},
	computed: {
		computedText() {
			return typeof this.text === 'string' && this.text.length > 0 ? this.text.charAt(0).toUpperCase() + this.text.slice(1) : '';
		},
		computedColor() {
			return _REALM.TOOLS.findColor(this.color);
		}
	},

	methods: {
		show(options = {}) {
			if (this.active) {
				this.close();
				this.$nextTick(() => this.show(options));
				return;
			}

			Object.keys(options).forEach(field => (this[field] = options[field]));
			this.active = true;

			_REALM.TOOLS.scrollTopIframe();
		},

		close() {
			this.active = false;
		}
	}
};
</script>
