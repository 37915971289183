<template>
	<v-tooltip v-model="show" top>
		<template v-slot:activator="{ on, attrs }">
			<span icon v-bind="attrs" v-on="on">
				<v-icon color="primary">
					info
				</v-icon>
			</span>
		</template>
		<span><slot></slot></span>
	</v-tooltip>
</template>

<script>
export default {
	data() {
		return {
			show: false
		};
	}
};
</script>
