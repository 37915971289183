import recurrenceService from '@/store/services/recurrenceService';

export function initState() {
	return {
		allRecurrences: [],
		filteredRecurrences: [],
		recurrenceNotifications: [],
		recurrenceFilters: {
			individualId: null,
			statuses: getDefaultStatusFilters()
		}
	};
}

function getDefaultStatusFilters() {
	return {
		active: true,
		onHold: true,
		complete: false,
		deactivated: false
	};
}

export default {
	state: initState(),
	async getRecurrences() {
		const args = {
			individualId: _REALM.STORE.recurrenceStore.state.recurrenceFilters.individualId,
			recurrenceStatus: ['active', 'onHold', 'complete', 'deactivated']
		};

		_REALM.STORE.recurrenceStore.state.allRecurrences = await recurrenceService.getRecurrences(args);
		_REALM.STORE.recurrenceStore.updateFilteredRecurrences();
	},
	getRecurrence(recurrenceId) {
		const recurrence = _REALM.STORE.recurrenceStore.state.allRecurrences.find(r => r.recurrenceId === recurrenceId);
		return recurrence || null;
	},
	async getRecurrenceNotifications() {
		const notifications = _REALM.STORE.recurrenceStore.state.recurrenceFilters.individualId === _REALM.STORE.accountStore.state.authAccount.individualId ? await recurrenceService.getRecurrenceNotifications() : [];

		_REALM.STORE.recurrenceStore.state.recurrenceNotifications = notifications;
		return notifications;
	},
	deleteRecurrenceNotifications(notification) {
		_REALM.STORE.recurrenceStore.state.recurrenceNotifications = _REALM.STORE.recurrenceStore.state.recurrenceNotifications.filter(n => !Object.is(n, notification));
		return recurrenceService.deleteRecurrenceNotifications(notification.id);
	},
	getRecurrenceFrequencyText(recurrence) {
		switch (recurrence.recurrenceType) {
			case 'Once':
				return 'One time';

			case 'Weekly':
				return recurrence.recurrenceData.everyOther === 'true' ? 'Every two weeks' : 'Once a week';

			case 'Monthly':
				if (recurrence.recurrenceData.every3Months === 'true') {
					return 'Every three months';
				} else if (recurrence.recurrenceData.day2) {
					return 'Twice a month';
				}

				return 'Once a month';

			default:
				return '';
		}
	},
	applyStatusFilters(statusFiltersObj) {
		const statusFilter = _REALM.STORE.recurrenceStore.state.recurrenceFilters.statuses;
		for (const [key, value] of Object.entries(statusFiltersObj)) {
			statusFilter[key] = value;
		}

		_REALM.STORE.recurrenceStore.updateFilteredRecurrences();
	},
	resetStatusFilters() {
		const defaultStatusFilters = getDefaultStatusFilters();
		_REALM.STORE.recurrenceStore.applyStatusFilters(defaultStatusFilters);
	},
	getDefaultStatusFilters() {
		return getDefaultStatusFilters();
	},
	updateFilteredRecurrences() {
		const filterStatuses = [];
		for (const [status, isIncluded] of Object.entries(_REALM.STORE.recurrenceStore.state.recurrenceFilters.statuses)) {
			if (isIncluded) {
				filterStatuses.push(status.toLowerCase());
			}
		}

		_REALM.STORE.recurrenceStore.state.filteredRecurrences = [];
		for (const recurrence of _REALM.STORE.recurrenceStore.state.allRecurrences) {
			if (filterStatuses.includes(recurrence.status.toLowerCase())) {
				_REALM.STORE.recurrenceStore.state.filteredRecurrences.push(recurrence);
			}
		}
	},
	async placeRecurrenceOnHold(recurrenceId, reason) {
		await recurrenceService.placeRecurrenceOnHold(recurrenceId, reason);
		await _REALM.STORE.recurrenceStore.getRecurrences();
	},
	async cancelRecurrence(recurrenceId, reason) {
		await recurrenceService.cancelRecurrence(recurrenceId, reason);
		await _REALM.STORE.recurrenceStore.getRecurrences();
	}
};
