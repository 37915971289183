<style lang="scss">
@import '@/scss/variables.scss';

.cr-slider::before {
	position: absolute;
	top: -2px;
	left: 0;
	font-weight: bold;
	letter-spacing: 1px;
	content: 'SHAPE';
}

.croppie-container {
	height: auto;
	.cr-slider::before {
		content: 'ZOOM';
	}
}

.cr-slider-wrap {
	position: relative;
	width: 410px !important;
	margin-bottom: 5px;
}

.image-crop-wrapper {
	$preview-width: 100px;

	padding: 5px;
	.image-preview-wrapper {
		position: relative;
		width: $preview-width;
		height: $preview-width;
		margin: auto;
		cursor: pointer;

		.image-preview {
			width: $preview-width;
			height: $preview-width;
		}

		.not-found {
			background-color: #cdcdcd;
			border-radius: 50%;
		}

		.image-actions {
			&.image-exist {
				display: none;
			}

			position: absolute;
			top: 50%;
			left: 50%;
			width: $preview-width;
			height: $preview-width;
			text-align: center;
			transform: translate(-50%, -50%);
			.actions {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 80px;
				transform: translate(-50%, -50%);
			}
			.v-icon {
				font-size: 40px;
				color: $white;
			}
		}

		&:hover .image-actions {
			display: block !important;
			background-color: rgba(0, 0, 0, 0.4) !important;
			outline: dashed $primary;
		}
	}
}
</style>

<template>
	<div class="image-crop-wrapper">
		<div class="image-preview-wrapper">
			<img v-if="!previewImageError && imagePath" :style="previewImgStyles" :src="imagePath" class="image-preview" @loaded="imagePreviewLoaded" @error="imagePreviewError" />
			<div v-if="previewImageError" class="image-preview not-found" @click="showImageCropDialog"></div>
			<div :class="{ 'image-exist': !previewImageError }" class="image-actions" @click="showImageCropDialog">
				<div class="actions">
					<v-icon class="image-edit">add_a_photo</v-icon>
					<v-icon v-if="!previewImageError" @click="deleteImage">delete</v-icon>
				</div>
			</div>
		</div>
		<input ref="newImage" type="file" class="hidden" accept="image/jpg, image/jpeg, image/png" @change="onImagePicked" />

		<r-dialog v-model="showDialog" max-width="800px">
			<template slot="title">
				Choose your photo
			</template>
			<v-layout wrap>
				<v-flex xs12>
					<vue-croppie v-if="showDialog" ref="croppieRef" :viewport="{ height: cropHeight, width: cropWidth, type: cropType }" :boundary="{ width: cropWidth + 200, height: cropHeight + 200 }" :enableResize="false" :croppieInitialized="onCroppieInit" class="initial-height"></vue-croppie>
					<div class="cr-slider-wrap text-center mx-auto">
						<input v-model="borderRadius" class="cr-slider" type="range" step="0.01" min="0" max="50" />
					</div>
				</v-flex>
			</v-layout>
			<template slot="actions">
				<v-btn @click="openFilePicker">
					<v-icon>add_a_photo</v-icon>
					&nbsp;Choose image
				</v-btn>
				<!--<r-btn v-if="!previewImageError" color="warning" @click="deleteImage">
					Delete
				</r-btn>-->

				<v-spacer></v-spacer>
				<r-btn color="default" @click="showDialog = false">Cancel</r-btn>
				<r-btn @click="cropImage">
					Save
				</r-btn>
			</template>
		</r-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';
Vue.use(VueCroppie);

export default {
	props: {
		cropType: {
			type: String,
			default: 'square',
			validator(value) {
				return ['square', 'circle'].includes(value);
			}
		},
		cropWidth: {
			type: Number,
			required: true
		},
		cropHeight: {
			type: Number,
			required: true
		},
		imageId: {
			type: String
		},
		imageType: {
			type: Number,
			required: true,
			validator(value) {
				return Object.keys(_REALM.STORE.mediaStore.ImageTypes)
					.map(k => _REALM.STORE.mediaStore.ImageTypes[k])
					.includes(value);
			}
		},
		initialBorderRadius: {
			type: Number,
			required: false,
			validator: value => value >= 0 && value <= 50,
			default: 50
		}
	},
	data() {
		return {
			croppieImageSet: false,
			showDialog: false,
			previewImageError: false,
			mediaImageType: this.imageType,
			imagePath: null,
			croppieViewport: null,
			borderRadius: this.initialBorderRadius
		};
	},
	computed: {
		previewImgStyles() {
			return {
				borderRadius: this.initialBorderRadius + '%'
			};
		}
	},
	watch: {
		borderRadius(val) {
			if (this.croppieViewport) {
				this.croppieViewport.style.borderRadius = val + '%';
			}
		}
	},
	created() {
		this.getImageURL();
	},
	methods: {
		refreshImage() {
			_REALM.EVENT.emit(this.imageId + '-refresh');
			this.previewImageError = false;
			if (this.$refs.croppieRef) {
				this.$refs.croppieRef.refresh();
			}

			this.getImageURL();
		},
		getImageURL() {
			this.imagePath = this.imageId ? _REALM.STORE.mediaStore.getImageURL(this.mediaImageType, this.imageId) : null;
		},
		imagePreviewLoaded() {
			this.previewImageError = false;
		},
		imagePreviewError() {
			this.previewImageError = true;
		},
		setCroppie(image) {
			this.croppieImageSet = true;
			this.$refs.croppieRef.bind({
				url: image
			});
		},
		cropImage() {
			if (!this.croppieImageSet) {
				return _REALM.TOAST.error('Please select an image.');
			}

			var options = {
				format: _REALM.STORE.mediaStore.getImageExtension(this.mediaImageType),
				circle: this.cropType === 'circle',
				type: 'blob'
			};

			this.$refs.croppieRef.result(options, output => this.uploadImage(output));
		},
		openFilePicker() {
			this.$refs.newImage.click();
		},
		showImageCropDialog(event) {
			if (event.target.innerHTML === 'delete') {
				return;
			}

			this.croppieImageSet = false;

			this.openFilePicker();
			//Always show new image picker since there is a bug in editing of current image
			//if (this.previewImageError) {
			//	this.openFilePicker();
			//} else {
			//	this.showDialog = true;
			//	this.$nextTick(() => this.setCroppie(this.imagePath));
			//}
		},
		resetFileInput() {
			var input = this.$refs.newImage;
			input.type = 'text';
			input.type = 'file';
		},
		onImagePicked(e) {
			var self = this;
			_REALM.LOADER.show();
			var files = e.target.files;
			if (files && files[0]) {
				var fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener('load', () => {
					self.showDialog = true;
					setTimeout(function() {
						self.setCroppie(fr.result);
						_REALM.LOADER.hide();
						self.resetFileInput();
					}, 1000);
				});
			}
		},
		uploadImage(imageBlob) {
			var self = this;
			_REALM.LOADER.show();
			_REALM.STORE.mediaStore
				.uploadImage(this.imageId, this.mediaImageType, imageBlob)
				.then(function() {
					self.refreshImage();
					self.showDialog = false;
					self.$emit('imageSaved', self.$data);
				})
				.finally(function() {
					_REALM.LOADER.hide();
				});
		},
		deleteImage() {
			var self = this;
			_REALM.LOADER.show();
			_REALM.STORE.mediaStore
				.deleteImage(self.mediaImageType, self.imageId)
				.then(function() {
					self.refreshImage();
				})
				.finally(function() {
					_REALM.LOADER.hide();
					self.$emit('imageDeleted');
				});
		},
		onCroppieInit() {
			this.croppieViewport = this.$refs.croppieRef.$el.querySelector('.cr-viewport');
			if (this.croppieViewport) {
				this.croppieViewport.style.borderRadius = this.borderRadius + '%';
			}
		}
	}
};
</script>
