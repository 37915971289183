<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on }">
			<v-icon v-on="on">more_horiz</v-icon>
		</template>
		<v-list>
			<slot></slot>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	props: {
		item: Object
	}
};
</script>
