import mediaService from '../services/mediaService';

import md5 from 'js-md5';

var getHash = function(imageType, fileName, options) {
	var hash1 = md5(_REALM.TOOLS.noDashGuid(_REALM.STORE.siteStore.state.site.siteId) + imageType);
	var hash2 = md5(options + fileName);
	return md5(hash1 + hash2);
};

// Types of Options:
// x          no resizing; you get the original image
// 0x0        no resizing; you get the original image
// 200x	200   pixels wide, proportional height
// x100	100   pixels tall, proportional width
// 100x150    100 by 150 pixels, cropping as needed
// 0.15x      15% original width, proportional height
// 100        100 pixels square, cropping as needed
// 150,fit    scale to fit 150 pixels square, no cropping
// 100,r90    100 pixels square, rotated 90 degrees
// 100,fv,fh  100 pixels square, flipped horizontal and vertical
// 200x,q80   200 pixels wide, proportional height, 80% quality
var getOptions = function(width, height) {
	if (width && !height) {
		return width + 'x';
	}

	if (!width && height) {
		return 'x' + height;
	}

	if (width && height) {
		return width + 'x' + height;
	}

	return '0x0';
};

export function initState() {
	return {};
}

export default {
	state: initState(),
	ImageTypes: {
		LOGO: 0,
		GIVING_FORM: 21
	},
	getImageURL(imageType, imageId, width, height) {
		imageId = _REALM.TOOLS.noDashGuid(imageId);

		var redirect = 1; //imageType == this.ImageTypes.LOGO ? 0 : 1; // if not found - 0 = show default image, 1 = 404 //Temporary use Logo until we have giving form images
		var options = getOptions(width, height);
		var fileName = imageId + '.' + this.getImageExtension(imageType);
		var hash = getHash(imageType, fileName, options);

		//[EP]/image/{RE}/{HA}/{SI}/{IT}/{OP}/{ID}.(ext)
		var url = _REALM.CONFIG.MediaHost + '/image';
		url += '/' + redirect;
		url += '/' + hash;
		url += '/' + _REALM.TOOLS.noDashGuid(_REALM.STORE.siteStore.state.site.siteId);
		url += '/' + imageType;
		url += '/' + options;
		url += '/' + fileName;

		return url + '?date=' + new Date().getTime();
	},
	deleteImage(imageType, imageId) {
		var options = getOptions();
		var fileName = _REALM.TOOLS.noDashGuid(imageId) + '.' + this.getImageExtension(imageType);
		var hash = getHash(imageType, fileName, options);
		return mediaService.deleteImage(fileName, options, hash, imageType);
	},
	getImageExtension() {
		return 'png';
	},
	getMediaServiceToken() {
		return mediaService.getMediaServiceToken();
	},
	uploadImage(imageId, mediaImageType, imageBlob) {
		return mediaService.uploadImage(imageId, mediaImageType, imageBlob);
	}
};
