var _initialized = false,
	_typeCallback = [];

var receiveMessageCallback = function(message) {
	var messageData = message.data;
	if (messageData && messageData.type && _typeCallback[messageData.type]) {
		var callbackData = messageData.data ? JSON.parse(messageData.data) : null;
		_typeCallback[messageData.type].callback(callbackData);
	}
};

export function receiveMessage(type, callback) {
	if (!_initialized) {
		window.addEventListener('message', receiveMessageCallback);
		_initialized = true;
	}

	_typeCallback[type] = {
		callback: callback
	};
}

export function sendMessage(type, message) {
	var data = {
		type: type,
		data: JSON.stringify(message)
	};

	parent.postMessage(data, '*');
}

export default { receiveMessage, sendMessage };
