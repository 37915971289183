<style lang="scss">
@import '@/scss/variables.scss';

.loader-wrapper {
	text-align: center;
	.loader {
		margin: 10px;
	}
	.loader > div {
		display: inline-block;
		width: 30px;
		height: 30px;
		margin: 0 16px;
		background-color: rgba(0, 0, 0, 0.54);
		background-color: $realm-blue;
		border-radius: 100%;
		animation-name: blink;
		animation-duration: 1.4s;
		animation-iteration-count: infinite;
		animation-fill-mode: both;
	}

	.loader .bounce2 {
		animation-delay: 0.2s;
	}

	.loader .bounce3 {
		animation-delay: 0.4s;
	}

	@keyframes blink {
		0% {
			opacity: 0.3;
		}
		20% {
			opacity: 1;
		}
		100% {
			opacity: 0.3;
		}
	}

	.loading-text {
		margin-top: 16px;
		color: black;
	}
}
</style>
<template>
	<v-overlay :value="visible" class="loader-wrapper" color="#fafafa" :opacity="0.9" z-index="9999999">
		<div class="loader">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
		<h4 v-if="loadingText" class="loading-text">{{ loadingText }}</h4>
		<h6 v-if="loadingSubText" class="loading-text">{{ loadingSubText }}</h6>
	</v-overlay>
</template>
<script>
import vuetify from '@/plugins/vuetify';
export default {
	vuetify: vuetify,
	data() {
		return {
			visible: false,
			loadingText: null,
			loadingSubText: null
		};
	},
	methods: {
		show(options) {
			this.visible = true;
			if (options) {
				this.loadingText = options.text;
				this.loadingSubText = options.subText;
			}
		},
		hide() {
			this.loadingText = null;
			this.loadingSubText = null;
			this.visible = false;
			this.$destroy();
			this.$el.parentNode.removeChild(this.$el);
		}
	}
};
</script>
