export default {
	redirectToErrorPage(error) {
		_REALM.TOAST.close();

		let errorCode = 500;
		if (error.response) {
			errorCode = error.response.status;
			if (error.response.data) {
				if (error.response.data.includes('insufficient rights')) {
					errorCode = 403;
				} else if (error.response.data.includes('not found')) {
					errorCode = 404;
				}
			}
		}

		if (![400, 401, 403, 404, 500].includes(errorCode)) {
			errorCode = 500;
		}

		_REALM.VM.$router.replace({ name: errorCode.toString() });
	}
};
