import pledgeFormService from '../services/pledgeFormService';
import _truncate from 'lodash/truncate';
import cssVariables from '@/scss/variables.scss';
import moment from 'moment';

var setPledgerHasEmail = function() {
	_REALM.STORE.pledgeFormStore.state.pledgeAccount.hasEmail = _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail !== null && _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail !== undefined && _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail !== '';
};

export function initState() {
	return {
		currentDonorPage: 'PledgeIntro',
		pledgeForms: [],
		pledgeForm: {},
		pledgeAccount: {},
		hidePledgeFormContent: false
	};
}

export default {
	state: initState(),
	listPledgeForms(individualId) {
		return pledgeFormService.listPledgeForms(individualId).then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForms = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForms;
		});
	},
	staffListPledgeForms() {
		return pledgeFormService.staffListPledgeForms().then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForms = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForms;
		});
	},
	getPledgeFormBaseUrl() {
		return _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/AddPledge/';
	},
	isPreviewPledgeFormMode() {
		return _REALM.VM.$route.name === 'pledge-form';
	},
	getPledgeForm(campaignId) {
		return pledgeFormService.getPledgeForm(campaignId).then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForm = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForm;
		});
	},
	isAdminManaging() {
		return _REALM.STORE.accountStore.isLoggedIn() && _REALM.STORE.pledgeFormStore.state.pledgeAccount.individualId !== _REALM.STORE.accountStore.state.authAccount.individualId;
	},
	isTextGiver() {
		return false;
	},
	getPledgeFormByUrl(pledgeFormUrl) {
		return pledgeFormService.getPledgeFormByUrl(pledgeFormUrl).then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForm = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForm;
		});
	},
	setPledgeFormDefaults(campaign) {
		var fundName = null;

		if (campaign && campaign.fund) {
			fundName = campaign.fund.displayName ? campaign.fund.displayName : campaign.fund.name;
		}

		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'fundId', campaign ? campaign.fundId : null);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'campaignId', campaign ? campaign.id : null);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'useDarkOverlayScheme', false);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'useDarkBodyScheme', false);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'headerBackgroundColor', cssVariables['color-primary']);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'accentColor', cssVariables['color-primary']);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'font', 'modern');
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'title', fundName);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'primarySubtitle', _truncate(_REALM.STORE.siteStore.state.site.siteName, { length: 50, omission: '' }));
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'introMessage', null);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'urlPath', _REALM.TOOLS.trimWhiteSpace(fundName ? fundName.toLowerCase() : null));
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'askForOnlineGifts', _REALM.STORE.givingStore.state.settings.isGivingEnabled);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'defaultFrequency', 'Weekly');

		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'campaignStartDate', campaign ? moment(campaign.startDate) : null);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'campaignStopDate', campaign ? moment(campaign.stopDate) : null);
		_REALM.VM.$set(_REALM.STORE.pledgeFormStore.state.pledgeForm, 'logoBorderRadius', 50);
	},
	updatePledgeForm(pledgeForm) {
		return pledgeFormService.updatePledgeForm(pledgeForm).then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForm = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForm;
		});
	},
	addPledgeForm(pledgeForm) {
		return pledgeFormService.addPledgeForm(pledgeForm).then(function(response) {
			_REALM.STORE.pledgeFormStore.state.pledgeForm = response;
			return _REALM.STORE.pledgeFormStore.state.pledgeForm;
		});
	},
	setPledgeAccount() {
		if (_REALM.VM.$route.query.individualId && _REALM.VM.$route.query.individualId !== _REALM.STORE.accountStore.state.authAccount.individualId) {
			return _REALM.STORE.givingFormStore.getGivingFormIndividual(_REALM.VM.$route.query.individualId).then(function(individual) {
				_REALM.STORE.pledgeFormStore.state.pledgeAccount = individual;
				setPledgerHasEmail();
				return _REALM.STORE.pledgeFormStore.state.pledgeAccount;
			});
		} else if (_REALM.STORE.accountStore.isLoggedIn()) {
			return new Promise(function(resolve) {
				_REALM.STORE.pledgeFormStore.state.pledgeAccount = _REALM.STORE.accountStore.state.authAccount;
				setPledgerHasEmail();
				resolve(_REALM.STORE.pledgeFormStore.state.pledgeAccount);
			});
		} else {
			return new Promise(function(resolve) {
				resolve(null);
			});
		}
	},
	isPledgeFormEmbed() {
		return [STRATUS.EnumImports.PledgeSource.Embed.Name].includes(_REALM.STORE.pledgeStore.state.pledge.source);
	},
	getLogoBorderRadius() {
		const form = _REALM.STORE.pledgeFormStore.state.pledgeForm;
		return typeof form.logoBorderRadius === 'undefined' || form.logoBorderRadius === null ? 50 : form.logoBorderRadius;
	}
};
