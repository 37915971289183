export default function(str) {
	if (!(!str || !str.trim())) {
		str = str.trim();
		var lastChar = str.charAt(str.length - 1);
		if (lastChar.toLowerCase() == 's') {
			str = str.slice(0, -1);
		}
	}
	return str;
}
