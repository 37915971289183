import realmClient from '../realmClient';

// modeled after pollStatus in Reports.js
var pollStatus = function(options) {
	realmClient.post('Reports/IsComplete', { id: options.id }).then(function(resp) {
		if (resp === true) {
			if (options.beforeCallback) {
				options.beforeCallback();
				return;
			}

			var route = 'Reports/Download/' + options.id + '?';

			var urlParams = {
				attachment: options.newTab === false,
				fileType: options.fileType || 'pdf'
			};

			// TODO: Utility function to convert to query string, ie. url += $.param(urlParams);
			route += 'attachment=' + urlParams.attachment + '&fileType=' + urlParams.fileType;

			var url = _REALM.TOOLS.buildSiteLink(route);

			var message;

			// This should be at the end but window.open prevents this from being called.
			if (options.callback) {
				options.callback();
			}

			if (!options.manualOpen) {
				window.open(url, '_parent');
				message = 'Your report has finished.';
			} else {
				// TODO: Currently this displays as text not a link
				message = 'Your report is ready to <a target="_blank" href="' + url + '">download</a>.';
			}

			_REALM.TOAST.success(message);
			return;
		}

		setTimeout(function() {
			pollStatus(options);
		}, 2500);
	});
};

var getReportUrl = function(reportId, fileType) {
	var route = 'Reports/Download/' + reportId + '?' + 'attachment=true&fileType=' + (fileType || 'pdf');
	return _REALM.TOOLS.buildSiteLink(route);
};

export default {
	getSavedQueries() {
		return realmClient.post('Reports/GetSavedQueries');
	},
	pollStatus(options) {
		pollStatus(options);
	},
	getReportUrl(reportId, fileType) {
		return getReportUrl(reportId, fileType);
	}
};
