export default [
	{
		name: 'payment-methods',
		path: 'payment-methods',
		component: () => import('@/router/pages/giving/PaymentMethodsPage.vue'),
		meta: {
			title: 'Payment Methods'
		}
	},
	{
		name: 'new-payment-method',
		path: 'payment-methods/new',
		component: () => import('@/router/pages/giving/PaymentMethodPage.vue'),
		meta: {
			title: 'Add Payment Method'
		}
	},
	{
		name: 'edit-payment-method',
		path: 'payment-methods/:paymentMethodId',
		component: () => import('@/router/pages/giving/PaymentMethodPage.vue'),
		meta: {
			title: 'Edit Payment Method'
		}
	},
	{
		name: 'scheduled-gifts',
		path: 'scheduled-gifts',
		component: () => import('@/router/pages/giving/ScheduledGiftsPage.vue'),
		meta: {
			title: 'Scheduled Gifts'
		}
	}
];
