import realmClient from '../realmClient';
import moment from 'moment';

var formPaymentMethods = function(paymentMethods) {
	var paymentMethodsResponse = [];
	if (paymentMethods) {
		for (var i = 0; i < paymentMethods.length; i++) {
			var paymentMethod = paymentMethods[i];
			paymentMethod.expirationDate = moment(`${paymentMethod.expirationMonth}-25-${paymentMethod.expirationYear}`, 'MM-DD-YYYY');
			paymentMethodsResponse.push(paymentMethod);
		}
	}

	return paymentMethodsResponse;
};

export default {
	getPaymentMethod(paymentMethodId) {
		return realmClient.post('Giving/GetPaymentMethod', { paymentMethodId }).then(paymentMethod => {
			const formattedPaymentMethod = formPaymentMethods([paymentMethod]);
			return formattedPaymentMethod[0];
		});
	},
	listPaymentMethods(mintCustomerId, includeRecurrences = false, handleError = true) {
		return realmClient.post('Giving/ListPaymentMethods', { mintCustomerId, includeRecurrences }, { handleError }).then(function(paymentMethods) {
			return formPaymentMethods(paymentMethods);
		});
	},
	listPaymentMethodsByTextToken(token) {
		return realmClient.post('Giving/ListPaymentMethodsByTextToken', { token }).then(function(paymentMethods) {
			return formPaymentMethods(paymentMethods);
		});
	},
	reassignRecurringGifts(oldPaymentMethodId, newPaymentMethodId) {
		return realmClient.post('Giving/UpdateRecurringGift', { oldPaymentMethodId, newPaymentMethodId });
	},
	cancelPaymentMethodRecurrences(paymentMethodId) {
		return realmClient.post('Giving/DeleteRecurringGiftsByPaymentMethodId', { paymentMethodId });
	},
	deletePaymentMethod(paymentMethodId, individualId) {
		return realmClient.post('Giving/DeletePaymentMethod', { paymentMethodId, individualId });
	}
};
