var notFound = function(to, next) {
	next({ name: '404', params: to.params, query: to.query, replace: true });
};

var redirectToPage = function(next, params = {}, query = {}, replace = false) {
	var defaultParams = {};

	var pageName = query.page;
	delete query.page;

	params = Object.assign({}, defaultParams, params);
	next({
		name: pageName,
		params: params,
		query: query,
		replace: replace
	});
};

var goToDonorForm = function(to, next) {
	if (to.query.givingFormUrl) {
		to.params.givingFormUrl = to.query.givingFormUrl;
		delete to.query.givingFormUrl;
	}

	redirectToPage(next, to.params, to.query, true);
};

var goToDonorPledgeForm = function(to, next) {
	if (to.query.pledgeFormUrl) {
		to.params.pledgeFormUrl = to.query.pledgeFormUrl;
		delete to.query.pledgeFormUrl;
	}

	redirectToPage(next, to.params, to.query);
};

var goTo = function(to, next) {
	if (to.query.page === 'donor-form' || to.query.page === 'donor-payment-form') {
		goToDonorForm(to, next);
	}

	if (to.query.page === 'donor-pledge-form') {
		goToDonorPledgeForm(to, next);
	}
};

export default function(to, next) {
	if (!to.query.page) {
		notFound(to, next);
		return;
	}

	goTo(to, next);
}
