import analyticsService from '../services/analyticsService';

export function initState() {
	return {};
}

export default {
	state: initState(),
	getUserInformation() {
		return analyticsService.getUserInformation();
	}
};
