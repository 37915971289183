<style lang="scss">
@import '@/scss/variables.scss';
.bread-crumb-wrapper {
	a {
		text-decoration: none;
	}
}
</style>

<template>
	<v-breadcrumbs :items="links" divider="▸" class="bread-crumb-wrapper">
		<template slot="item" slot-scope="props">
			<router-link v-if="props.item.name" :to="{ name: props.item.name, params: props.item.params }" :exact="props.item.exact">{{ props.item.text }}</router-link>
			<r-link v-else-if="props.item.route" :route="props.item.route">{{ props.item.text }}</r-link>
			<span v-else>{{ props.item.text }}</span>
		</template>
	</v-breadcrumbs>
</template>
<script>
export default {
	props: {
		links: Array
	}
};
</script>
