import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const router = new Router({
	mode: 'history',
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	//https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/master/src/router/index.js#L25
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes: require('@/router/routes/routes').default
});

import * as guards from './guards';
router.beforeEach(guards.showLoader);
router.beforeEach(guards.setSiteUrl);
router.beforeEach(guards.refreshToken);
router.beforeEach(guards.setHtmlMeta);
router.beforeEach(guards.loadSite);
router.beforeEach(guards.isLoggedIn);
router.beforeEach(guards.analyticsIdentify);
router.beforeEach(guards.beforeRoute);

// When each route is finished evaluating...
router.afterEach(() => {
	_REALM.LOADER.hide();
});

export default router;
