import realmClient from '../realmClient';

export default {
	listMemorials: async function(active = true) {
		try {
			const res = await realmClient.post('Giving/ListMemorials', { showActive: active });
			return Array.isArray(res) ? res : [];
		} catch {
			return [];
		}
	}
};
