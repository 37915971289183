<style lang="scss">
@import '@/scss/variables.scss';
.btn-wrapper {
	text-transform: uppercase;
}
</style>
<template>
	<v-btn
		:icon="icon"
		:to="to"
		:color="computedColor"
		:absolute="absolute"
		:large="large"
		:href="computedHref"
		:target="target"
		:disabled="disabled"
		:block="block"
		:fab="fab"
		:small="small"
		:right="right"
		:text="text"
		:rounded="false"
		:depressed="true"
		:style="computedStyles"
		:tile="tile"
		class="btn-wrapper"
		@click="$emit('click', $event)"
	>
		<slot></slot>
	</v-btn>
</template>

<script>
import cssVariables from '@/scss/variables.scss';

export default {
	props: ['color', 'icon', 'absolute', 'to', 'large', 'href', 'target', 'disabled', 'block', 'fab', 'small', 'right', 'text', 'round', 'depressed', 'tile'],
	computed: {
		computedColor() {
			var btnColor = this.color;
			if (this.color === 'default') {
				btnColor = cssVariables['color-grey'];
			}

			if (!this.color) {
				btnColor = cssVariables['color-realmblue'];
			}

			return _REALM.TOOLS.findColor(btnColor);
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		},
		computedStyles() {
			var btnTextColor = _REALM.TOOLS.getContrastColor(this.computedColor);

			btnTextColor = _REALM.TOOLS.findColor(btnTextColor);

			var fontSize = '16px';
			if (this.$vuetify.typeOf !== undefined && this.$vuetify.breakpoint.xsOnly) {
				fontSize = '11px';
			}

			var style = { fontSize: fontSize + ' !important' };

			if (typeof this.text == 'undefined') {
				style.color = btnTextColor + ' !important';
			}

			style['text-transform'] = 'none';

			return style;
		},
		computedHref() {
			if (this.href) {
				return _REALM.TOOLS.buildSiteLink(this.href);
			}

			return null;
		}
	}
};
</script>
