<style lang="scss">
.select-list-wrapper {
	/* Mobile inputs are set to button to prevent keyboard. This aligns the value to the left and not centered */
	input[type='button'] {
		line-height: 16px;
		text-align: left;
	}
}
</style>
<template>
	<div v-if="computedItems && computedItems.length > 0" class="select-list-wrapper">
		<v-autocomplete
			ref="selectList"
			:items="computedItems"
			:clearable="clearable"
			:label="label"
			:item-text="convertOptionValues"
			:filter="optionsFilter"
			:value="computedValue"
			:rules="computedRules"
			:required="required"
			:append-icon="appendIcon"
			:placeholder="placeholder"
			:disabled="disabled"
			:outlined="outlined"
			:dense="dense"
			:hide-details="hideDetails"
			autocomplete="off"
			:type="computedType"
			@input="$emit('input', $event)"
		>
			<slot></slot>
			<template slot="item" slot-scope="data">
				<v-list-item-content>
					<v-list-item-title v-text="data.item.text"></v-list-item-title>
					<v-list-item-subtitle v-if="data.item.subText" v-text="data.item.subText"></v-list-item-subtitle>
				</v-list-item-content>
			</template>
			<slot slot="append-outer">
				<slot name="append-outer"></slot>
			</slot>
		</v-autocomplete>
		<input v-model="autofillInput" :autocomplete="autocomplete" :name="name" tabIndex="-1" type="text" class="hidden-off-screen" />
	</div>
</template>

<script>
import states from './assets/states';
import countries from './assets/countries';
import provinces from './assets/provinces';
var _find = require('lodash/find');
var _lowerCase = require('lodash/lowerCase');

export default {
	props: {
		type: String,
		placeholder: String,
		value: [String, Number],
		rules: Array,
		label: {
			type: String,
			required: true
		},
		required: Boolean,
		options: Array,
		appendIcon: String,
		autocomplete: String,
		name: String,
		clearable: Boolean,
		disabled: Boolean,
		outlined: String,
		dense: String,
		hideDetails: [Boolean, String]
	},
	data() {
		return {
			autofillInput: null
		};
	},
	computed: {
		computedValue() {
			return this.value;
		},
		computedRules() {
			var computedRules = this.rules || [];
			if (this.required) {
				computedRules.push(v => (v && v.toString().length > 0 && this.computedValues.includes(v)) || `${this.label} is required`);
			}

			return computedRules;
		},
		computedType() {
			//Prevent keyboard from opening on mobile devices
			return _REALM.TOOLS.browser().mobile ? 'button' : 'text';
		},
		computedItems() {
			var items;
			switch (this.type) {
				case 'states':
					items = states;
					break;
				case 'countries':
					items = countries;
					break;
				case 'provinces':
					items = provinces;
					break;
				default:
					items = this.options;
			}

			return items;
		},
		computedValues() {
			var values = [];
			for (var i = 0; i < this.computedItems.length; i++) {
				values.push(this.computedItems[i].value);
			}

			return values;
		}
	},
	watch: {
		autofillInput(val) {
			var searchText = _lowerCase(val);
			var match = _find(this.computedItems, function(o) {
				return _lowerCase(o.value) === searchText || _lowerCase(o.text) === searchText;
			});

			if (match) {
				this.$emit('input', match.value);
			}
		}
	},
	methods: {
		convertOptionValues(option) {
			if (option.text === 'NOT_SET') {
				return '';
			}

			return option.text;
		},
		optionsFilter(item, searchText) {
			searchText = _lowerCase(searchText);
			return _lowerCase(item.value) === searchText || _lowerCase(item.text).includes(searchText) || _lowerCase(item.subText).includes(searchText);
		}
	}
};
</script>
