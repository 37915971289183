import Vue from 'vue';

var stores = require.context('@/store/modules', false, /\.js$/);

var modules = {};
stores.keys().forEach(function(key) {
	var fileName = key.replace(/^\.\//, '').replace(/\.\w+$/, '');

	var store = stores(key).default;
	store.resetState = function() {
		if (typeof stores(key).initState !== 'function') {
			console.error(`${fileName} missing initState reset function`);
			return;
		}

		var reset = stores(key).initState();
		for (var s in stores(key).default.state) {
			Vue.set(stores(key).default.state, s, reset[s]);
		}
	};

	modules[fileName] = store;
});

var cachedValues = {};

modules.STATE = {
	reset() {
		for (var key in modules) {
			if (modules[key] && typeof modules[key].resetState === 'function') {
				modules[key].resetState();
			}
		}
	}
};

modules.CACHE = {
	set(key, value, ttlMilliseconds = 10000) {
		if (key) {
			cachedValues[key] = {
				value: value,
				expiresAt: new Date().getTime() + ttlMilliseconds / 1 //Divide by one to force type
			};
		}

		return value;
	},
	verify(key) {
		return new Promise(function(resolve, reject) {
			if (key && cachedValues[key] && cachedValues[key].value && cachedValues[key].expiresAt > new Date().getTime()) {
				resolve(cachedValues[key].value);
			}

			return reject();
		});
	}
};

export default modules;
