<style lang="scss">
.date-picker-text-field {
	input,
	.v-input__slot {
		cursor: pointer !important;
	}
}
</style>

<template>
	<div @click="onUnhandledTextFieldClick">
		<v-menu v-if="!isMobile" v-model="datePickerMenuOpen" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
			<template v-slot:activator="{ on }">
				<v-text-field
					ref="textField"
					v-model="dateFormatted"
					class="date-picker-text-field"
					autocomplete="off"
					:rules="computedRules"
					:label="label"
					:outlined="outlined"
					:disabled="disabled"
					required
					prepend-inner-icon="event"
					:append-icon="textAppendIcon"
					@blur="parseDate"
					v-on="on"
				></v-text-field>
			</template>
			<v-date-picker v-if="!disabled" v-model="dateValue" color="primary" no-title scrollable @change="datePickerMenuOpen = false"></v-date-picker>
		</v-menu>

		<v-dialog v-if="isMobile" ref="dialog" v-model="datePickerMenuOpen" persistent width="290px">
			<template v-slot:activator="{ on }">
				<v-text-field ref="textField" v-model="dateFormatted" class="date-picker-text-field" :rules="computedRules" :label="label" :outlined="outlined" :disabled="disabled" required append-icon="event" @blur="parseDate" v-on="on"></v-text-field>
			</template>
			<v-date-picker v-if="!disabled" v-model="dateValue" scrollable color="primary" no-title @change="datePickerMenuOpen = false"></v-date-picker>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';
export default {
	props: {
		value: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null
		},
		required: {
			type: String
		},
		label: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean
		},
		outlined: String
	},
	data() {
		return {
			dateFormatted: null,
			dateValue: null,
			datePickerMenuOpen: false
		};
	},
	computed: {
		textAppendIcon() {
			return this.datePickerMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down';
		},
		isMobile() {
			return _REALM.TOOLS.browser().mobile;
		},
		computedRules: function() {
			var computedRules = this.rules || [];

			if (this.required != undefined) {
				computedRules.push(v => (v && v.toString().length > 0) || `${this.label} is required`);
			}

			return computedRules;
		}
	},
	watch: {
		dateValue(newDateValue) {
			this.formatDate(newDateValue);
		}
	},
	created() {
		this.setDatePickerInitialValue();
	},
	methods: {
		setDatePickerInitialValue() {
			if (this.value && this.value.isValid()) {
				this.dateValue = this.value.format('YYYY-MM-DD');
			}
		},
		formatDate(dateValue) {
			var momentDate = moment(dateValue);

			if (!momentDate.isValid()) {
				return;
			}

			if (momentDate && momentDate.isSame(moment().startOf('day'), 'd')) {
				this.dateFormatted = 'Today';
			} else {
				var format = _REALM.TOOLS.browser().mobile ? 'M/D/YY' : 'M/D/YYYY';
				this.dateFormatted = momentDate.format(format);
			}

			this.dateValueChanged();
		},
		parseDate() {
			var tempDateValue = this.dateFormatted === 'Today' ? moment() : moment(this.dateFormatted, 'MM/DD/YYYY');

			if (tempDateValue.isValid()) {
				this.dateValue = tempDateValue.format('YYYY-MM-DD');
				this.dateValueChanged();
			}
		},
		dateValueChanged() {
			this.$emit(
				'input',
				moment(this.dateValue)
					.utc()
					.startOf('day')
			);
		},
		onUnhandledTextFieldClick($event) {
			var textField = this.$refs.textField;
			if (!textField) {
				return;
			}

			var clickedTextField = textField.$refs['input-slot'] && $event.path.includes(textField.$refs['input-slot']);
			var clickedInputElement = textField.$refs['input'] && $event.path.includes(textField.$refs['input']);
			if (clickedTextField && !clickedInputElement) {
				this.datePickerMenuOpen = !this.datePickerMenuOpen;
			}
		}
	}
};
</script>
