import _find from 'lodash/find';
import { depositAccountNameFormatter } from '@/helpers/formatters';

export default function(depositAccountId) {
	if (_REALM.STORE.givingStore.state.settings.givingDepositAccounts.length === 0) {
		console.error('No deposit accounts loaded or exist');
	}

	var depositAccount = _find(_REALM.STORE.givingStore.state.settings.givingDepositAccounts, { depositAccountId });
	return depositAccountNameFormatter(depositAccount);
}
