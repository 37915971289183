import givingService from '../services/givingService';

const cacheToken = `settings.cache`;

export function initState() {
	return {
		settings: {
			givingNumberFieldNamePlural: 'Numbers'
		}
	};
}

export default {
	state: initState(),
	settings() {
		return _REALM.STORE.CACHE.verify(cacheToken).catch(function() {
			return givingService.settings().then(function(settings) {
				_REALM.STORE.givingStore.state.settings = _REALM.STORE.CACHE.set(cacheToken, settings);
				return _REALM.STORE.givingStore.state.settings;
			});
		});
	},
	resetCache(settings) {
		_REALM.STORE.givingStore.state.settings = _REALM.STORE.CACHE.set(cacheToken, settings);
		return _REALM.STORE.givingStore.state.settings;
	},
	givingNumberName() {
		var givingNumberFieldName = _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		return _REALM.TOOLS.isNullOrEmpty(givingNumberFieldName) ? 'Numbers' : givingNumberFieldName;
	}
};
