import Vue from 'vue';
import ConfirmDialog from './ConfirmDialog.vue';

let confirmDialogComponent = null;

function createConfirmDialogComponent() {
	const component = new Vue(ConfirmDialog);
	document.getElementById('app').appendChild(component.$mount().$el);
	return component;
}

function getConfirmDialogComponent() {
	if (!confirmDialogComponent) {
		confirmDialogComponent = createConfirmDialogComponent();
	}

	return confirmDialogComponent;
}

export default function confirm(message, yesCallback, noCallback) {
	getConfirmDialogComponent().confirm(message, yesCallback, noCallback);
}
