import axios from 'axios';

var buildUrl = function(url) {
	if (url.includes(_REALM.CONFIG.StratusHost)) {
		return url;
	}

	return _REALM.CONFIG.StratusHost + '/' + _REALM.CONFIG.siteUrl + '/' + url;
};

var buildConfig = function(config) {
	var defaultConfig = {
		handleError: true
	};

	return { ...defaultConfig, ...config };
};

var handleResponse = function(resp) {
	//Unauthorized request. Redirect to signin
	if (resp && resp.request && resp.request.responseURL && resp.request.responseURL.length > 0 && resp.request.responseURL.includes('SignIn?ReturnUrl=')) {
		var redirectUrl = resp.request.responseURL.split(/[?#]/)[0];
		window.location = redirectUrl;
		return;
	}

	if (resp && resp.status && resp.data && resp.status === 200) {
		return _REALM.TOOLS.toCamelCase(resp.data);
	}

	return {};
};

var refreshToken = function() {
	return executeGet('Spa/RefreshToken', null, { handleError: false }).then(function(resp) {
		document.getElementById('anti-forgery-wrapper').innerHTML = resp.token;
		return resp;
	});
};

var handleError = function(error, config) {
	if (config.handleError) {
		_REALM.LOADER.hide();
		var errorMessage = 'An error has occured';
		if (error && error.response && error.response.data) {
			errorMessage = error.response.data;
		}

		_REALM.TOAST.error(errorMessage);
	}

	throw error;
};

var executePost = function(url, req, config) {
	config = buildConfig(config);

	var axiosConfig = {
		headers: {
			__RequestVerificationToken: document.querySelectorAll('[name="__RequestVerificationToken"]')[0].value,
			'X-Requested-With': 'XMLHttpRequest'
		}
	};

	return axios
		.post(buildUrl(url), req, axiosConfig)
		.then(function(resp) {
			return handleResponse(resp, config);
		})
		.catch(function(error) {
			return handleError(error, config);
		});
};

var executeGet = function(url, params, config) {
	config = buildConfig(config);

	return axios
		.get(buildUrl(url), { params })
		.then(function(resp) {
			return handleResponse(resp, config);
		})
		.catch(function(error) {
			return handleError(error, config);
		});
};

export default {
	post: function(url, req, config) {
		return executePost(url, req, config);
	},
	get(url, params, config) {
		return executeGet(url, params, config);
	},
	refreshToken: refreshToken
};
