<template>
	<v-list-item :to="to" @click="$emit('click', $event)">
		<v-list-item-title>
			<slot></slot>
		</v-list-item-title>
	</v-list-item>
</template>

<script>
export default {
	props: {
		to: Object
	}
};
</script>
